.brand-box {
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    height: 100%;
    text-align: center;
    border: 1px solid rgba(var(--border-color), 1);
    position: relative;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;

    &:hover {
        border: 1px solid rgba(var(--theme-color), 0.5);
        box-shadow: 0 0 30px 0 rgba(var(--theme-color), 0.1);


        h5 {
            color: rgba(var(--theme-color), 1);
        }

        .brand-img {
            img {
                transform: scale(1.05);
            }
        }
    }

    .like-btn {
        position: absolute;
        cursor: pointer;
        top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
        right: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
        background-color: rgba(var(--theme-color), 0.1);
        width: calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
        height: calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 100%;
        @include display;
        z-index: 1;

        img {
            height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
            width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
            object-fit: cover;
        }
    }

    .brand-img {
        background-color: rgba(var(--theme-color), 0.08);
        width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
        height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
        padding: 10px;
        margin: auto;
        @include display;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: all 0.3s ease-in-out;
        }
    }

    h5 {
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        padding-top: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
    }

    span {
        padding-top: 5px;
        color: rgba(var(--content-color), 1);
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }

    p {
        color: rgba(var(--content-color), 1);
        font-size: 15px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
        padding-block: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));

        li {
            cursor: pointer;
            padding: 5px 10px;
            font-weight: 400;
            background-color: rgba(var(--content-color), 0.1);
            color: rgba(var(--content-color), 1);
            font-size: 14px;
            border-radius: 6px;
        }
    }

    .brand-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        border-top: 1px solid rgba(var(--border-color), 1);

        .location {
            display: flex;
            align-items: center;
            gap: 4px;

            h5 {
                font-size: 14px;
                padding-top: 0;
                color: rgba(var(--content-color), 1);
                font-weight: 400;
            }

            img {
                width: 18px;
                height: 18px;
            }
        }

        span {
            cursor: pointer;
            padding: 0;
            font-weight: 600;
            color: rgba(var(--theme-color), 1);
            font-weight: 500;
            font-size: 14px;
            transition: all 0.5s ease-in-out;

            &:hover {
                color: rgba(var(--theme-color), 0.9);
            }
        }
    }
}