:root {
  --auth-margin: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.back-arrow {
  width: 15px;
  height: 15px;
}

.auth-section {

  .auth-img {
    height: calc(100vh - 40px);
    width: 100%;
    object-fit: cover;
    border-radius: 20px;

    @media (max-height:900px) {
      height: 100%;
    }

  }

  .auth-form {
    width: 48%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 40px);

    @media (max-height:900px) {
      height: 100%;
    }

    @media (max-width: 1400px) {
      width: 60%;
    }

    @media (max-width: 1024px) {
      width: 70%;
    }

    @media (max-width: 991px) {
      width: 60%;
    }

    @media (max-width: 768px) {
      width: 75%;
    }

    @media (max-width: 576px) {
      width: 100%;
    }

    >div {
      width: 100%;
    }

    .logo {
      margin-bottom: var(--auth-margin);
    }

    h2 {
      padding-bottom: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(28px + (32 - 28) * ((100vw - 320px) / (1920 - 320)));
    }

    h6 {
      padding-bottom: var(--auth-margin);
      color: rgba(var(--content-color), 1);
      font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 400;
    }
    .auth-login_form {
      .dropdown {
        &.form-control {
          padding: 11px;
          position: relative;
          .dropdown-arrow {
            height: 20px;
            width: auto;
            @media (max-width: 576px) { 
              height: 20px;
              width: 20px;
            }
          }
          .dropdown-btn {
            display: flex;
            align-items: center;
            gap: 8px;
            .flag{
              width: 22%;
            }
          }
        }
        .dropdown-content { 
          input {
            &.search-box {
              margin: 15px auto !important;
              width: 90%;
            }
          }
          width: calc(296px + (500 - 296) * ((100vw - 320px) / (1920 - 320)));
          height: 240px;
          overflow-y: auto;
          overflow-x: hidden;
          left: 0px;
          top: 50px;
          position: absolute;
          z-index: 11;
          background-color: white;
          border: 1px solid rgba(var(--border-color), 1);
          .dropdown-item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 11px;
            &:hover {
              background-color: rgba(var(--theme-color), 0.1);
            }
            .flag {
              width: calc(25px + 2 * (100vw - 320px) / 1600);
              height: calc(18px + 2 * (100vw - 320px) / 1600);
              object-fit: cover;
            }
          }
        }
      }
    }
    .forgot-pass {
      text-align: right;
      margin-bottom: var(--auth-margin);

      span {
        color: rgba(var(--content-color), 1);
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        cursor: pointer;
        transition: all 0.4s ease-in-out;

        &:hover {
          color: rgba(var(--title-color), 1);
        }
      }
    }

    .divider {
      position: relative;
      text-align: center;
      margin-block: var(--auth-margin);

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: rgba(var(--border-color), 1);
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }

      span {
        padding: 0 calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        background-color: rgba(var(--white), 1);
        font-size: 18px;
        color: rgba(var(--content-color), 1);
      }
    }

    .social-media {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
    }

    .insta-btn {
      img {
        width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
        height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
      }

      @include display;
      gap: 10px;
      font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--title-color), 1);
      padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
      width: 100%;
      border: 1px solid rgba(var(--border-color), 1);
      border-radius: 10px;
      line-height: 1;
    }

    .create-wrap {
      margin-top: var(--auth-margin);
      @include display($dis: flex, $align: center, $justify: start);
      gap: 7px;

      h6 {
        padding-bottom: 0;
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
      }

      a {
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
        transition: all 0.3s ease-in-out;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .terms-wrap {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      margin-bottom: var(--auth-margin);

      .terms {
        color: rgba(var(--content-color), 1);
        font-size: 14px;
        width: calc(100% - calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320))) - 10px);

        a {
          font-size: 14px;
          color: rgba(var(--theme-color), 1);
        }
      }
    }

    .nav {
      border: none;
      margin-bottom: var(--auth-margin);
      display: flex;
      gap: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
      width: 100%;

      .nav-item {
        display: flex;
        justify-content: space-between;
        width: calc(50% - calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))));

        .nav-link {
          border: 1px solid rgba(var(--border-color), 1);
          padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 4px;
          line-height: 1;
          color: rgba(var(--title-color), 1);
          text-align: center;
          width: 100%;

          &.active {
            color: rgba(var(--white), 1);
            background-color: rgba(var(--theme-color), 1);
          }
        }
      }
    }
  }
}

.password-indicate {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: calc(6px + (30 - 6) * ((100vw - 320px) / (1920 - 320)));
  margin-top: 8px;
  padding: 0;

  li {
    background-color: rgba(var(--theme-color), 0.1);
    height: 4px;
    width: 100%;
    border-radius: 4px;
  }

  &.weak {
    li {
      &:first-child {
        background-color: rgba(var(--error), 1);
      }
    }
  }

  &.strong {
    li {
      background-color: #65c15c;
    }
  }
}

.password-indicate.medium li:first-child,
.password-indicate.medium li:nth-child(2) {
  background-color: #ffb829;
}

.password-indicate-note,
.address-indicate-note {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 50px;

  .indicate {
    display: flex;
    align-items: center;
    gap: 14px;
    position: absolute;
    right: 4%;
    top: 10px;
    z-index: 2;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;

    .more-info {
      line-height: 1;
      position: relative;
      transition: all 0.5s ease-in-out;

      .tip {
        width: 16px;
        height: 16px;
      }

      .info {
        position: absolute;
        top: -6px;
        left: -4px;
        display: none;
        transform: translateY(-5px);
        transition: all 0.5s ease-in-out;

        @media (max-width: 991px) {
          left: auto;
          right: -4px;
        }
      }

      &:hover {
        .info {
          display: block;
          transform: translateY(0px);
          width: 270px;
          background-color: #f2f8ff;
          border-radius: 8px;
          padding: 4px;

          .info-icon {
            width: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320))) !important;
            height: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320))) !important;
            border-radius: 100%;
            background-color: #f2f8ff;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 991px) {
              margin-left: auto;
            }

            img {
              width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
              height: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            }
          }

          p {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            font-style: italic;
            font-weight: 400;
            padding: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(var(--content-color), 1);
          }
        }
      }
    }
  }
}

.verification-section {
  background-image: url("../../images/otp.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  .verification-form {
    text-align: center;
    margin: 0 auto;
    height: 100vh;
    @include display;

    .auth-gif {
      width: 100px;
      height: 100px;
    }

    .verify-gif {
      width: 160px;
      height: 160px;
    }

    .title {
      margin-bottom: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));

      h3 {
        margin-bottom: 15px;
      }

      .code-text {
        text-wrap: nowrap;
        width: 100%;
      }

      p {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        margin: auto;
        color: rgba(var(--content-color), 1);

        span {
          color: rgba(var(--title-color), 1);
          text-wrap: nowrap;
        }
      }
    }

    .otp-code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
      padding: 0;

      .otp-field {
        border: 1px solid rgba(var(--border-color), 1);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)));
        width: calc(40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        padding: 0;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &:active,
        &.focus,
        &:hover,
        &:focus-visible {
          border: 1px solid rgba(var(--border-color), 1);
          outline: none;
        }

        &.invalid-field {
          border: 1px solid rgba(var(--error), 1);
          background-color: #ffefee;
          color: rgba(var(--error), 1);
        }
      }
    }

    .resend-code {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--auth-margin);

      .sec {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;
        color: rgba(var(--content-color), 1);
      }

      .resend {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;
        letter-spacing: 0.7px;
        color: rgba(var(--content-color), 1);
        @include display;
        gap: 10px;

        .btn {
          border: none;

          &.disabled {
            border: none;
          }
        }
      }
    }

    &.login-detail {
      text-align: start;

      .otp-code {
        justify-content: flex-start;
        margin-top: 20px;
        gap: 8px;

        .otp-field {

          &:active,
          &.focus,
          &:hover,
          &:focus-visible {
            border: 1px solid rgba(var(--border-color), 1);
            outline: none;
          }
        }
      }

      .action-btn {
        margin-top: 30px;

        .btn {
          margin: 0;
        }
      }
    }
  }
}




// New auth css 

.auth-section {
  height: 100%;
  margin: 0 auto;
  position: relative;
  width: 100%;
  padding: 0;

  >.auth-main {
    height: 100vh;
    // height: calc(100vh - 40px);
  }

  .auth-form {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    padding-block: 20px;
    padding-inline: 12px;
  }

  .image-main {
    height: 100%;
    width: 100%;
    padding: 20px;

    .image-contain {
      height: 100%;
      width: 100%;
      background-image: url("../../images/login.png");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 20px;
    }
  }
  &.admin-login{
    .auth-form{
      >div{
        text-align: center;
        .btn-theme{
          margin-top: 32px;
        }
      }
    }
  }
}