@mixin display($dis: flex, $align: center, $justify: center) {
    display: $dis;
    align-items: $align;
    justify-content: $justify;
}

@mixin flex_wrap($dis: flex, $wrap: wrap, $gap: 15px) {
    display: $dis;
    flex-wrap: $wrap;
    gap: $gap;
}

@mixin pos($pos: absolute, $content: "") {
    content: $content;
    position: $pos;
}

@mixin pseudowh($width: 100%, $height: 100%) {
    width: $width;
    height: $height;
}


@mixin center($position) {
    @if $position=="vertical" {
        top: 50%;
        transform: translateY(-50%);
    }

    @else if $position=="horizontal" {
        left: 50%;
        transform: translateX(-50%);
    }

    @else if $position=="both" {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin icon {
    font-family: remixicon;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}