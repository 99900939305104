@each $activity-dot-name,
$activity-dot-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $warning-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .activity-dot-#{$activity-dot-name} {
    min-width: 10px;
    height: 10px;
    background-color: $activity-dot-color;
    border-radius: 100%;
    outline: 5px solid rgba($activity-dot-color, 0.25);
    position: relative;
    z-index: 2;
  }
  // timeline
  .timeline-dot-#{$activity-dot-name} {
    min-width: 12px;
    height: 12px;
    background-color: $activity-dot-color;
    outline: 5px solid rgba($activity-dot-color, 0.25);
    position: relative;
    z-index: 2;
  }
}
@each $badge-dot-name,
$badge-dot-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $warning-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .badge-dot-#{$badge-dot-name} {
    min-width: 10px;
    height: 10px;
    background-color: $badge-dot-color;
    border-radius: 100%;
    outline: 5px solid rgba($badge-dot-color, 0.25);
    position: relative;
    z-index: 2;
  }
} 
.ecommerce-dashboard {
    div.dataTables_wrapper {
      position: unset;
      .dataTables_length {
          margin-bottom: 26px;
          label {
            display: none !important; 
          }
      }
      div.dataTables_filter {
        position: absolute;
        top: 10px;
        right: 53px;
        @media (max-width: 575px) {
          top: 44px;
          left: 20px;
          text-align: start;
          [dir="rtl"] & {
            left: unset !important;
            right: 20px !important;
          }
        }
        [dir="rtl"] & {
          left: 53px;
          right: unset;
        }
          input[type=search] {
              width: 150px !important;
              height: 34px;
              border-radius: 6px;
              border-style: solid;
          }
      }
      table.dataTable {
        margin: 0 !important;
        border: none;
        @media (max-width: 575px) {
            margin-top: 42px !important;
        }
        &.display {
            tbody {
                tr.odd,
                tr.even {
                    background-color: transparent !important;
                    >.sorting_1 {
                        background-color: transparent !important;
                    }
                }
            }
        }
        thead {
          --bs-table-bg: #f7f7f7;
          >tr {
            >th {
              border: none !important;
              text-transform: capitalize;
              font-family: $font-poppins;
              font-weight: 500;
              padding-top: 9px;
              padding-bottom: 9px;
              padding-right: 0;
              &:before,
              &:after {
                display: none;
              }
              [dir="rtl"] & {
                padding: 9px 6px;
              }
              &:first-child {
                padding-left: 24px;
                [dir="rtl"] & {
                  padding-left: unset;
                  padding-right: 24px !important;
                }
              }
              &:last-child {
                padding-right: 24px;
                text-align: center;
                [dir="rtl"] & {
                  padding-right: unset;
                  padding-left: 24px !important;
                }
              }
            }
          }
        }
        tbody {
          >tr {
            >td {
              border: none;
              font-family: $font-poppins;
              font-weight: 500;
              &:first-child {
                padding-left: 24px;
                [dir="rtl"] & {
                  padding-left: unset;
                  padding-right: 24px;
                }
              }
              &:nth-child(5) {
                .btn {
                  border-radius: 25px;
                  padding: 7px 25px;
                  min-width: 50px;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 1199px) { 
      .custom-user-card {
        .custom-card-revenue {
          margin-bottom: 30px !important;
          &:last-child{
            margin-bottom: 0px !important;
          }
        }
      }
    }
    .card {
        margin-bottom: $card-margin-bottom; 
        transition: all 0.3s ease;
        letter-spacing: 0.5px;
        border: none;
        border-radius: $card-border-radius;
        background-color: $white;
        box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
        .card-header {
            background-color: $card-header-bg-color;
            padding: $card-padding;
            border-bottom: none;
            border-top-left-radius: $card-border-radius;
            border-top-right-radius: $card-border-radius;
            position: relative;
        }
        .card-body {
            padding: $card-padding;
            border-radius: 5px;
            background-color: $card-header-bg-color;
        }
    }

    .product-order {
      table {
        thead {
          background-color: rgba($primary-color, 0.9);
          tr {
            th {
              color: $white !important;
              padding: 9px 6px;
              .form-check {
                input {
                  height: 15px;
                  &:checked {
                    background-color: var(--theme-color);
                    border: 1px solid var(--theme-color);
                  }
                  &:focus {
                    box-shadow: none;
                  }
                }
              }
              padding-bottom: 8px;
              color: $theme-font-color;
              border-bottom: 1px solid $light-gray;
              font-weight: 600;
              &:first-child {
                min-width: 0px;
                padding-left: 14px;
              }
              &:last-child {
                text-align: center;
              }
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid $light-gray;
            &:hover{
              background-color: rgba($primary-color, 0.19);
              td {
                font-weight: 500 !important; 
              }
            }
            &:last-child{
                border-bottom: none;
                td {
                    border-bottom-width: 0 !important;
                }
            }
            .form-check {
              input {
                height: 15px;
                &:checked {
                  background-color: var(--theme-color);
                  border: 1px solid var(--theme-color);
                } 
                &:focus {
                  box-shadow: none;
                }
              }
            }
            td {
              &:nth-child(2) {
                @media (max-width:1119px) {
                  min-width: 150px;
                }
              }
              &:nth-child(3) {
                @media (max-width:1119px) {
                  min-width: 180px;
                }
              }
              &:nth-child(4) {
                @media (max-width:1119px) {
                  min-width: 150px;
                }
              }
              &:nth-child(5) {
                @media (max-width:1119px) {
                  min-width: 90px; 
                }
              }
              &:last-child,
              &:first-child {
                padding-left: 14px;
                padding-right: 0px; 
              }
              white-space: nowrap;
              font-weight: 400;
              padding: 14px 6px;
              border-top: none;
              font-size: 14px;
              color: $theme-body-font-color;
              .d-flex {
                align-items: center;
                img {
                  margin-left: 0px;
                  [dir="rtl"] & {
                    margin-right: 0px;
                    margin-left: unset;
                  }
                }
                .flex-shrink-0 {
                  img {
                    margin-left: 0px;
                    [dir="rtl"] & {
                      margin-right: 0px;
                      margin-left: unset;
                    }
                  }
                }
                .flex-grow-1 {
                  a {
                    h6 {
                      font-weight: 500;
                      color: $theme-body-font-color;
                      transition: all 0.4s ease-in;
                    }
                  }
                }
              }
              h6 {
                transition: all 0.4s ease-in;
                font-weight: 500;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
              }
              span {
                font-size: 14px;
                font-weight: 400;
                color: $theme-body-sub-title-color;
                text-overflow:ellipsis;
                overflow:hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
              }
              &:last-child {
                text-align: center;
              }
              button {
                border-radius: 5px;
                padding: 6px 10px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 0px;
                color: $white;
                box-shadow: unset !important;
                border: none !important;
                &.badge-light-primary {
                    background-color: var(--theme-color);
                    color: $white;
                }
                &.badge-light-danger {
                    background-color: $danger-color;
                    color: $white;
                }
                &.badge-light-success {
                    background-color: $success-color;
                    color: $white;
                }
                &.badge-light-info {
                    background-color : $info-color;
                    color: $white;
                }
                &.badge-light-warning {
                    background-color: $warning-color;
                    color: $white;
                }
              }
            }
          }
        }
      }
      .MuiPaper-root{
        .MuiTablePagination-root{
          .MuiToolbar-root {
            flex-wrap: wrap;
            gap: 6px;
          }
        }
      }
    }
    .bg-light-primary {
      background-color: rgba($primary-color, 0.75) !important;
    }
    .bg-light-danger {
      background-color: rgba($danger-color, 0.85) !important;  
    }
    .custom-light-primary {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      background-color: rgba($primary-color, 0.2) !important;
    }
    .custom-light-danger {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      background-color: rgba($danger-color, 0.25) !important;
    }
    
    .custom-card-revenue {
      h4 {
        font-weight: 600;
      }
      .custom-card-title {
        gap: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          stroke: $white;
          width: 24px; 
          height: 24px;
          &.instagram-icon {
            fill: $white;
          }
        }
      }
      .card {
        box-shadow: unset !important;
        .card-body {
          border-radius: 8px;
          background-color: $body-color;
          h5 {
            font-weight: 600;
          } 
          span {
            font-weight: 600;
            color: rgba(var(--content-color), 1); 
          }
        }
      }
    }
    .custom-activity-table {
      position: relative;
      .custom-product-order {
        position: absolute;
        right: 30px;
        top: 15px;
        @media (max-width: 560px) {
          position: unset;
          justify-content: flex-start !important;
          margin-top: 10px;
        }
        .form-label { 
          margin-bottom: 0px;
        }
        .form-control { 
          padding: 4px 8px;
          font-size: $body-font-size;
          &::placeholder{ 
            font-size: $body-font-size;
          }
        }
      }

      .MuiTablePagination-actions {
        .MuiButtonBase-root {
          background-color: rgba($primary-color, 0.19);
          padding: 3px;
          border-radius: 5px;
          margin: 0px 4px; 
        }
      }
    }
    .active-online {
      background-color: var(--theme-color);
      opacity: 0;
    }
    .state {
      ul {
        &.d-flex {
          justify-content: space-between;
          margin-inline: 50px;
          @media (max-width: 1570px) and (min-width: 1200px){
            margin-inline: 20px;
          }
          @media (max-width: 767px) {
            margin-inline: 20px;
          }
          .balance-card {
            .Countries {
              display: flex;
              align-items: center;
              gap: 5px;
              .rounded-pill {
                background: var(--theme-color);
                padding: 4px 4px;
              }
              h5 {
                font-size: 16px;
                font-weight: 400;
                color: $theme-body-sub-title-color;
              }
            }
            h2 {
              font-weight: 500;
              color: $theme-font-color;
              margin-left: 12px;
              [dir="rtl"] & {
                margin-right: 12px;
                margin-left: unset;
              }
            }
          }
        }
      }
      .jvector-map-height {
        height: 175px;
        transform: translateY(15px);
        .jvectormap-zoomin , .jvectormap-zoomout{
          display: none;
        }
        @media (max-width: 1399px) and (min-width: 768px) {
          height: 205px;
        }
      }
    }
  }