@import "../public/assets/scss/app.scss";

.table-wrapper {
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e2e2e227; /* Adjust the opacity as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  &.loading-overlay-off {
    position: fixed; 
  }
}

.loading-message {
  font-size: 18px;
  font-weight: 400;
  color: #333;
}

.table-responsive.loading {
  opacity: 0.2; /* Adjust the opacity to show the underlying content */
}

.custom-loader {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #0075ff 94%, #0000) top/4px 4px no-repeat, conic-gradient(#0000 30%, #0075ff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

.list-loader.loading {
  opacity: 0.3;
}

.add-list-loader.loading{
  opacity: 0.3;
}