.short-bay-box {
    .dropdown {
        &.show {
            .dropdown-toggle {
                &::after {
                    content: '';
                    transform: scale(-1);
                }
            }
        }

        .dropdown-toggle {
            background-color: rgba(var(--theme-color), 0.07);
            border: 1px solid rgba(var(--theme-color), 0.05);
            color: rgba(var(--theme-color), 1);
            line-height: 1;

            &.shortby-btn {
                background-color: rgba(var(--white), 1);
                border: none;
                color: rgba(var(--title-color), 1);

                img {
                    filter: brightness(0.1);
                }

                &::after {
                    filter: brightness(0.1);
                }

                &:hover {
                    border: none;
                    background-color: rgba(var(--theme-color), 0.05);
                }
            }

            &:hover {
                background-color: rgba(var(--white), 1);
                border: 1px solid rgba(var(--theme-color), 0.5);
            }

            &:first-child {
                &:active {
                    color: rgba(var(--theme-color), 1);
                    background-color: rgba(var(--theme-color), 0.1);
                }
            }

            &::after {
                content: '';
                border: none;
                background: url('../../images/svg/theme-arrow-down.svg');
                width: 11px;
                height: 6px;
                margin-left: 0;
                background-size: cover;
                transform: scale(1);
                transition: all 0.2s ease-in-out;
            }

            img {
                width: 18px;
                height: 18px;

                &.img-short {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .dropdown-menu {
            border: 1px solid rgba(var(--border-color), 1);
            box-shadow: 0 0 8px 0 rgba(var(--content-color), 0.1);
            border-radius: 4px;
            margin-top: 10px;
            background-color: rgba(var(--white), 1);
            &.show {
                .dropdown-item {
                    img {
                        height: 18px;
                        width: 18px;
                    }
                }
            }
            .dropdown-item {
                display: flex;
                align-items: center;
                gap: 10px;
                padding-inline: 0;
                padding: 10px 20px;
                border-bottom: 1px solid rgba(var(--border-color), 1);

                &:last-child {
                    border-bottom: 0;
                }

                &:hover {
                    background-color: rgba(var(--theme-color), 0.1);
                }

                &:active {
                    background-color: rgba(var(--theme-color), 1);
                    color: rgba(var(--white), 1);
                }
            }
        }
    }
}