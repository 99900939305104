.creators-table-wrap {
  border: 1px solid rgba(var(--border-color), 1);
  border-radius: 6px;

  &.scroll-bar {
    &::-webkit-scrollbar {
      height: 5px;
    }
  }

  .creators-table {
    margin: 0;

    tr {
      background-color: rgba(var(--white), 1);
      vertical-align: middle;

      th,
      td {
        border-bottom: 1px solid rgba(var(--border-color), 1);
      }

      .table-head {
        .info-icon{
          font-size: small;
          margin-left: 5px;
          color: rgba(var(--content-color), 1);
        }
      }

      &:hover {
        th,
        td {
          background-color: rgba(var(--theme-color), 0.1);
        }

        .table-head {
          background-color: transparent;
        }
      }
    }

    th {
      font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
      text-transform: capitalize;
      color: rgba(var(--black), 1);
      font-weight: 400;
      cursor: pointer;
      text-wrap: nowrap;
      line-height: 1;
      text-align: center;

      img {
        width: 16px;
        height: 16px;
      }

      &.table-head {
        padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        &:first-child {
          padding-left: 16px;
        }
      }

      &:hover {
        color: rgba(var(--theme-color), 1);
      }

      &:first-child {
        min-width: calc(40px + (20 - 40) * ((100vw - 320px) / (1920 - 320)));
        width: calc(40px + (20 - 40) * ((100vw - 320px) / (1920 - 320)));
      }

      &:nth-child(2) {
        min-width: calc(200px + (300 - 200) * ((100vw - 320px) / (1920 - 320)));
      }

      &:nth-child(3) {
        min-width: 145px;
      }

      &:nth-child(4) {
        min-width: 165px;
        max-width: 0;
      }

      &:nth-child(5) {
        min-width: 145px;
        max-width: 0;
      }  
      
      &:nth-child(6) {
        min-width: 155px;
        max-width: 0;
      } 
      
      &:nth-child(7) {
        min-width: 145px;
        max-width: 0;
      } 
      
      &:last-child {
        min-width: 145px;
        max-width: 0;
      }
    }

    td {
      text-align: center;
      cursor: pointer;
      font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--title-color), 1);
    }

    tbody {
      tr {
        td {
          padding-inline: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

          &:first-child {
            padding-left: 16px;
          }
        }
        &:last-child {
          td {
            border: none;
          }
        }
      }
    }

    .check-box {
      display: flex;
    }

    .creator-wrap {
      display: flex;
      align-items: center;
      padding-block: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320)));
      gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

      .user-img {
        width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
        height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 100%;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .creator-detail {
        width: calc(100% - calc(54px + (70 - 54) * ((100vw - 320px) / (1920 - 320))));

        .name {
          display: flex;
          align-items: center;
          gap: 5px;
          flex-wrap: wrap;

          h5 {
            font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            gap: 6px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-align: left;
          }

          img {
            width: 17px;
            height: 17px;
            object-fit: cover;
          }
        }

        h6 {
          text-align: left;
          color: rgba(var(--title-color), 0.6);
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;

          span {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
          }

          img {
            width: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
            height: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
          }

          &:hover {
            color: rgba(var(--theme-color), 1);
            text-decoration: underline;
          }
        }
      }
    }

    &.profile-table {
      tr {
        th,
        td {
          text-align: center;
        }

        th {
          &:first-child {
            min-width: calc(40px + (20 - 40) * ((100vw - 320px) / (1920 - 320)));
            width: calc(40px + (20 - 40) * ((100vw - 320px) / (1920 - 320)));
          }

          &:nth-child(2) {
            min-width: calc(200px + (300 - 200) * ((100vw - 320px) / (1920 - 320)));
          }
    
          &:nth-child(3) {
            min-width: 145px;
          }
    
          &:nth-child(4) {
            min-width: 165px;
            max-width: 0;
          }
    
          &:nth-child(5) {
            min-width: 145px;
            max-width: 0;
          }  
          
          &:nth-child(6) {
            min-width: 155px;
            max-width: 0;
          } 
          
          &:nth-child(7) {
            min-width: 145px;
            max-width: 0;
          } 
          
          &:last-child {
            min-width: 145px;
            max-width: 0;
          }
        }
      }

      tbody {
        tr {
          td {
            padding-inline: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

            &:first-child {
              padding-left: 16px;
            }
          }
          &:last-child {
            td {
              border: none;
            }
          }
        }
      }
    }
  }
}

.table-no-data {
  &:hover {
    background-color: transparent !important;
  }
}

.no-found {
  background-color: rgba(var(--border-color), 0.3) !important;
  height: 300px;
  width: 100%;

  p {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--title-color), 0.5);
  }

  img {
    width: 100px;
  }

  &.nodata-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

tr {
  &.selected-row {
    td {
      background-color: rgba(var(--theme-color), 0.15) !important;
      border-bottom: 1px solid rgba(var(--content-color), 0.3) !important;
    }
  }
}

.table-wrap {
  border: 1px solid rgba(var(--border-color), 1);
  border-radius: 6px;

  &.scroll-bar {
    &::-webkit-scrollbar {
      height: 5px;
    }
  }

  .creators-table {
    margin: 0;

    tr {
      background-color: rgba(var(--white), 1);
      vertical-align: middle;

      th,
      td {
        border-bottom: 1px solid rgba(var(--border-color), 1);
      }

      .table-head {
        .info-icon{
          font-size: small;
          margin-left: 5px;
          color: rgba(var(--content-color), 1);
        }
      }

      &:hover {
        th,
        td {
          background-color: rgba(var(--theme-color), 0.1);
        }

        .table-head {
          background-color: transparent;
        }
      }
    }

    th {
      font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
      text-transform: capitalize;
      color: rgba(var(--black), 1);
      font-weight: 400;
      cursor: pointer;
      text-wrap: nowrap;
      line-height: 1;
      text-align: center;

      img {
        width: 16px;
        height: 16px;
      }

      &.table-head {
        padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
        &:first-child {
          padding-left: 16px;
        }
      }

      &:hover {
        color: rgba(var(--theme-color), 1);
      }

      &:first-child {
        min-width: calc(40px + (20 - 40) * ((100vw - 320px) / (1920 - 320)));
        width: calc(40px + (20 - 40) * ((100vw - 320px) / (1920 - 320)));
      }

      &:nth-child(2) {
        min-width: calc(200px + (300 - 200) * ((100vw - 320px) / (1920 - 320)));
      }

      &:nth-child(3) {
        min-width: 145px;
      }

      &:nth-child(4) {
        min-width: 165px;
        max-width: 0;
      }

      &:nth-child(5) {
        min-width: 145px;
        max-width: 0;
      }  
      
      &:nth-child(6) {
        min-width: 155px;
        max-width: 0;
      } 
      
      &:nth-child(7) {
        min-width: 145px;
        max-width: 0;
      } 
      
      &:last-child {
        min-width: 145px;
        max-width: 0;
      }
    }

    td {
      text-align: center;
      cursor: pointer;
      font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--title-color), 1);
    }

    tbody {
      tr {
        td {
          padding-inline: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

          &:first-child {
            padding-left: 16px;
          }
        }
        &:last-child {
          td {
            border: none;
          }
        }
      }
    }

    .check-box {
      display: flex;
    }

    .creator-wrap {
      display: flex;
      align-items: center;
      padding-block: calc(4px + (10 - 4) * ((100vw - 320px) / (1920 - 320)));
      gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

      .user-img {
        width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
        height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 100%;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .creator-detail {
        width: calc(100% - calc(54px + (70 - 54) * ((100vw - 320px) / (1920 - 320))));

        .name {
          display: flex;
          align-items: center;
          gap: 5px;
          flex-wrap: wrap;

          h5 {
            font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            gap: 6px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-align: left;
          }

          img {
            width: 17px;
            height: 17px;
            object-fit: cover;
          }
        }

        h6 {
          text-align: left;
          color: rgba(var(--title-color), 0.6);
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;

          span {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
          }

          img {
            width: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
            height: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
          }

          &:hover {
            color: rgba(var(--theme-color), 1);
            text-decoration: underline;
          }
        }
      }
    }

    &.profile-table {
      tr {
        th,
        td {
          text-align: center;
        }

        th {
          &:first-child {
            min-width: calc(40px + (20 - 40) * ((100vw - 320px) / (1920 - 320)));
            width: calc(40px + (20 - 40) * ((100vw - 320px) / (1920 - 320)));
          }

          &:nth-child(2) {
            min-width: calc(200px + (300 - 200) * ((100vw - 320px) / (1920 - 320)));
          }
    
          &:nth-child(3) {
            min-width: 145px;
          }
    
          &:nth-child(4) {
            min-width: 165px;
            max-width: 0;
          }
    
          &:nth-child(5) {
            min-width: 145px;
            max-width: 0;
          }  
          
          &:nth-child(6) {
            min-width: 155px;
            max-width: 0;
          } 
          
          &:nth-child(7) {
            min-width: 145px;
            max-width: 0;
          } 
          
          &:last-child {
            min-width: 145px;
            max-width: 0;
          }
        }
      }

      tbody {
        tr {
          td {
            padding-inline: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

            &:first-child {
              padding-left: 16px;
            }
          }
          &:last-child {
            td {
              border: none;
            }
          }
        }
      }
    }
  }
}