.collaborate-box {
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    border: 1px solid rgba(var(--border-color), 1);
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    transition: all 0.5s ease-in-out;

    &:hover {
        box-shadow: 0 0 13px 0 rgba(var(--theme-color), 0.1);
        border: 1px solid transparent;

        .top-panel {
            .name {
                h5 {
                    color: rgba(var(--theme-color), 1);
                }
            }
        }
    }

    .top-panel {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

        .brand-img {
            width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
            height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 10px;
            background-color: rgba(var(--theme-color), 0.08);
            @include display;

            img {
                width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
                height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
                object-fit: cover;
            }
        }

        .name {
            width: calc(100% - calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320))) - calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))));

            h5 {
                transition: all 0.3s ease-in-out;
                font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
                font-weight: 500;
                color: rgba(var(--title-color), 1);
            }

            h6 {
                color: rgba(var(--content-color), 1);
                font-weight: 400;
                font-size: 14px;
                padding-top: 5px;
            }
        }
    }

    .brand-info {
        padding-block: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--title-color), 1);
    }

    .product-img {
        position: relative;

        label {
            position: absolute;
            top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
            left: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
            background-color: rgba(var(--white), 1);
            padding: calc(4px + (7 - 4) * ((100vw - 320px) / (1920 - 320))) calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 6px;
            color: rgba(var(--title-color), 1);
        }

        img {
            aspect-ratio: 1.64 / 1;
            object-fit: cover;
            transition: all 0.5s ease-in-out;
            object-position: center;
            width: 100%;
            border-radius: 10px;
        }
    }

    .detail-box {
        padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

        .detail {
            padding-block: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            display: grid;
            grid-template-columns: auto auto;
            gap: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));

            @media (max-width: 435px) {
                grid-template-columns: auto;
            }

            li {
                h5 {
                    color: rgba(var(--content-color), 1);
                    font-weight: 400;
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));

                    span {
                        color: rgba(var(--title-color), 1);
                    }
                }
            }
        }

        .categories {
            display: flex;
            align-items: center;
            gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

            li {
                cursor: pointer;
                padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
                line-height: 1;
                border-radius: calc(4px + (6 - 4) * ((100vw - 320px) / (1920 - 320)));
                background-color: rgba(var(--content-color), 0.1);
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                color: rgba(var(--content-color), 1);
            }
        }
    }

    .bottom-panel {
        @include display($dis: flex, $align: center, $justify: space-between);
        padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        border-top: 1px solid rgba(var(--border-color), 1);

        h5 {
            color: rgba(var(--content-color), 1);
            font-weight: 400;
            font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            align-items: center;
            gap: 5px;

            img {
                width: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
                height: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
            }
        }

        .btn {
            color: rgba(var(--theme-color), 1);
            font-weight: 500;
        }
    }
}