.search-panel {
  padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
  background-color: rgba(var(--white), 1);
  border-radius: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
  z-index: 1;
  position: relative;

  .filter-wrapper {
    display: flex;
    align-items: center;
    gap: calc(6px + (20 - 6) * ((100vw - 320px) / (1920 - 320))) 10px;
    width: 100%;

    .btn-theme,
    .filter-btn-main {
      width: 100%;
    }
  }

  .select-form {
    .form-select {
      max-width: unset;
      min-width: unset;
      width: 100%;
      line-height: 1;
    }

    .inner-select {
      padding: 14px;
      border-radius: 10px;
      background-color: transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid rgba(var(--border-color), 1);

      img {
        opacity: 0.5;
      }

      span {
        line-height: 1;
        color: rgba(var(--content-color), 1);
        font-size: 18px;

        &.selected {
          color: rgba(var(--title-color), 1);
        }
      }
    }
  }

  form {
    .filter-box {
      position: relative;

      .filter-btn {
        background-color: #f2f8ff;
        font-weight: 500;
        color: rgba(var(--theme-color), 1);
      }

      .filter-menu {
        display: none;
        position: absolute;
        background-color: rgba(var(--white), 1);
        box-shadow: 0 0 13px rgba(var(--theme-color), 0.05);
        padding: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
        min-width: calc(270px + (530 - 270) * ((100vw - 320px) / (1920 - 320)));
        right: calc(-5px + (-20 - 5) * ((100vw - 320px) / (1920 - 320)));
        top: calc(48px + (65 - 48) * ((100vw - 320px) / (1920 - 320)));
        border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

        @media (max-width: 1050px) {
          right: unset;
          left: -5px;
        }

        .filter-row {
          .filter-title {
            text-align: start;
            padding-bottom: 10px;
          }

          .rang-title {
            text-align: left;
            font-size: 16px;
          }
        }
      }

      &.open {
        .filter-menu {
          display: block;
        }
      }
    }
  }
}

.top-filter-panel {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;

  .filter-form-box {
    display: flex;
    align-items: center;
    gap: calc(6px + (10 - 6) * ((100vw - 991px) / (1920 - 991)));
    flex-wrap: wrap;

    .rest-btn-filter {
      padding: 14px 18px;
    }

    @media (max-width: 991px) {
      gap: 8px;
    }

    .search-icon {
      width: calc(22px + (20 - 22) * ((100vw - 991px) / (1920 - 991)));
      height: calc(22px + (20 - 22) * ((100vw - 991px) / (1920 - 991)));
    }

    .search-btn {
      @media (max-width: 510px) {
        display: none;
      }
    }
  }

  .dropdown-toggle {
    letter-spacing: 0.3px;
    font-size: 16px;
    font-weight: 500;
    height: 46px;
  }

  .form-search-box {
    display: flex;
    align-items: center;
    gap: calc(8px + (10 - 8) * ((100vw - 991px) / (1920 - 991)));

    .image-search-section {
      position: relative;

      .MuiInputBase-root {
        margin-top: 0px !important;
      }

      .image-preview {
        width: 245px;
        height: 200px;
        position: absolute !important;
        top: 100%;
        left: 0;
        z-index: 1;
        padding: 10px;
        border: 1px solid rgba(var(--border-color), 1);
        background-color: $white;
        border-radius: 4px;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .close-icon {
        background-color: rgba(var(--white), 0.5);
        padding: 4px;
        border-radius: 4px;
      }
    }

    .search-btn {
      img {
        width: 21px;
        height: 21px;
      }

      @media (max-width: 576px) {
        font-size: 0;
        gap: 0;
      }
    }

    .label-text {
      font-size: 16px;
      color: rgba(var(--black), .6);
      white-space: nowrap;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    flex-direction: unset;
    align-items: flex-end;
  }
}

.ai-search-box{
  background-color: rgba(var(--content-color), .04);
  padding: 18px;
  border-radius: 10px;
  border: 1px solid rgba(var(--border-color), 1);
  .form-search-box .ai-input input{
    background-color: #fff;
  }
}
.search-title {
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  font-size: 19px;
  color: rgba(var(--black), 1);

  >span{
    color: rgba(var(--content-color), 1);
    display: flex;
    margin-left: 5px;
  }
}

.filter-option-box {
  display: flex;
  align-items: center;
  gap: 10px;

  .btn {
    border: 1px solid rgba(var(--theme-color), 1);
    padding: 10px calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--theme-color), 1);

    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      border: 1px solid rgba(var(--theme-color), 1);
      background-color: rgba(var(--theme-color), 0.05);
    }
  }
}

.more-filter-btn {
  font-size: 17px;
  font-weight: 500;
  color: rgba(var(--title-color), 1);
  border: 1px solid rgba(var(--border-color), 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
  line-height: 1;
  padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320))) calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));

  &.btn-pill-light {
    border: 1px solid transparent;
    color: rgba(var(--theme-color), 1);

    img {
      filter: none;
    }
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: all;

    &:hover {
      border: 1px solid rgba(var(--border-color), 1);
      background-color: transparent;
      color: rgba(var(--title-color), 1);

      img {
        filter: brightness(0);
      }
    }
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
    filter: brightness(0);
    padding: 3px;
  }

  &:hover {
    border: 1px solid rgba(var(--theme-color), 0.5);
    background-color: rgba(var(--theme-color), 0.03);
    color: rgba(var(--theme-color), 1);

    img {
      filter: none;
    }
  }
}

input[type=file] {
  &::file-selector-button {
    color: $primary-color;
    background-color: rgba(var(--content-color), 0.1);
    border: none;
    padding: 4px 8px;
    margin-top: -4px;

    &:hover {
      color: $primary-color;
      background-color: rgba(var(--content-color), 0.1);
    }
  }
}