.form-check-input {

    &:active {
        background-color: rgba(var(--white), 1);
        filter: none;
    }

    &:focus {
        box-shadow: none;
        border: none;
    }
}


.check-box {
    padding: 0;
    position: relative;
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    appearance: none;

    &::before {
        content: "";
        position: absolute;
        background-color: rgba(var(--white), 1);
        border: 1px solid rgba(var(--content-color), 0.5);
        border-radius: 4px;
        width: 100%;
        height: 100%;
        transition: 0.3s;
    }

    &::after {
        content: "";
        position: absolute;
        border: 2px solid rgb(0 117 255);
        top: -4px;
        left: 12px;
        width: 6px;
        height: 17px;
        transform: rotate(235deg) scale(0);
        border-bottom: unset;
        border-right: unset;
        transition: all 0.3s ease-in-out;
        background-color: #fff;
    }

    &:checked {
        &::after {
            transform: rotate(230deg) scale(1);
        }

        &::before {
            border: 1px solid rgba(var(--theme-color), 1);
            background-color: rgba(var(--white), 1);
        }
    }
}

.switch-box {
    padding: 0;
    position: relative;
    width: calc(46px + (56 - 46) * ((100vw - 320px) / (1920 - 320)));
    height: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
    appearance: none;

    &::before {
        content: "";
        position: absolute;
        background-color: rgba(var(--theme-color), 1);
        border: 1px solid rgba(var(--border-color), 1);
        border-radius: 20px;
        width: 100%;
        height: 100%;
        transition: 0.3s;
    }

    &::after {
        cursor: pointer;
        content: "";
        position: absolute;
        top: 5px;
        left: 6px;
        width: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
        height: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 100%;
        transform: translateX(calc(26px + (30 - 26) * ((100vw - 320px) / (1920 - 320))));
        transition: all 0.3s ease-in-out;
        background-color: rgba(var(--white), 1);
    }

    &:checked {
        &::after {
            transform: translateX(0px);
        }
    }
}