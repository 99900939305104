.offcanvas-header {
    .btn-close {
        background-image: url(../../images/svg/close-lg.svg);
        opacity: 1;

        &:focus {
            box-shadow: none;
            border: none;
        }
    }
}

.creator-list-offcanvas {
    padding: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
    min-width: 30%;
    padding-bottom: 0;

    @media (max-width: 1750px) {
        min-width: 35% !important;
    }

    @media (max-width: 1570px) {
        min-width: 40% !important;
    }

    @media (max-width: 1250px) {
        min-width: 45% !important;
    }

    @media (max-width: 1100px) {
        min-width: 50% !important;
    }

    @media (max-width: 991px) {
        min-width: 55% !important;
    }

    @media (max-width: 775px) {
        min-width: 70% !important;
    }

    @media (max-width: 599px) {
        min-width: 100% !important;
    }

    .offcanvas-header {
        padding: 0;
        // padding-bottom: 10px;

        .offcanvas-title {
            font-size: 24px;
            font-weight: 500;
        }

        .btn-close {
            position: absolute;
            right: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
            top: calc(12px + (35 - 12) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .offcanvas-body {
        padding: 0;

        .list-title {
            font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
            color: rgba(var(--title-color), 1);
            display: flex;
            align-items: center;
            gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
            padding-bottom: 20px;

            span {
                font-size: 16px;
                cursor: pointer;
                text-decoration: underline;
                color: rgba(var(--content-color), 1);
            }
        }

        .delete-list-btn {
            background-color: rgba(var(--error), 0.2);
            color: rgba(var(--error), 1);
            @include display;
        }
    }
}


.filter-list-offcanvas {
    padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: 0;
    min-width: 40% !important;

    @media (max-width: 1570px) {
        min-width: 45% !important;
    }

    @media (max-width: 1350px) {
        min-width: 55% !important;
    }

    @media (max-width: 1050px) {
        min-width: 60% !important;
    }

    @media (max-width: 950px) {
        min-width: 70% !important;
    }

    @media (max-width: 850px) {
        min-width: 100% !important;
    }

    .offcanvas-header {
        padding-block: 0;
        margin-bottom: 10px;

        .offcanvas-title {
            font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 500;
            color: rgba(var(--title-color), 1);
        }

        .btn-close {
            position: absolute;
            right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
            top: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
        }
    }

    .offcanvas-body {
        padding-bottom: 0;
        overflow: hidden;

        .filter-row {
            max-height: calc(100vh - calc(120px + (180 - 120) * ((100vw - 320px) / (1920 - 320))));
            overflow-y: auto;
            // min-height: calc(100vh - calc(120px + (100 - 120) * ((100vw - 320px) / (1920 - 320))));

        }

        .filter-box {
            .inner-list {
                font-size: calc(17px + (22 - 17) * ((100vw - 320px) / (1920 - 320)));
                color: rgba(var(--title-color), 1);
                font-weight: 500;
                padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

                span {
                    font-size: 13px;
                    color: rgba(var(--content-color), 1);
                }
            }
        }

    }

    .filter-footer-wrap {
        margin-top: 20px;
        padding: 20px 10px;
        position: sticky;
        bottom: 0;
        border-top: 1px solid rgba(var(--border-color), 1);
        background-color: rgba(var(--white), 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
    }

}