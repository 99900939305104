.accordion {
    &.accordion-section {
        .accordion-item {
            border: none;
            box-shadow: 0 0 8px 0 rgba(var(--theme-color), 0.07);
            border-radius: 10px;
            margin-bottom: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
            background-color: rgba(var(--white), 1);

            .accordion-header {
                .accordion-button {
                    background-color: rgba(var(--white), 1);
                    box-shadow: none;
                    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
                    color: rgba(var(--title-color), 1);
                    font-weight: 500;
                    font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
                    display: flex;
                    align-items: flex-start;
                    gap: 10px;

                    &:focus {
                        box-shadow: none;
                    }

                    &:not(.collapsed) {
                        box-shadow: none;

                        &::after {
                            content: "";
                            background-image: url(../../images/svg/minus.svg);
                        }
                    }

                    &::after {
                        content: "";
                        background-image: url(../../images/svg/add.svg);
                    }
                }
            }

            .accordion-body {
                padding: 0 calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
                margin: 0 0 20px;
                color: rgba(var(--content-color), 1);
                font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}