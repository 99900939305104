.title {
    font-size: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--title-color), 1);
    font-weight: 500;
}

.sub-title {
    padding-top: 8px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--content-color), 1);
}

.title-flex {
    @include display($dis: flex, $align: center, $justify: space-between);
}