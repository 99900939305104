.pagination {
    justify-content: center;
    gap: 8px;
    border: 1px solid rgba(var(--border-color), 1);
    padding: 4px;
    border-radius: 6px;
    align-items: center;

    .page-item {
        border-radius: 6px;

        &.disabled {
            cursor: not-allowed;

            .page-link {
                background-color: transparent;
            }
        }

        .page-link {
            overflow: hidden;
            color: rgba(var(--title-color), 1);
            border-radius: 4px;
            border: none;
            padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
            min-width: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
            width: max-content;
            // height: calc(28px + (36 - 28) * ((100vw - 320px) / (1920 - 320)));
            @include display;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1;

            &.prev,
            &.next {
                padding: 0;
                min-width: auto;
                width: 18px;
            }

            &.next-pagination,
            &.back-pagination {
                width: calc(80px + (100 - 80) * ((100vw - 575px) / (1920 - 575)));

                span {
                    font-size: calc(16px + (18 - 16) * ((100vw - 575px) / (1920 - 575)));
                    gap: 5px;
                    @include display;
                }

                @media (max-width: 575px) {
                    width: calc(40px + (60 - 40) * ((100vw - 320px) / (1920 - 320)));

                    span {
                        gap: 0;
                        font-size: 0;
                    }
                }
            }

            &:focus {
                background-color: transparent;
                color: rgba(var(--title-color), 1);
                box-shadow: none;

                &:hover {
                    background-color: transparent;
                }
            }

            &:hover {
                background-color: rgba(var(--theme-color), 0.08);
            }
        }

        &.active {
            .page-link {
                background-color: rgba(var(--theme-color), 1);
                color: rgba(var(--white), 1);
                box-shadow: none;

                &:hover {
                    background-color: rgba(var(--theme-color), 1);
                }
            }
        }
    }
}

.pagination-panel {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;

    .pagination-select-wrap {
        display: flex;
        align-items: center;
        gap: 5px;
    }


    @media (max-width: 525px) {
        flex-direction: column;
        align-items: center;
    }
}