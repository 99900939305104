.arrow-style-1 {
    &.arrow-style-2 {

        .swiper-button-next,
        .swiper-button-prev {
            left: auto;
            right: 12%;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 1599px) {
                right: 11.5%;
            }

            @media (max-width: 1399px) {
                right: 11%;
            }

            @media (max-width: 1199px) {
                display: none;
            }
        }

        .swiper-button-prev {
            left: 12%;
            right: auto;

            @media (max-width: 1599px) {
                left: 11.5%;
            }

            @media (max-width: 1399px) {
                left: 11%;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
        height: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 100%;
        box-shadow: 0 0 20px rgba(47, 47, 47, 0.10);
        border: none;
        padding: 0;

        &::after {
            content: "prev";
            width: 100%;
            height: 100%;
            font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: black;
            font-weight: 600;
            background-color: rgb(255, 255, 255);
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
            border-radius: 100%;
        }
    }

    .swiper-button-next {
        &::after {
            content: "next";
        }

        left: auto;
        right: calc(-19px + (-25 - -19) * ((100vw - 576px) / (1920 - 576)));
        top: 40%;

        @media (max-width: 575px) {
            // display: none;
        }
    }

    .swiper-button-prev {
        left: calc(-19px + (-25 - -19) * ((100vw - 576px) / (1920 - 576)));
        top: 40%;
        right: auto;

        @media (max-width: 575px) {
            // display: none;
        }
    }


    .creators-slider {
        position: relative;

        .swiper-slide {
            padding: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))) 0;
        }
    }
}

.swiper-pagination-bullet {
    &.swiper-pagination-bullet-active {
        background-color: rgba(var(--theme-color), 1);
    }
}

.swiper-pagination {
    display: flex;
    justify-content: center;
    gap: calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320)));
}


.swiper {
    z-index: 0 !important;
}