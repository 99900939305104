.creators-box {
  position: relative;
  border: 1px solid rgba(var(--border-color), 1);
  border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
  overflow: hidden;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  height: 100%;
  pointer-events: none;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 30px 0 rgba(var(--theme-color), 0.1);
    border: 1px solid rgba(var(--theme-color), 0.3);

    .creators-img-box {
      .influencers-img {
        transform: scale(1.05);
      }

      .btn {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        visibility: visible;
        z-index: 1;
      }
    }

    .creators-details {
      .top-panel {
        h5 {
          color: rgba(var(--theme-color), 1);
          cursor: pointer;
        }
      }
    }
  }

  .creators-img-box {
    position: relative;
    overflow: hidden;

    .btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease-in-out;
    }

    .influencers-img {
      aspect-ratio: 1/1;
      object-fit: cover;
      transition: all 0.5s ease-in-out;
      width: 100%;
    }

    label {
      font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (1920 - 320)));
      display: flex;
      align-items: center;
      gap: 5px;
      line-height: 1;
      position: absolute;
      top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      left: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      background-color: rgba(var(--white), 1);
      padding: calc(3px + (7 - 3) * ((100vw - 320px) / (1920 - 320))) calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 6px;
      z-index: 1;
    }

    .like-btn {
      position: absolute;
      cursor: pointer;
      top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      right: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      background-color: rgba(var(--white), 1);
      width: calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
      height: calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 100%;
      @include display;
      z-index: 1;

      img {
        height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
        width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
        object-fit: cover;
      }
    }
  }

  .creators-details {
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

    .top-panel {
      @include display($dis: flex, $align: center, $justify: space-between);
      flex-wrap: wrap;
      gap: 5px;

      h5 {
        color: rgba(var(--title-color), 1);
        font-weight: 500;
        font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
        transition: all 0.5s ease-in-out;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
      }

      h6 {
        color: rgba(var(--content-color), 1);
        font-weight: 400;
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
      }

      @media (max-width: 575px) {
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
      }
    }

    p {
      padding-top: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 300;
      color: rgba(var(--content-color), 1);
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      line-height: 1;
    }

    .bottom-panel {
      @include display($dis: flex, $align: center, $justify: space-between);

      .user-location {
        display: flex;
        align-items: center;
        gap: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));

        img {
          width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          height: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        }

        span {
          color: rgba(var(--content-color), 1);
          font-weight: 400;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          width: 80%;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          line-height: 1;

          @media (max-width: 575px) {
            width: 85%;
          }
        }
      }

      .price {
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}

//   creators box scss end

// creator page scss start

.table-top-panel {
  @include display($dis: flex, $align: center, $justify: space-between);

  .row-check {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;

    h5 {
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    .export-btn-box {
      display: flex;
      align-items: center;
      gap: 10px;

      @media (max-width: 575px) {
        justify-content: space-between;
      }
    }

    @media (max-width: 575px) {
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .found-list {
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--title-color), 1);
  }

  .list-btn-box {
    display: flex;
    align-items: center;
    gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));

    .btn {
      font-size: 16px;

      .list-icon {
        width: 18px;
        height: 18px;
      }

      .export-img {
        width: 16px;
        height: 16px;
        filter: brightness(0.1);
      }

      &:hover {
        .export-img {
          filter: none;
        }
      }
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 15px;

    .list-btn-box {
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
    }
  }
}

.add-creator-btn {
  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
}

.create-list-wrapper {
  height: calc(100vh - 230px);
  overflow-y: auto;
  margin-top: 10px;

  &.inner-list-wrapper {
    height: calc(100vh - 300px);
  }

  .user-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-block: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    transition: all 0.5s ease-in-out;
    cursor: pointer;

    +.user-row {
      border-top: 1px solid rgba(var(--border-color), 1);
    }

    &:hover,
    &.active {
      background-color: rgba(var(--theme-color), 0.1);
    }

    .user-list {
      width: calc(100% - calc(48px + (60 - 48) * ((100vw - 320px) / (1920 - 320))));
      display: flex;
      align-items: center;
      gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));

      .icon-box {
        padding: 10px;
        width: 40px;
        height: 40px;
        background-color: rgba(var(--theme-color), 0.1);
        @include display;
        border-radius: 8px;

        svg {
          color: rgba(var(--theme-color), 1);
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .check-box {
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
        }
      }

      .list-name {
        width: calc(100% - calc(83px + (110 - 83) * ((100vw - 320px) / (1920 - 320))));

        h5 {
          color: rgba(var(--title-color), 1);
          font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
          padding-bottom: calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 400;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }

        h6 {
          color: rgba(var(--content-color), 1);
          font-size: 14px;
          font-weight: 400;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          &:hover{
            text-decoration: underline;
            color: rgba(var(--theme-color), 1);
            cursor: pointer;
          }
        }
      }
    }

    .list-operations {
      display: flex;
      align-items: center;
      gap: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .favorite-no-data {
    height: 100%;
    width: 100%;
    @include display;

    @media (max-height: 575px) {
      height: auto;
    }
  }
}

.menu-list-wrap {
  display: flex;
  flex-direction: column;
  gap: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));

  .list-row {
    position: relative;

    .inner-icon-line {
      position: relative;
      display: flex;
      align-items: center;
      gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
      flex-wrap: nowrap;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &.selected {
        color: rgba(var(--theme-color), 1);

        .check-icon {
          font-size: 18px;
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: rgba(var(--content-color), 1);

        &:hover {
          cursor: not-allowed;
          color: rgba(var(--content-color), 1);
        }
      }

      img {
        width: 17px;
        height: 17px;
        filter: brightness(0.1);
        transition: all 0.3s ease-in-out;

        &.info-icon {
          width: 14px;
          height: 14px;
        }
      }

      .coming-soon {
        font-size: small;
      }

      &:hover,
      &.active {
        color: rgba(var(--theme-color), 1);

        img {
          filter: none;

          &.info-icon {
            filter: brightness(0);
          }
        }
      }
    }
    
    .select-child {
      position: relative;
      z-index: 1;
      top: 0px;
      min-width: 260px;
      box-shadow: unset !important;
      border: none !important;
      box-shadow: unset !important;
      &.custom-select-child {
        border: none !important; 
        box-shadow: unset !important;
      }
      .select-inner {
        .inner-icon-line {
          .taglNews {
            padding: 1px 4px;
            border-radius: 4px 0 4px 0;
            background-color: #ff4f4f;
            color: #fff;
            font-weight: 500;
            font-size: 10px;
            margin: 0 !important;
            transform: translate(-8px , -8px);
          }
          span {
            margin: 0px 0px 0px auto !important;
          }
        }
      }
      @media (max-width: 991px) {
        width: 80%;
      }

      @media (max-width: 575px) {
        left: 0;
        min-width: unset;
        width: 260px;
      }

      .select-inner-box {
        padding: 0;
        border: none;
      }

      .select-box {

        .left,
        .right {
          h5 {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.filter-content-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(var(--content-color), 0.07);
  padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 4px;

  .tags-list-box {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    .filter-tags {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320))) calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
      cursor: pointer;
      border: 1px solid transparent;
      flex-wrap: nowrap;
      background-color: rgba(var(--white), 1);
      border: 1px solid rgba(var(--content-color), 0.3);
      border-radius: 4px;

      .tag-detail {
        display: flex;
        align-items: center;
        gap: calc(1px + (5 - 1) * ((100vw - 320px) / (1920 - 320)));
        transition: all 0.3s ease-in-out;

        h5 {
          color: rgba(var(--black), 1);
          font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 400;
        }

        span {
          font-size: 14px;
          color: rgba(var(--title-color), 0.8);
          font-weight: 400;
        }
      }
    }
  }

  .btn {
    padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;

    .tags-list-box {
      width: 100%;

      .filter-tags {
        align-items: flex-start;
        width: 100%;
        justify-content: space-between;

        .tag-detail {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .btn {
      padding: 0;
    }
  }
}

.common-tooltip {
  .tooltip {
    .tooltip-inner {
      background-color: rgba(var(--title-color), 1) !important;
      margin: 2px 6px;
      font-weight: 500;
      border-radius: 4px !important;
    }

    .tooltip-arrow {
      &::before {
        content: none !important;
      }
    }
  }
}

.no-filter-content {
  justify-content: center;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.favorite-list {
    height: 230px;

    img {
      width: 150px;
    }
  }

  &.favorite-canvas {
    height: 230px;

    img {
      width: calc(180px + (200 - 180) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  img {
    width: 350px;

    &.ai-img {
      height: 220px;
    }
  }

  .filter-content {
    padding-top: 20px;
    text-align: center;

    h5 {
      color: rgba(var(--title-color), 1);
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    h6 {
      margin-top: 4px;
      color: rgba(var(--content-color), 1);
      font-weight: 400;
      font-size: 13px;
    }
  }
}

.ai-search-panel {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 7px;
  border-radius: 4px;
  .taglNews{
    padding: 0px 4px;
    border-radius: 4px 0 4px 0;
    background-color: #ff4f4f;
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    margin: 0 !important;
    transform: translate(-5px, -10px);
  }
  
  h5 {
    text-wrap: nowrap;
    color: rgba(var(--black), 0.6);
    font-size: 16px;
    white-space: nowrap;
    letter-spacing: 0.3px;
    font-weight: 400;
  }

  .info-icon {
    margin-top: -2px;
    color: rgba(var(--content-color), 1);
    cursor: pointer;
  }

  span{
    font-size: small;
    color: rgba(var(--content-color), 1);
  }

  .MuiFormControl-root{    margin-left: 10px;
    .MuiFormControlLabel-root{
      margin-left: 0;
      .MuiButtonBase-root{
        padding: 0 3px 0 0;
      }
      .MuiTypography-root{
        font-size: 16px;
      }
    }
  }
}

.top-filter-panel {
  .form-search-box {
    .ai-input {
      input {
        padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) 7px !important;
        cursor: unset;
      }
    }

    .profile-search {
      input {
        padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) 7px !important;
      }
    }
  }
}

.creator-list-offcanvas {
  .offcanvas-body {
    .list-search {
      img {
        width: 20px;
        height: 20px;
      }

      input {
        padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) 7px !important;
      }
    }
  }
}

.creator-addlist {
  .modal-body {
    .list-image {
      width: 20px;
      height: 20px;
    }

    input {
      padding: calc(11px + (14 - 11) * ((100vw - 320px) / (1920 - 320))) 10px !important;

      &.check-box {
        padding: 0 !important;

        &:before {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}


.inner-offcanvas {
  .back-btn {
    width: 40px;
    height: 40px;
    @include display;
    background-color: rgba(var(--content-color), 0.07);
    border-radius: 100%;
    padding: 10px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .list-header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    .list-title-wrap {
      h5 {
        font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 10px;
        text-transform: capitalize;
        color: rgba(var(--title-color), 1);
        font-weight: 600;
      }

      h6 {
        font-weight: 400;
        color: rgba(var(--content-color), 1);
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    .list-operations {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .total-list-count {
    display: flex;
    align-items: center;
    gap: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-inline-start: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));

    label {
      width: calc(100% - 36px);
      cursor: pointer;
      font-weight: 600;
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .inner-list-wrapper {
    .user-row {
      .user-list {
        .user-icon {
          position: relative;
          width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
          height: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }

          .platform-icon {
            position: absolute;
            bottom: -6px;
            right: calc(-6px + (-10 - -6) * ((100vw - 320px) / (1920 - 320)));
            width: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
            height: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
            @include display;
            padding: calc(4px + (6 - 4) * ((100vw - 320px) / (1920 - 320)));
            background-color: rgba(var(--white), 1);
            border-radius: 100%;
            box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2);

            img {
              width: 100%;
              height: 100%;
              border-radius: 0;
            }
          }
        }

        .list-name {
          h6 {
            display: flex;
            align-items: center;
            gap: 4px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;

            @media (max-width: 375px) {
              -webkit-line-clamp: 2;
            }
          }
        }
      }
    }
  }
}