// search box  scss start  //
.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
  background-color: rgba(var(--white), 1);
  gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

  .form-control {
    background-color: rgba(var(--content-color), 0.08);
    border: none;
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

    &::placeholder {
      width: 80%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;
    width: 90%;
    background-color: transparent;
    margin: auto;

    .form-control {
      border: 5px solid rgba(var(--white), 1);
      border-radius: 6px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  }
}

// search box  scss ebd  //

// find creators page scss start  //
.creator-section {
  position: relative;
  z-index: 0;
}

.creator-wrap {
  position: relative;
  z-index: 0;
}

.top-panel {
  @include display($dis: flex, $align: center, $justify: space-between);
  gap: 10px;

  .grid-box {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;

    .grid-option {
      width: calc(40px + (54 - 40) * ((100vw - 320px) / (1920 - 320)));
      height: calc(40px + (54 - 40) * ((100vw - 320px) / (1920 - 320)));
      @include display;
      background-color: rgba(var(--theme-color), 0.08);
      border-radius: 10px;

      img {
        opacity: 0.5;
      }

      &.active {
        img {
          opacity: 1;
        }
      }
    }

    @media (max-width: 992px) {
      display: none;
    }
  }
}

// find creators page scss end  //

//  creators detail page scss start  //

:root {
  --margin: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
  --border: 1px solid rgba(var(--border-color), 1);
}

.btn-back {
  color: rgba(var(--title-color), 1);
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;

  img {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }
}

.creator-portfolio {
  .detail-wrap {
    background: url("../../images/detail-bg.jpg") center center no-repeat;
    background-size: cover;
    border-radius: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
  }

  .user-detail-box {
    @include display($dis: flex, $align: start, $justify: space-between);
    gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

    .img-content {
      @include display($dis: flex, $align: center, $justify: start);
      gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));

      img {
        width: calc(60px + (110 - 60) * ((100vw - 320px) / (1920 - 320)));
        height: calc(60px + (110 - 60) * ((100vw - 320px) / (1920 - 320)));
        border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
        object-fit: cover;
      }

      .name {
        h5 {
          font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 600;
        }

        h6 {
          font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 400;
          padding-top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
          color: rgba(var(--content-color), 1);
        }
      }
    }

    .detail {
      h5 {
        @include display($dis: flex, $align: center, $justify: end);
        gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));

        img {
          filter: brightness(0.1);
          width: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
          height: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
      }

      ul {
        padding-top: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        align-items: center;
        gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));

        li {
          padding: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320))) calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
          background-color: rgba(var(--white), 1);
          border-radius: 6px;

          a {
            color: rgba(var(--content-color), 1);
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
    }

    @media (max-width: 575px) {
      flex-direction: column;

      .detail {
        h5 {
          justify-content: flex-start;
        }
      }
    }
  }

  .portfolio-box {
    padding-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    position: relative;

    .p-img {
      aspect-ratio: 69 / 50;
      object-fit: cover;
      transition: all 0.5s ease-in-out;
      width: 100%;
      border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    }

    .modal-btn {
      position: absolute;
      bottom: calc(25px + (50 - 25) * ((100vw - 768px) / (1920 - 768)));
      right: calc(10px + (20 - 10) * ((100vw - 768px) / (1920 - 768)));
      background-color: rgba(var(--white), 1);
      color: rgba(var(--theme-color), 1);

      img {
        object-fit: cover;
        width: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
        height: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
      }

      @media (max-width: 767px) {
        position: relative;
        margin: auto;
        right: 0;
        bottom: 0;
        margin-top: 16px;
      }
    }
  }
}

.inner-title {
  font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  color: rgba(var(--title-color), 1);
}

.creator-detail {
  .info-wrap {
    p {
      padding-block: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--content-color), 1);
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .platform {
      display: flex;
      align-items: center;
      gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: var(--margin);
      flex-wrap: wrap;

      li {
        padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
        @include display;
        gap: 8px;
        color: rgba(var(--title-color), 1);
        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
        border: 1px solid rgba(var(--border-color), 1);
        border-radius: 6px;

        img {
          width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
          height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }

    .achieve-list {
      display: flex;
      flex-direction: column;
      gap: calc(6px + (16 - 6) * ((100vw - 320px) / (1920 - 320)));
      padding-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
      margin-bottom: var(--margin);

      li {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--content-color), 1);
        margin-left: 15px;
        display: list-item;
        list-style-type: disc;
      }
    }

    .platform-box {
      background-color: rgba(var(--white), 1);
      box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);
      padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
      border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

      .platform-title {
        font-weight: 400;
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        padding-bottom: 16px;
      }
    }

    .list-note {
      color: rgba(var(--content-color), 1);
      font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
      display: flex;
      align-items: start;
      gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      font-style: italic;
      margin-bottom: var(--margin);
      margin-top: 0;
      padding-top: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
      padding-bottom: 0;

      img {
        filter: grayscale(1);
      }
    }

    .collab-brand {
      padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
      border: 1px solid rgba(var(--border-color), 1);
      border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      display: flex;
      align-items: center;
      gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

      .brand-icon-box {
        width: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
        height: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 6px;
        background-color: rgba(var(--theme-color), 0.08);
        @include display;

        img {
          width: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
          height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
          object-fit: cover;
        }
      }

      .name-box {
        width: calc(100% - calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320))) - calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))));

        h5 {
          font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
          color: rgba(var(--title-color), 1);
        }

        h6 {
          color: rgba(var(--content-color), 1);
          font-weight: 400;
          font-size: 14px;
          padding-top: calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }

  .overview-box {
    position: sticky;
    top: 20px;
    left: 0;
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(var(--white), 1);
    box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

    .inner-title {
      padding-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    }

    .overview-wrap {
      display: flex;
      flex-direction: column;

      h5 {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--title-color), 1);
        font-weight: 400;
      }

      h6 {
        color: rgba(var(--content-color), 1);
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;
      }

      li {
        padding-block: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
        border-bottom: var(--border);
        @include display($dis: flex, $align: center, $justify: space-between);

        .platform-price {
          li {
            &:first-child {
              padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
            }

            border-color: rgba(var(--white), 1);
          }
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border: none;
        }
      }
    }

    .btn {
      margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    }

    .overview-title {
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      padding-bottom: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}

.platform-price {
  background-color: rgba(var(--theme-color), 0.08);
  display: flex;
  flex-direction: column;
  padding: 0 calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
  border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

  li {
    gap: 10px;
    padding-block: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    @include display($dis: flex, $align: center, $justify: space-between);
    border-bottom: 1px solid rgba(var(--white), 1);

    &:last-child {
      border-bottom: none;
    }

    h5 {
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--title-color), 1);
      font-weight: 400;
    }

    h6 {
      color: rgba(var(--content-color), 1);
      font-weight: 400;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}

.brand-review-box {
  padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
  box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);
  background-color: rgba(var(--white), 1);
  margin-top: var(--margin);
  border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

  .swiper-slide {
    padding-bottom: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320)));
    margin-block: auto;
  }

  .review-box {
    margin-top: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(var(--theme-color), 0.08);
    height: 100%;
    position: relative;

    .top-panel {
      @include display($dis: flex, $align: start, $justify: space-between);

      @media (max-width: 435px) {
        flex-direction: column;
        text-align: center;
      }

      .brand-name {
        display: flex;
        align-items: center;
        gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

        @media (max-width: 435px) {
          flex-direction: column;
          width: 100%;
        }

        .user-img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 100%;
        }

        .brand-logo {
          width: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
          height: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
          border-radius: 100%;
          background-color: rgba(var(--white), 1);
          @include display;

          img {
            width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
            height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
            object-fit: cover;
          }
        }

        .name {
          h5 {
            font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
            padding-bottom: 5px;
            color: rgba(var(--title-color), 1);
            font-weight: 500;
          }

          h6 {
            color: rgba(var(--content-color), 1);
            font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 400;
          }
        }
      }

      .date {
        position: absolute;
        right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--content-color), 1);
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;
      }
    }

    .detail {
      padding-bottom: 0;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));

      @media (max-width: 435px) {
        text-align: center;
      }
    }
  }
}

//  creators detail  page scss end  //

//  brand page scss start  //
.brand-section {
  position: relative;
  z-index: 0;
}

//  brand page scss end  //

//  Brand detail  page scss start  //
.brand-portfolio {
  .detail-wrap {
    background: url("../../images/detail-bg.jpg") center center no-repeat;
    background-size: cover;
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: 0;

    @media (max-width: 435px) {
      padding-inline: 0;
    }

    .brand-detail-box {
      @include display($dis: flex, $align: start, $justify: space-between);
      gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

      .img-content {
        @include display($dis: flex, $align: center, $justify: start);
        gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

        .brand-logo {
          width: calc(60px + (110 - 60) * ((100vw - 320px) / (1920 - 320)));
          height: calc(60px + (110 - 60) * ((100vw - 320px) / (1920 - 320)));
          background-color: rgba(var(--white), 1);
          border-radius: 10px;
          overflow: hidden;
          @include display;

          img {
            width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
            height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
            object-fit: cover;
          }
        }

        .name {
          width: calc(100% - calc(60px + (110 - 60) * ((100vw - 320px) / (1920 - 320))) - calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))));

          h5 {
            font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
          }

          h6 {
            font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 400;
            padding-top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(var(--content-color), 1);
          }
        }
      }

      .detail {
        h5 {
          @include display($dis: flex, $align: center, $justify: end);
          gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
          font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));

          img {
            filter: brightness(0.1);
            width: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
            height: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
          }
        }

        ul {
          padding-top: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
          display: flex;
          align-items: center;
          gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));

          li {
            padding: calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320))) calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
            background-color: rgba(var(--white), 1);
            border-radius: 6px;

            a {
              color: rgba(var(--content-color), 1);
              font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
          }
        }
      }

      @media (max-width: 575px) {
        flex-direction: column;

        .detail {
          h5 {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

.brand-wrap {
  .swiper-slide {
    padding-bottom: calc(38px + (80 - 38) * ((100vw - 320px) / (1920 - 320)));

    img {
      border-radius: 10px;
      aspect-ratio: 69 / 50;
    }
  }

  .swiper-pagination {
    padding-bottom: calc(8px + (30 - 8) * ((100vw - 320px) / (1920 - 320)));
  }
}

.left-wrap {
  p {
    padding: calc(5px + (16 - 5) * ((100vw - 320px) / (1920 - 320))) 0 calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--content-color), 1);
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
  }

  .location-box {
    padding-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
    display: flex;
    flex-direction: column;
    gap: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));

    li {
      display: flex;
      align-items: start;
      gap: 5px;

      h5 {
        text-wrap: nowrap;
        color: rgba(var(--title-color), 1);
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      }

      h6 {
        color: rgba(var(--content-color), 1);
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;

        a {
          font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }
}

.right-wrap {
  position: sticky;
  top: 20px;
  left: 0;

  .collaborate-box {
    box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);
    border: none;
  }
}

//  Brand detail  page scss end  //

//  Collaboration page scss start  //

.top-panel {
  .filter-btn {
    background-color: rgba(var(--theme-color), 0.07);
    color: rgba(var(--theme-color), 1);
  }
}

.collab-portfolio {
  .collab-wrap {
    background: url("../../images/detail-bg.jpg") center center no-repeat;
    background-size: cover;
    border-radius: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
  }

  .collab-detail-box {
    @include display($dis: flex, $align: start, $justify: space-between);
    gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

    @media (max-width: 640px) {
      flex-direction: column;
    }

    .name {
      h5 {
        width: 70%;
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 600;
        line-height: 1.4;

        @media (max-width: 768px) {
          width: 90%;
        }

        @media (max-width: 575px) {
          width: 100%;
        }
      }

      h6 {
        padding-top: calc(6px + (16 - 6) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--content-color), 1);
        font-weight: 400;
      }
    }

    .detail {
      h5 {
        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        align-items: center;
        gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--title-color), 1);
        font-weight: 400;
        flex-wrap: nowrap;
        text-wrap: nowrap;

        img {
          width: calc(22px + (24 - 22) * ((100vw - 320px) / (1920 - 320)));
          height: calc(22px + (24 - 22) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }

  .collab-wrap {
    .swiper-slide {
      padding-bottom: calc(38px + (80 - 38) * ((100vw - 320px) / (1920 - 320)));

      img {
        border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
        object-fit: cover;
        aspect-ratio: 69 / 50;
      }
    }

    .swiper-pagination {
      padding-bottom: calc(8px + (30 - 8) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}

.left-wrap {
  .goals-list {
    padding-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: calc(5px + (16 - 5) * ((100vw - 320px) / (1920 - 320)));

    li {
      color: rgba(var(--content-color), 1);
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      margin-left: 30px;
      display: list-item;
      list-style-type: disc;

      &::marker {
        font-size: 14px;
      }
    }
  }

  .compensation-box {
    margin-block: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(var(--theme-color), 0.07);
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

    .title-flex {
      @media (max-width: 575px) {
        flex-direction: column;
        gap: 7px;
        align-items: flex-start;
      }
    }

    .paid-box {
      display: flex;
      align-items: center;
      gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      background-color: #d6edde;
      padding: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 6px;
      font-weight: 400;
      font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
    }

    .note {
      padding: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
      background-color: rgba(var(--white), 1);
      border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      margin-block: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));

      p {
        padding: 0;
      }
    }

    .paid-list {
      display: flex;
      flex-direction: column;
      gap: calc(6px + (16 - 6) * ((100vw - 320px) / (1920 - 320)));

      li {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--content-color), 1);
        margin-left: 30px;
        display: list-item;
        list-style-type: disc;

        &::marker {
          font-size: 14px;
        }
      }
    }
  }

  .product-list {
    display: flex;
    flex-direction: column;
    gap: calc(6px + (16 - 6) * ((100vw - 320px) / (1920 - 320)));
    padding-block: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320))) calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));

    li {
      margin-left: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
      display: list-item;
      list-style-type: disc;
      color: rgba(var(--content-color), 1);
      font-weight: 400;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));

      &::marker {
        font-size: 14px;
        color: rgba(var(--title-color), 1);
      }

      span {
        color: rgba(var(--title-color), 1);
        font-weight: 500;
      }
    }
  }

  .address-list {
    p {
      padding-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
      padding-bottom: 0;
    }

    .brand-address {
      padding-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
      display: flex;
      flex-direction: column;
      gap: calc(6px + (16 - 6) * ((100vw - 320px) / (1920 - 320)));

      li {
        color: rgba(var(--content-color), 1);
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));

        span {
          color: rgba(var(--title-color), 1);
          font-weight: 500;
        }

        a {
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }
}

.right-wrap {
  .requirement-box {
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(var(--white), 1);
    box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

    p {
      display: flex;
      align-items: flex-start;
      gap: 5px;
      padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--content-color), 1);
      font-style: italic;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));

      img {
        filter: grayscale(1);
      }
    }

    .requirement-list {
      display: flex;
      flex-direction: column;
      padding-top: 4px;

      li {
        padding-block: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(var(--border-color), 1);

        h5 {
          color: rgba(var(--title-color), 1);
          font-weight: 400;
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        }

        h6 {
          color: rgba(var(--content-color), 1);
          font-weight: 400;
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        }

        &:last-child {
          border-bottom: none;
        }

        .platform-price {
          margin-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));

          li {
            border-color: rgba(var(--white), 1);
          }
        }
      }
    }

    .btn {
      margin-top: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .respond-box {
    .respond-btn {
      background-color: rgba(var(--theme-color), 0.07);
      font-weight: 400;
      color: rgba(var(--title-color), 1);
    }

    .tags-btn {
      display: flex;
      gap: 10px;
      align-items: center;
      padding-block: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));

      @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .categories-icon {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .tags {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          cursor: pointer;
          width: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
          height: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
          object-fit: cover;
        }

        li {
          cursor: pointer;
          background-color: rgba(var(--white), 1);
          font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))) calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
          box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);
        }
      }
    }
  }

  .respond-list {
    display: flex;
    flex-direction: column;
    gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

    li {
      color: rgba(var(--content-color), 1);
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      margin-left: 30px;
      display: list-item;
      list-style-type: disc;

      &::marker {
        font-size: 14px;
      }
    }
  }
}

//  Collaboration page scss end //

//  Blog detail page scss start //

.blog-detail-section {
  .blog-img {
    aspect-ratio: 140 / 55;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
    width: 100%;
    border-radius: 10px;
    object-position: center;
  }

  .blog-info {
    padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    @include display($dis: flex, $align: center, $justify: space-between);
    gap: 5px;

    @media (max-width: 575px) {
      flex-direction: column;
      align-items: flex-start;
    }

    h5 {
      color: rgba(var(--content-color), 1);
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      text-transform: capitalize;
    }

    .blog-date {
      display: flex;
      align-items: center;
      gap: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

      .blog-view {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: calc(-10px + (-20 - -10) * ((100vw - 320px) / (1920 - 320)));
          height: 80%;
          width: 2px;
          background-color: rgba(var(--border-color), 1);
          margin-right: 20px;
        }
      }
    }
  }

  .left-wrap {
    .title {
      width: 80%;

      @media (max-width: 575px) {
        width: 100%;
      }
    }

    .blog-summary {
      display: flex;
      flex-direction: column;
      gap: calc(8px + (30 - 8) * ((100vw - 320px) / (1920 - 320)));

      .list-title {
        margin-left: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
        display: list-item;
        list-style-type: decimal;

        &::marker {
          font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
        }

        h5 {
          color: rgba(var(--title-color), 1);
          font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
        }

        .summary-list {
          padding-top: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320)));
          display: flex;
          flex-direction: column;
          gap: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));

          .list {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            display: list-item;
            list-style-type: disc;
            color: rgba(var(--content-color), 1);
            font-weight: 400;

            &::marker {
              font-size: 14px;
            }
          }
        }
      }
    }

    .blog-conclusion {
      padding-top: 30px;
      font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--title-color), 1);
    }
  }

  .right-wrap {
    .recent-post-box {
      padding: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      border: 1px solid rgba(var(--border-color), 1);
      display: flex;
      flex-direction: column;
      gap: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));

      .post-box {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: calc(65px + (100 - 65) * ((100vw - 320px) / (1920 - 320)));
          height: calc(65px + (100 - 65) * ((100vw - 320px) / (1920 - 320)));
          object-fit: cover;
          border-radius: 6px;
        }

        .recent-post-list {
          width: calc(100% - 10px);

          .post-title {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(var(--title-color), 1);
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
          }

          p {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(var(--content-color), 1);
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            margin-top: calc(3px + (6 - 3) * ((100vw - 320px) / (1920 - 320)));
          }
        }
      }
    }

    .discover-box {
      padding: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
      margin-top: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
      text-align: center;
      background: url("../../images/blog-bg.jpg") center center no-repeat;
      object-fit: cover;
      border-radius: 10px;
      background-size: cover;

      h5 {
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--title-color), 1);
        font-weight: 600;
        padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}

//  Blog detail page scss end //

// webinar page scss start //
.webinar-img {
  aspect-ratio: 69 / 40;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.webinar-detail {
  .webinar-time {
    display: flex;
    align-items: center;
    gap: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));

    h5 {
      color: rgba(var(--title-color), 1);
      font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 5px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .time {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: calc(-10px + (-20 - -10) * ((100vw - 320px) / (1920 - 320)));
        height: 80%;
        width: 2px;
        background-color: rgba(var(--border-color), 1);
        margin-right: 20px;
      }
    }

    @media (max-width: 375px) {
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;

      .time {
        &::after {
          content: "";
          background-color: transparent;
        }
      }
    }
  }

  .webinar-title {
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    color: rgba(var(--theme-color), 1);
  }

  p {
    color: rgba(var(--content-color), 1);
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    padding-top: 10px;
  }

  .tags {
    margin-block: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

    li {
      border-radius: 6px;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--content-color), 1);
      padding: calc(4px + (9 - 4) * ((100vw - 320px) / (1920 - 320))) calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
      background-color: rgba(var(--content-color), 0.1);
      line-height: 1;
    }
  }

  .social-title {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(var(--title-color), 1);
    padding-bottom: 12px;
  }

  .social-link {
    display: flex;
    align-items: center;
    gap: 10px;

    li {
      height: 30px;
      width: 30px;
      @include display;
      background-color: rgba(var(--white), 1);
      border-radius: 100%;
      box-shadow: 0 0 30px 0 rgba(var(--theme-color), 0.2);
      transition: all 0.5s ease-in-out;

      img {
        width: 16px;
        height: 16px;
        filter: grayscale(1);
        transition: all 0.5s ease-in-out;
      }

      &:hover {
        transform: translateY(-5px);

        img {
          filter: none;
        }
      }
    }
  }
}

// webinar page scss end //

// glossary page scss start //
.glossary-list {
  display: flex;
  flex-direction: column;
  gap: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

  .glossary-item {
    border-bottom: 1px solid rgba(var(--border-color), 1);
    padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    h5 {
      color: rgba(var(--title-color), 1);
      font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
    }

    p {
      padding-top: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--content-color), 1);
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}

//No data box

.no-data-box {
  margin-block: 30px;

  .no-data-img {
    @include display;

    img {
      width: 80%;
    }
  }

  h5 {
    padding-top: 20px;
    font-size: 20px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    color: rgba(var(--black), 1);
  }

  p {
    text-align: center;
    color: rgba(var(--black), 0.5);
    margin-top: 2px;
  }
}

// glossary page scss end //

// pricing page scss end //
.plan-wrap {
  .plan-box {
    transform: scale(0.9);
    height: 100%;
    padding: 30px;
    border: 1px solid rgba(var(--theme-color), 1);
    border-radius: 10px;
    background-color: rgba(var(--white), 1);
    transition: all 0.3s ease-in-out;

    @media (max-width: 991px) {
      transform: scale(1);
    }

    &.annual {
      transform: scale(1);
      background-color: rgba(var(--theme-color), 1);
      color: rgba(var(--white), 1);

      h6 {
        img {
          filter: invert(1);
        }
      }

      .price {
        color: rgba(var(--white), 1);

        span {
          color: rgba(var(--white), 1);
        }
      }

      .price-list {
        li {
          color: rgba(var(--white), 1);
        }
      }

      .btn {
        background-color: rgba(var(--white), 1);
      }
    }

    h6 {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding-bottom: 10px;
    }

    .price {
      color: rgba(var(--theme-color), 1);
      text-align: center;
      font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));

      span {
        font-weight: 400;
        color: rgba(var(--title-color), 1);
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    .price-list {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 16px;

      li {
        line-height: 1;
        align-items: flex-start;
        color: rgba(var(--content-color), 1);
        display: flex;
        gap: 8px;
      }
    }

    .btn {
      color: rgba(var(--theme-color), 1);
      background-color: rgba(var(--theme-color), 0.08);
      margin: auto;
    }

    &:hover {
      background-color: rgba(var(--theme-color), 0.04);

      .btn {
        background-color: rgba(var(--theme-color), 1);
        color: rgba(var(--white), 1);
      }

      &.annual {
        background-color: rgba(var(--theme-color), 1);

        .btn {
          background-color: rgba(var(--white), 1);
          color: rgba(var(--theme-color), 1);
        }
      }
    }
  }
}

.price-note {
  color: rgba(var(--content-color), 1);
}

.plan-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  span {
    cursor: pointer;

    &.active {
      color: rgba(var(--theme-color), 1);
    }
  }
}

// pricing page scss end //