/**=====================
  Header CSS Start
==========================**/
.left-header {
    .d-flex {
      align-items: center;
    }
    h6 {
      line-height: 1.6;
      @media (max-width: 1199px) {
        width: 160px;
        overflow: hidden;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      @media (max-width: 991px) {
        width: 120px;
      }
      @media (max-width: 767px) {
        width: 66px;
      }
    }
    @media (max-width: 755px) {
      display: none;
    }
    img {
      margin-top: -12px;
      height: 26px;
    }
    i {
      font-weight: 600;
      font-size: 12px;
      margin-bottom: -4px;
    }
  }
  .onhover-show-div {
    top: 55px;
    position: absolute;
    z-index: 8;
    background-color: $white;
    transition: all linear 0.3s;
    @extend %for-animated-hover;
    li {
      padding: 5px 30px;
      &:before {
        display: none;
      }
      a {
        svg {
          margin-top: 0 !important;
          path,
          line {
            color: $theme-body-font-color !important;
          }
        }
      }
    }
  }
  .left-header {
    display: flex;
    .left-menu-header {
      ul.header-left {
        height: 100%;
        display: flex;
        align-items: center;
        li {
          padding: 9px 14px;
          display: inline-block;
          position: relative;
          border-radius: 5px;
          cursor: pointer;
          background-color: $main-body-color;
          svg {
            vertical-align: middle;
            width: 24px;
            height: 24px;
            stroke: none;
            fill: $theme-body-sub-title-color;
          }
          &.serchinput {
            display: none !important;
          .serchbox {
            position: relative;
          }
          .search-form {
            top: 52px;
            position: absolute;
            transition: all linear 0.3s;
            right: -50px;
            transform: translateY(-35px) scaleY(0);
            opacity: 0;
            visibility: hidden;
            width: 180px;
            margin-left: 12px;
            @media (max-width: 480px) {
              right: -85px;
            }
            &.open {
              transform: translateY(0px) scaleY(1);
              opacity: 1;
              visibility: visible;
              transition: all linear 0.3s;
            }
            input {
              padding: 10px 15px;
              background-color: $light;
            }
          }
        }
        }
      }
    }
    .input-group {
      padding: 5px 15px;
      border-radius: 30px;
      overflow: hidden;
      background-color: $body-color;
      .input-group-prepend {
        .input-group-text {
          background-color: transparent;
          border: none;
          padding: 0;
          height: 40px;
          width: 40px;
          margin-right: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            color: $primary-color;
          }
        }
      }
      .form-control {
        border: none;
        background-color: transparent;
        padding: 0;
        font-size: 14px;
        &:focus {
          box-shadow: none;
        }
        &::placeholder {
          font-weight: 300;
          color: $light-text;
        }
      }
    }
  }
  /*======= Page Header css ends  ======= */
  /**======Main Header css Start ======**/
  .offcanvas,
  .offcanvas-bookmark {
    .page-wrapper {
      .page-body-wrapper {
        .page-body {
          position: relative;
          filter: blur(5px) grayscale(100%);
        }
      }
    }
  }
  .offcanvas {
    position: unset;
    visibility: visible;
    background: unset;
    z-index: 2;
  }
  .toggle-sidebar {
    position: absolute;
    right: 30px;
    top: 24px; 
    cursor: pointer;
    svg {
      width: $header-wrapper-nav-icon-size;
      height: $header-wrapper-nav-icon-size;
    }
    &:before {
      content: "";
      position: absolute;
      height: 40px;
      width: 40px;
      background-color: rgba($primary-color, 0.1);
      border-radius: 100%;
      left: -11px;
      z-index: -2;
      top: -8px;
      transform: scale(0);
      transition: all 0.3s ease;
    }
    &:hover {
      &:before {
        height: 40px;
        width: 40px;
        transform: scale(1);
        transition: all 0.3s ease;
      }
    }
  }
  .onhover-dropdown {
    cursor: pointer;
    position: relative;
    &:hover {
      .onhover-show-div {
        @extend %for-animated-hover-box;
      }
    }
  }
  .onhover-show-div {
    top: 55px;
    position: absolute;
    z-index: 8;
    background-color: $white;
    transition: all linear 0.3s;
    @extend %for-animated-hover;
    li {
      a {
        svg {
          margin-top: 0 !important;
          path,
          line {
            color: $theme-body-font-color !important;
          }
        }
      }
    }
  }
  /**======Main Header css Ends ======**/
  /*=======Mega menu css start=======*/
  .mega-menu {
    .default-according {
      .card {
        .btn-link {
          font-weight: 500;
          color: gray;
        }
        .card-body {
          font-size: 12px;
        }
      }
    }
    .onhover-show-div {
      width: 1500px;
    }
    .card-body {
      padding: 20px;
    }
    div {
      >div {
        a {
          margin-bottom: 0px;
          display: inline-block;
          color: $theme-body-sub-title-color;
        }
      }
    }
    .list-unstyled {
      div {
        a {
          padding: 8px 35px 8px 0;
          transition: $sidebar-transition;
          &:hover {
            padding: 8px 35px 8px 10px;
            transition: $sidebar-transition;
          }
        }
      }
    }
  }
  /*=======Mega menu css end=======*/

  .header-breadcrumbs-box{
    display: flex;
    gap: 10px;
    align-items: center;
    // @media (max-width: 575.98px) {
    //   display: grid;
    // }

    >.btn{
      width: max-content;
      @media (max-width: 575.98px) {
        padding: 7px;
        img{
          filter: invert(1) brightness(100);
        }
      }
    }
  }
  /**=====================
      Header CSS Ends 
  ==========================**/