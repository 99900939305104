.dashboard-body {
  .dashboard-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 280px;
    background-color: rgba(var(--white), 1);
    border-right: 1px dashed rgba(var(--theme-color), 0.3);
    transition: all 0.15 ease-in-out;
    z-index: 5;

    .close-icon {
      position: absolute;
      right: -15px;
      top: 20px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      border: 1px dashed rgba(var(--theme-color), 0.3);
      @include display;
      border-radius: 100%;
      background-color: rgba(var(--white), 1);
      box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);

      svg {
        width: 20px;
        height: 20px;
        fill: rgba(var(--theme-color), 1);
      }
    }

    .logo-header {
      padding: 15px !important;
      padding-bottom: 0;

      .main-logo {
        display: block;
      }

      .favicon-logo {
        display: none;
      }
    }

    .sidebar-menu {
      .menu-list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .menu-item {
          cursor: pointer;
          position: relative;
          margin-inline: 7px;

          .menu-link {
            padding-inline: 15px;
            transition: all 0.5s ease-in-out;
            padding-block: 15px;
            display: flex;
            align-items: center;
            gap: 15px;
            border-radius: 10px;

            span {
              font-size: 18px;
              font-weight: 500;
              text-wrap: nowrap;
              color: rgba(var(--title-color), 1);
              line-height: 1;
            }

            svg {
              margin-left: auto;
            }
          }

          img {
            filter: grayscale(1);
            transition: all 0.5s ease-in-out;
            opacity: 0.7;
          }

          &.active {
            img {
              filter: invert(42%) sepia(80%) saturate(6187%) hue-rotate(203deg) brightness(104%) contrast(105%) !important;
            }
          }

          .sub-menu {
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            padding-inline: 40px;
            width: 100%;
            gap: 6px;

            .sub-item {
              margin-left: 30px;
              display: list-item;
              list-style-type: disc;
              padding-block: 10px;

              .sub-link {
                color: rgba(var(--title-color), 1);
                text-wrap: nowrap;
                font-weight: 500;
                font-size: 18px;
              }

              &.active {
                color: rgba(var(--theme-color), 1);

                .sub-link {
                  color: rgba(var(--theme-color), 1);
                }
              }
            }
          }

          &:hover,
          &.active {
            .menu-link {
              background-color: rgba(var(--theme-color), 0.07);

              span {
                color: rgba(var(--theme-color), 1);
              }
            }

            img {
              filter: none;
              opacity: 1;
            }

          }
        }
      }
    }

    &.close-action {
      width: 95px;
      transition: all 0.15 ease-in-out;
      background-color: rgba(var(--white), 1);
      box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);

      .logo-header {
        padding: 10px;
        padding-bottom: 0;
        transition: all 0.15 ease-in-out;

        .main-logo {
          display: none;
          transition: all 0.15 ease-in-out;
        }

        .favicon-logo {
          display: block;
          transition: all 0.15 ease-in-out;
          margin: auto;
        }
      }

      .sidebar-menu {
        .menu-list {
          .menu-item {
            margin-inline: 5px;

            .menu-link {
              border-radius: 10px;
              display: flex;
              flex-direction: column;
              gap: 8px;

              span {
                font-size: 13px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                display: none;
              }

              svg {
                display: none;
              }
            }

            .sub-menu {
              display: none;
            }

            &.active {
              .menu-link {
                background-color: rgba(var(--theme-color), 0.1);
              }
            }
          }
        }
      }

      ~.dashboard-content {
        margin-left: 95px;
        transition: all 0.15 ease-in-out;
      }

      ~.footer {
        margin-left: 95px;
        transition: all 0.15 ease-in-out;
      }
    }
  }

  .dashboard-content {
    margin-left: 280px;
    padding-inline: 50px;
    padding-top: 50px;
    min-height: calc(100vh - 125px);
  }

  .footer {
    margin-left: 0;
    padding-inline: 50px;
    background-color: $sidebar-background;
    padding-block: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: calc(100% - 0px);
    bottom: 0;
    left: 0;


    p {
      color: rgba(var(--title-color), 1);
      font-size: 16px;
    }

    .footer-menu {
      display: flex;
      align-items: center;
      gap: 20px;
      margin: 0;

      li {
        font-size: 16px;
        color: rgba(var(--title-color), 1);
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        &:hover {
          color: rgba(var(--theme-color), 1);
          font-weight: 500;
        }
      }
    }
  }
}


.profile-page {

  .inner-title {
    font-size: 18px;
    color: rgba(var(--title-color), 1);
    padding-bottom: 20px;
  }

  .width-space {
    width: 63%;
  }

  .breadcrumb {
    margin: 0;

    .breadcrumb-item {
      font-weight: 500;
      font-size: 16px;

      a {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        color: rgba(var(--content-color), 1);

        img {
          width: 16px;
          height: 16px;
          filter: brightness(0) contrast(0);
        }
      }

      &.active {
        margin-top: 2px;
        font-weight: 500;
        color: rgba(var(--theme-color), 1);
      }
    }
  }

  .profile-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .profile-wrap {
      display: flex;
      align-items: center;
      gap: 17px;
      // flex-direction: column;
      // justify-content: center;
      // width: 100%;
      // text-align: center;

      img {
        width: 89px;
        height: 89px;
        object-fit: cover;
        border-radius: 13px;
      }

      .profile-detail {
        width: calc(100% - 17px - 89px);
        h5 {
          color: rgba(var(--title-color), 1);
          margin-bottom: 6px;
          font-weight: 600;
        }

        h6 {
          color: rgba(var(--content-color), 1);
          font-weight: 400;
        }
      }
    }

    .edit-btn {
      .btn {
        padding-inline: 30px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .about-wrap {
    padding-top: 40px;

    p {
      color: rgba(var(--content-color), 1);
      font-size: 18px;
      font-weight: 400;
    }
  }

  .general-details-wrap {
    padding-top: 40px;

    .input-wrap {
      .form-warp {
        label {
          padding-bottom: 5px;
          font-size: 18px;
          color: rgba(var(--content-color), 1);
        }

        .form-control {
          padding-inline: 18px;
        }
      }
    }
  }

  .account-wrap {
    padding-top: 40px;

    .account-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      flex-wrap: wrap;

      .account-link {
        .btn {
          color: rgba(var(--title-color), 1);

          img {
            width: 20px;
            height: 20px;
          }

          &:hover {
            color: rgba(var(--theme-color), 1);
            background-color: transparent;
            border: 1px solid rgba(var(--theme-color), 1);
          }
        }
      }
    }

  }

  .interest-wrap {
    padding-top: 40px;

    .teg-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 20px;

      .teg-link {
        padding: 12px 25px;
        border: 1px solid rgba(var(--border-color), 1);
        border-radius: 4px;
        font-size: 18px;
        color: rgba(var(--content-color), 1);
        line-height: 1;
        cursor: pointer;
      }
    }
  }
}