.hidden {
  overflow: hidden;
}

.loader-box {
  display: none;
}
.table-loader {
  position: relative;
  overflow: hidden;
  .loader-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--white), 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: rgba(var(--content-color), 1);
    h3 {
      font-size: 21px;
      font-weight: 400;
    }
  }
}

.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-default {
  height: 175px;
}

.loader {
  display: flex;
  align-items: center;
  span {
    margin-top: 2px;
  }

  svg {
    animation: loader-round 1s linear infinite;
  }
}

@keyframes loader-round {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}


// Main Loader
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(var(--white), 1);
  z-index: 11;
  top: 0;

  .loader {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 75px;
    display: inline-block;
    vertical-align: middle;
  }

  .loader-1 .loader-outer {
    position: absolute;
    border: 4px solid rgba(var(--theme-color), 0.5);
    border-left-color: transparent;
    border-bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: loader-1-outer 1.5s cubic-bezier(.42, .61, .58, .41) infinite;
  }

  .loader-1 .loader-inner {
    position: absolute;
    border: 4px solid rgba(var(--theme-color), 1);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
    border-right: 0;
    border-top-color: transparent;
    animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
  }

  .loader-1 .loader-inner-1 {
    position: absolute;
    border: 4px solid rgba(var(--theme-color), 0.1);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    border-right: 0;
    border-top-color: transparent;
    animation: loader-1-inner 2s cubic-bezier(.42, .61, .58, .41) infinite;
  }

}

@keyframes loader-1-inner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-1-outer {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes shrink {
  0% {
    transform: translateX(-1em)
  }

  50% {
    transform: translateX(0em)
  }
}

// custom-loader 
.custom-spinner-loader {
  .loader-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: unset !important;
    position: fixed;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loader {
      position: relative;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin: 75px;
      display: inline-block;
      vertical-align: middle;
    }
  
    .loader-1 .loader-outer {
      position: absolute;
      border: 4px solid rgba(var(--theme-color), 0.5);
      border-left-color: transparent;
      border-bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: loader-1-outer 1.5s cubic-bezier(.42, .61, .58, .41) infinite;
    }
  
    .loader-1 .loader-inner {
      position: absolute;
      border: 4px solid rgba(var(--theme-color), 1);
      border-radius: 50%;
      width: 100px;
      height: 100px;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
      border-right: 0;
      border-top-color: transparent;
      animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
    }
  
    .loader-1 .loader-inner-1 {
      position: absolute;
      border: 4px solid rgba(var(--theme-color), 0.1);
      border-radius: 50%;
      width: 60px;
      height: 60px;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      border-right: 0;
      border-top-color: transparent;
      animation: loader-1-inner 2s cubic-bezier(.42, .61, .58, .41) infinite;
    }
  
  }
  
  @keyframes loader-1-inner {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader-1-outer {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(-360deg);
    }
  }
  
  @keyframes shrink {
    0% {
      transform: translateX(-1em)
    }
  
    50% {
      transform: translateX(0em)
    }
  }
}