.navbar {
  padding: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))) 0;

  &.header-wrapper {
    .container-fluid {
      padding: 0;
    }
  }

  .navbar-toggler {
    padding: 0;
    border: 0;
    width: 40px;
    height: 40px;
    @include display;
    background-color: rgba(var(--theme-color), 0.08);
    border-radius: 100%;
    display: none;

    @media (max-width: 991px) {
      display: block;
    }

    span {
      background-image: url(../../images/svg/toggle-nav.svg);
      width: 20px;
      height: 20px;
    }

    &:focus {
      outline: 0;
      border: 0;
      box-shadow: none;
    }
  }

  .navbar-collapse {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: max-content;

    @media (max-width: 991px) {
      position: fixed;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100vh;
      z-index: 10;
    }

    &.show {
      .navbar-nav {
        @media (max-width: 991px) {
          left: 0px;
        }
      }
    }
  }

  .navbar-nav {
    display: flex;
    align-items: center;
    gap: calc(15px + (30 - 15) * ((100vw - 991px) / (1920 - 991)));

    .back-box {
      width: 100%;
      display: none;
      padding-block: 10px;

      .back-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
          font-size: 20px;
          color: rgba(var(--theme-color), 1);
        }

        img {
          height: 30px;
          width: 30px;
        }
      }
    }

    .nav-item {
      .nav-link {
        font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 400;
        padding: 0;
        color: rgba(var(--title-color), 1);
        cursor: pointer;

        &.disabled{
          color: rgba(var(--content-color), 1);
          cursor: not-allowed;
          pointer-events: all;

          .arrow-icon{
           display: none;
          }
          &:hover{
            color: rgba(var(--content-color), 1);
          }
        }

        .arrow-icon {
          filter: grayscale(1) contrast(3);
          width: 11px;
          height: 6px;
        }

        &:hover {
          color: rgba(var(--theme-color), 1);

          .arrow-icon {
            filter: none;
          }
        }

        &.active {
          color: rgba(var(--theme-color), 1);
          font-weight: 500;
        }
      }

      &.has-dropdown {
        position: relative;

        @media (max-width: 991px) {
          width: 100%;

          .nav-link {
            div {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }

        .nav-link {
          span {
            display: flex;
            align-items: center;
            gap: 5px;

            &.active {
              color: rgba(var(--theme-color), 1);
              font-weight: 500;

              .arrow-icon {
                filter: none;
              }
            }
          }

          &.open {
            .arrow-icon {
              transform: scale(-1);
            }
          }

          .mega-menu {
            width: 530px;
            background-color: rgba(var(--white), 1);
            position: absolute;
            top: 60px;
            left: 50%;
            overflow: hidden;
            transform: translateX(-50%);
            z-index: 10;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);

            ul {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              gap: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));

              li {

                a {
                  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                  transition: all 0.3s ease-in-out;
                  color: rgba(var(--title-color), 1);

                  @media (max-width: 991px) {
                    color: rgba(var(--content-color), 1);
                  }

                  &.active {
                    color: rgba(var(--theme-color), 1);
                    font-weight: 500;
                  }
                }

                &:hover {
                  a {
                    color: rgba(var(--theme-color), 1);
                  }
                }

              }
            }

            .mega-img {
              overflow: hidden;
              border-radius: 10px;
              width: 100%;
              height: 220px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
              }

              @media (max-width: 991px) {
                display: none;
              }
            }

            @media (max-width: 991px) {
              width: 280px;
              top: 30px;
              left: 0;
              background-color: white;
              box-shadow: none;
              transform: none;
            }

          }
        }
      }
    }

    @media (max-width: 991px) {
      gap: 20px;
      position: fixed;
      top: 0;
      left: -350px;
      width: 300px;
      background: white;
      text-align: start;
      align-items: flex-start;
      padding: 20px;
      height: 100vh;
      z-index: 10;
      transition: all 0.5s ease-in-out;

      .back-box {
        display: block;
      }
    }

    @media (max-width: 375px) {
      width: 100%;
    }
  }
}

// nav & tabs scss start //
.top-nav-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  .creator-filter-tab {
    .nav-item {
      display: flex;
      align-items: center;
      gap: 10px;

      .nav-link {
        line-height: 1.1;
        border: 1px solid rgba(var(--border-color), 1);
        border-radius: 4px;
        font-size: calc(16px + (17 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--title-color), 1);
        display: flex;
        align-items: center;
        background-color: rgba(var(--white), 1);
        gap: 10px;
        text-wrap: nowrap;
        transition: all 0.10s ease-in-out;
        padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));

        img {
          width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
          height: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
          filter: brightness(0.3);
        }

        &.active {
          background-color: rgba(var(--theme-color), 1);
          color: rgba(var(--white), 1);

          img {
            filter: invert(0.5) brightness(100);
          }
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 425px) {
    flex-direction: column;
    align-items: center;

    .creator-filter-tab {
      width: 100%;

      .nav-item {
        width: 100%;

        .nav-link {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

// nav & tabs scss end //