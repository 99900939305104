.home-section {
  position: relative;
  width: 100%;

  .home-wrapper {
    padding-block: calc(70px + (78 - 70) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f2f8ff;
    border-radius: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    width: 100%;
    text-align: center;
    overflow: hidden; 

    @media (max-width: 992px) {
      padding-bottom: 30px;
    }

    .review-box {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      text-align: left;
      gap: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320)));
      padding: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320))) calc(5px + (18 - 5) * ((100vw - 320px) / (1920 - 320)));
      background-color: rgba(var(--white), 1);
      border-radius: 0 calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) 0px calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));

      @media (max-width: 375px) {
        &::after {
          display: none;
        }

        &::before {
          display: none;
        }
      }


      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: -40px;
        width: 40px;
        height: 40px;
        background-color: #f2f8ff;
        border-radius: 100%;
      }

      &::before {
        content: "";
        position: absolute;
        top: -4px;
        left: -17px;
        background-color: rgba(var(--white), 1);
        width: 28px;
        height: 23px;
      }

      .after-radius {
        position: absolute;
        display: block;
        right: 0;

        @media (max-width: 992px) {
          display: none;
        }


        &::after {
          content: "";
          position: absolute;
          bottom: calc(-79px + (-85 + 79) * ((100vw - 991px) / (1920 - 991)));
          left: -40px;
          width: 40px;
          height: 40px;
          background-color: #f2f8ff;
          border-radius: 100%;
        }

        &::before {
          content: "";
          position: absolute;
          bottom: calc(-55px + (-70 + 55) * ((100vw - 991px) / (1920 - 991)));
          right: calc(-9px + (-6 + 9) * ((100vw - 991px) / (1920 - 991)));
          background-color: #ffffff;
          width: 28px;
          height: 28px;
        }
      }

      .review-img {
        li {
          img {
            height: calc(36px + (46 - 36) * ((100vw - 320px) / (1920 - 320)));
            width: calc(36px + (46 - 36) * ((100vw - 320px) / (1920 - 320)));
            object-fit: cover;
            border-radius: 100%;
            border: 3px solid rgba(var(--white), 1);
          }

          +li {
            margin-left: -15px;
          }
        }
      }

      .review-content {
        .rating {
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            line-height: 1;
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
          }
        }

        h6 {
          color: rgba(var(--content-color), 1);
          font-weight: 400;
        }
      }
    }

    .bg-1 {
      position: absolute;
      top: 0;
      left: 0;
    }

    .bg-2 {
      position: absolute;
      bottom: 0;
      right: 0;

      @media (max-width: 575px) {
        display: none;
      }
    }

    h1 {
      font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
      text-align: center;
      line-height: 1.3;

      span {
        background: rgb(0, 117, 255);
        background: linear-gradient(180deg, rgba(0, 117, 255, 1) 0%, rgba(0, 93, 204, 1) 64%);
        color: rgba(var(--white), 1);
        padding: calc(4px + (7 - 4) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
        display: inline-block;
        transform: translateY(3px) rotate(357deg);
        margin-left: calc(8px + (13 - 8) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1;
      }
    }

    p {
      padding-top: calc(15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
      width: 43%;
      text-align: center;
      margin: auto;
      color: rgba(var(--content-color), 1);

      @media (max-width: 1499px) {
        width: 60%;
      }

      @media (max-width: 1199px) {
        width: 80%;
      }

      @media (max-width: 425px) {
        width: 98%;
      }
    }

  }
}

.home-btns {
  @include display;
  gap: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));

  .btn {
    padding-block: 12px; 

    svg {
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }

}

.brand-section {
  position: relative;
  z-index: 0;

  .swiper-wrapper {
    .swiper-slide {
      @include display;
    }
  }
}

.categories-section {

  @media (max-width: 375px) {
    padding-inline: 12px;
  }
  .home-btns {
    position: relative;
    p {
      background-color: rgba($danger-color, 0.76);
			border-bottom-right-radius: 5px;
			border-top-left-radius: 5px;
			color: $white;
			font-size: 12px;
			font-weight: 600; 
			padding: 4px 12px;
      margin: 0 auto;
			// top: -30px;
    }
  }
  .swiper {
    .swiper-wrapper {
      position: relative;

      .swiper-slide {
        opacity: 0.1;
        @media (max-width: 991px) {
            .banner-part {
                opacity: 0.1;
            }
            &.swiper-slide-active {
              .banner-part{
                opacity: 1;
              }
            }
        } 
        .banner-part {
          img {
            border-radius: 12px;
            height: calc(150px + (250 - 150) * ((100vw - 320px) / (1920 - 320)));
            width: 100%;
            transform: scale(0.85);
            transition: all 0.5s ease-in-out;
            object-fit: cover;
          }

          .categories-title {
            text-align: center;
            opacity: 0;
            visibility: hidden;
            color: rgba(var(--title-color), 1);
          }
          
        }

        &.swiper-slide-active {
          opacity: 1;
          img {
            transform: scale(1);
          }

          .categories-title {
            margin-top: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
            opacity: 1;
            visibility: visible;
          }
        }

        &.swiper-slide-next,
        &.swiper-slide-prev {
          opacity: 1;
          .categories-title {
            opacity: 1;
            visibility: visible;
          }
         
        }
      }
    }
  }
  .arrow-style-1 {
    &.arrow-style-2 {
        .swiper-button-next,
        .swiper-button-prev {
            @media (max-width: 1199px) {
              display: block !important;
            }
            @media (max-width: 991px) {
              top: 55%;
            }
        }
        .swiper-button-next {
          @media (max-width: 575px) {
            right: 11%;
          }
        }
        .swiper-button-prev {
          @media (max-width: 575px) {
            left: 11%; 
          }
        }
    }
  }
  .swiper-slide-prev {
    .banner-part {
      img {
        margin-left: -20px;
      }
     
    }
  }

  .swiper-slide-next {
    .banner-part {
      img {
        margin-left: 20px;
      }
    }
  }
}

.service-section {
  position: relative;
  margin-top: calc(25px + (60 - 25) * ((100vw - 320px) / (1920 - 320)));

  .service-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .service-detail {
    h3 {
      font-size: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    sup {
      color: $primary-color;
      background-color: rgba($primary-color, 0.15);
      border-radius: 5px; 
      font-size: 8px;
      font-weight: 600;
      padding: 4px;
      right: 3px;
      top: -7px;
    }
    p {
      color: rgba(var(--content-color), 1);
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))) 0 calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
      line-height: 1.5;
      &.custom-badge {
        // font-size: 12px;
        padding-top: 0px;
      }
      @media (max-width: 575px) {
        line-height: 1.3;
      }

    }

    ul {
      padding-block: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
      display: flex;
      align-items: center;
      gap: calc(10px + (50 - 10) * ((100vw - 320px) / (1920 - 320)));

      li {
        h3 {
          font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
          color: rgba(var(--title-color), 1);
        }

        color: rgba(var(--content-color), 1);
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.2;
      }
    }
  }

  .service-row {
    justify-content: center;

    >div {
      &:nth-last-child(-n + 2) {

        @media (max-width: 991px) {
          transform: translateX(0);
        }
      }
    }
  }

  .service-box {
    transition: all 0.5s ease-in-out;
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(var(--white), 1);
    border-radius: 10px;
    height: 100%;

    &:hover {
      h5 {
        color: rgba(var(--theme-color), 1);
      }

      transform: translateY(calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320))));
    }

    .service-img {
      @include display;
      width: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
      height: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
      border-radius: 10px;
      background-color: rgba(var(--white), 1);
      box-shadow: 0 0 8px 0 rgba(var(--theme-color), 0.08);
      padding: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h5 {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      padding-top: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    p {
      padding-top: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
      color: rgba(var(--content-color), 1);
      font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    @media (max-width: 575px) {
      text-align: center;

      .service-img {
        margin: auto;
      }
    }
  }
}

.testimonial-section {
  position: relative;

  .sub-title {
    width: 55%;
    margin: auto;

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  @keyframes crescendo {
    0% {
      transform: scale(.9);
    }

    100% {
      transform: scale(1);
    }
  }

  .bg-img {
    position: absolute;
    border-radius: 100%;
    width: calc(80px + (100 - 80) * ((100vw - 991px) / (1920 - 991)));
    height: calc(80px + (100 - 80) * ((100vw - 991px) / (1920 - 991)));
    animation: crescendo 4s alternate 1s infinite ease-in;

    &.user-1 {
      top: 26%;
      left: 16%;
      animation: crescendo 4s alternate 2s infinite ease-in;
    }

    &.user-2 {
      top: 45%;
      left: 5%;
      width: 60px;
      height: 60px;
      animation: crescendo 4s alternate 0.5s infinite ease-in;
    }

    &.user-3 {
      top: 66%;
      left: 22%;
      width: 50px;
      height: 50px;
      animation: crescendo 4s alternate 0.5s infinite ease-in;
    }

    &.user-4 {
      top: 50%;
      right: 10%;
      animation: crescendo 4s alternate 2s infinite ease-in;
    }

    &.user-5 {
      top: 30%;
      right: 24%;
      width: 80px;
      height: 80px;
      animation: crescendo 4s alternate 0s infinite ease-in;
    }

    &.user-6 {
      top: 80%;
      right: 20%;
      width: 50px;
      height: 50px;
      animation: crescendo 4s alternate 0.5s infinite ease-in;
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  .swiper-slide {
    padding: calc(30px + (55 - 30) * ((100vw - 320px) / (1920 - 320))) calc(6px + (20 + 6) * ((100vw - 320px) / (1920 - 320))) calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    gap: 5px;
    bottom: 2px;
  }

  .testimonial-box {
    position: relative;
    background-color: rgba(var(--white), 1);

    .quotes-img {
      position: absolute;
      top: calc(-30px + (-46 + 30) * ((100vw - 320px) / (1920 - 320)));
      left: calc(10px + (25 + 10) * ((100vw - 320px) / (1920 - 320)));
      z-index: 2;
      width: calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)));
      height: calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)));
    }

    .testimonial-img {
      width: 100%;
      @include display;
      overflow: hidden;
      position: absolute;
      top: calc(-30px + (-50 + 30) * ((100vw - 320px) / (1920 - 320)));
      left: 50%;
      transform: translateX(-50%);

      img {
        border-radius: 100%;
        width: calc(50px + (80 - 50) * ((100vw - 320px) / (1920 - 320)));
        height: calc(50px + (80 - 50) * ((100vw - 320px) / (1920 - 320)));
        object-fit: cover;
      }
    }

    .testimonial-detail {
      padding: calc(33px + (67 - 33) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      box-shadow: 0 0 13px 0 rgba(var(--theme-color), 0.05);


      p {
        color: rgba(var(--content-color), 1);
        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
        text-align: center;
        padding-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
      }

      h5 {
        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
      }

      .testimonial-bottom {
        @include display($dis: flex, $align: center, $justify: space-between);

        span {
          color: rgba(var(--content-color), 1);
          font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
        }

        h6 {
          font-weight: 400;
          color: rgba(var(--content-color), 1);
        }
      }
    }
  }
}