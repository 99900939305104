.blog-box {
    position: relative;
    overflow: hidden;

    &:hover {
        .blog-img {
            img {
                transform: scale(1.1);
            }
        }

        .blog-detail {
            h5 {
                color: rgba(var(--theme-color), 1);
            }
        }
    }

    .blog-img {
        aspect-ratio: 45 / 25;
        overflow: hidden;
        border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

        label {
            position: absolute;
            top: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
            left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
            padding: calc(3px + (7 - 3) * ((100vw - 320px) / (1920 - 320))) calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1;
            background-color: rgba(var(--white), 1);
            border-radius: calc(4px + (6 - 4) * ((100vw - 320px) / (1920 - 320)));
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
            z-index: 1;
        }

        img {
            overflow: hidden;
            transform: scale(1);
            transition: all 0.5s ease-in-out;
        }
    }

    .blog-detail {
        padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

        h5 {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            padding-bottom: 6px;
            transition: all 0.5s ease-in-out;
        }

        p {
            font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(var(--content-color), 1);
            margin-bottom: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }

        a {
            text-decoration: underline;
            font-size: 15px;
        }
    }
}