header {
  position: relative;
  z-index: 5;
}

.header-btn {
  display: flex;
  align-items: center;
  gap: calc(8px + (20 - 8) * ((100vw - 991px) / (1920 - 991)));

  .btn-theme {
    display: flex;
    align-items: center;
    gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

    img {
      width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
      height: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.user-header {
  display: none;

  .user-icon {
    width: 40px;
    height: 40px;
    @include display;
    background-color: rgba(var(--theme-color), 0.1);
    border-radius: 100%;
  }

  @media (max-width: 991px) {
    display: block;
  }
}

.user-profile-box {
  position: relative;

  .profile-box {
    display: flex;
    align-items: center;
    gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

    &.open {
      ~.profile-detail {
        display: block;
        padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
      }

      .arrow-image {
        transform: scale(-1);
      }
    }

    .profile-img {
      background-color: rgba(var(--theme-color), 0.1);
      width: calc(40px + (45 - 40) * ((100vw - 991px) / (1920 - 991)));
      height: calc(40px + (45 - 40) * ((100vw - 991px) / (1920 - 991)));
      border-radius: 100%;
      @include display;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .profile-name {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      position: relative;
      cursor: pointer;

      h5 {
        font-size: calc(15px + (17 - 15) * ((100vw - 991px) / (1920 - 991)));
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        max-width: calc(85px + (150 - 85) * ((100vw - 991px) / (1920 - 991)));
      }

      h6 {
        color: rgba(var(--content-color), 1);
        font-weight: 400;
        padding-top: 3px;
        font-size: calc(14px + (16 - 14) * ((100vw - 991px) / (1920 - 991)));
      }

      img {
        width: 13px;
        height: 13px;
        margin-top: calc(2px + (4 - 2) * ((100vw - 1050px) / (1920 - 1050)));
      }

      @media (max-width: 991px) {
        display: flex;
      }

      @media (max-width: 576px) {
        // display: none;
        div {
          h5 {
            display: none;
          }
        }
        .arrow-image {
          display: none; 
        }
      }
    }
  }
  @media (max-width: 576px) {
    .profile-box {
      flex-direction: column;
      .profile-name {
        h6 {
          padding-top: 0px;
          gap: 0px;
        }
      }
    }
  }
  
  .profile-detail {
    display: none;
    position: absolute;
    top: calc(48px + (65 - 48) * ((100vw - 320px) / (1920 - 320)));
    padding: 0;
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.1);
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(var(--white), 1);
    width: auto;
    min-width: calc(115px + (150 - 115) * ((100vw - 320px) / (1920 - 320)));
    z-index: 2;

    @media (max-width: 1050px) {
      left: unset;
      right: 0;
      transform: none;
      width: max-content;
    }

    .profile-menu {
      ul {
        display: flex;
        flex-direction: column;

        li {
          width: 100%;

          &:first-child {
            a {
              padding-top: 0;
            }
          }

          &:last-child {
            a {
              border-bottom: none;
              padding-bottom: 0;
            }
          }

          a {
            text-align: start;
            font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
            display: block;
            border-bottom: 1px solid rgba(var(--border-color), 1);
            padding-block: 10px;
            color: rgba(var(--content-color), 1) !important;
            

            img {
              width: calc(22px + (24 - 22) * ((100vw - 320px) / (1920 - 320)));
              height: calc(22px + (24 - 22) * ((100vw - 320px) / (1920 - 320)));
            }

            .profile-icon {
              filter: grayscale(1);
              opacity: 0.8;
            }

            &:hover {
              color: rgba(var(--theme-color), 1);

              .profile-icon {
                filter: none;
                opacity: 1;
              }
            }

            &.logout-link {
              color: rgba(var(--error), 1);
            }
          }
        }
      }
    }
  }
}

.dashboard-header {
  padding-block: 13px;
  border-bottom: 1px solid rgba(var(--border-color), 1);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 13px 0 rgba(var(--content-color), 0.2);
  position: sticky;
    top: 0px;
    background-color: $white;
    // backdrop-filter: blur(10px);
    z-index: 2;

  .header-wrap {
    margin-left: 330px;
    margin-right: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .main-header {
      .nav-menu {
        display: flex;
        align-items: center;
        gap: 30px;
        flex-direction: row;

        .nav-item {
          font-weight: 400;
          font-size: 18px;
          color: rgba(var(--title-color), 1);
          transition: all 0.3s ease-in-out;

          &:hover {
            color: rgba(var(--theme-color), 1);
            font-weight: 500;
          }
        }
      }
    }

    .profile-menu {
      .profile-item {
        display: flex;
        align-items: center;
        gap: 20px;

        li {
          display: flex;
          align-items: center;
          gap: 10px;

          .theme-icon {
            width: 44px;
            height: 44px;
            background-color: rgba(var(--theme-color), 0.1);
            @include display;
            border-radius: 100%;
            overflow: hidden;
          }

          &.user-profile {
            .user-name {
              h5 {
                color: rgba(var(--title-color), 1);
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 3px;
              }

              h6 {
                color: rgba(var(--content-color), 1);
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  &.close-action {
    transition: all 0.3s ease-in-out;

    .header-wrap {
      margin-left: 130px;
    }
  }
}