.footer-section {
    background: url('../../images/footer-bg.jpg') center center no-repeat;
    background-size: cover;

    .footer-detail {
        p {
            color: rgba(var(--content-color), 1);
            font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
        }

        .social-link {
            padding-top: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
            display: flex;
            align-items: center;
            gap: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));

            li {
                width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
                height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
                background-color: rgba(var(--white), 1);
                border-radius: 100%;
                @include display;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .footer-menu {

        &.open {
            .menu-title {
                img {
                    transform: scale(-1);
                }
            }

            ul {
                display: flex;
            }
        }

        .menu-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: rgba(var(--white), 1);
            font-size: 16px;
            padding-bottom: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));

            img {
                transform: scale(1);
                transition: all 0.2s ease-in-out;
                filter: invert(1);
                display: none;
                width: 16px;
                height: 16px;

                @media (max-width: 575px) {
                    display: block;
                }
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

            @media (max-width: 575px) {
                display: none;
            }


            li {
                transition: all 0.3s ease-in-out;

                a {
                    transition: all 0.3s ease-in-out;
                    color: rgba(var(--content-color), 1);
                    font-size: 15px;
                }

                &:hover {
                    a {
                        color: rgba(var(--white), 1);
                    }
                }
            }

        }
    }

    .sub-footer {
        padding-block: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
        border-top: 1px solid rgba(var(--border-color), 0.1);

        p {
            color: rgba(var(--content-color), 1);
            font-size: 14px;
        }

        @media (max-width: 450px) {
            flex-direction: column;
            gap: 10px;

            p{
                text-align: center;
            }
        }
    }
}