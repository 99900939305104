.newsletter-section {
    position: relative;
    background-color: rgba(var(--theme-color), 0.08);
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.42) 64%);
        height: 100%;
    }

    .news-img {
        position: absolute;
        top: -108px;
        right: -34px;
        object-fit: cover;
        width: calc(400px + (800 - 400) * ((100vw - 767px) / (1920 - 767)));
        // height: 100vh;
        z-index: -1;
        overflow-y: auto;
        transform: rotate3d(1, 1, 1, 333deg);
        animation: news 20s 1s infinite ease-in-out;

        @media (max-width: 767px) {
            display: none; 
        }
    }

    @keyframes news {
        0% {
            transform: translateY(0) rotate3d(1, 1, 1, 333deg);
        }

        100% {
            transform: translateY(-200px) rotate3d(1, 1, 1, 333deg);
        }
    }

    h3 {
        font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
    }

    p {
        color: rgba(var(--content-color), 1);
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        padding-top: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
    }

    .btn {
        padding-block: 12px;

        svg {
            transition: all 0.5s ease-in-out;
        }

        &:hover {
            svg {
                transform: translateX(5px);
            }
        }
    }

}

.input-group {

    .news-input {
        width: 100%;
        padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 4px !important;
        background-color: rgba(var(--white), 1);
        border: 0;
        padding-right: 30%;

        @media (max-width: 1400px) {
            padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320))) 33% calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320))) 12px;
        }

        @media (max-width: 576px) {
            padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320))) 45% calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320))) 12px;
        }


        &::placeholder {
            font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
            color: rgba(var(--content-color), 1);
        }

        &:focus-visible {
            outline: none;
        }
    }

    .btn {
        border-radius: 4px !important;
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
        padding-block: 12px;
    }
}