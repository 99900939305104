.form-select {
  padding: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
  border: none;
  border: 1px solid rgba(var(--border-color), 1);
  border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
  text-transform: capitalize;
  cursor: pointer;
  gap: 20px;

  &:focus {
    border: none;
    box-shadow: none;
  }

  &:visited {
    outline: none;
  }
}

input {
  &[type="number"] {
    appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

.password-wrap {
  position: absolute;
  right: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
  top: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
  cursor: pointer;

  img {
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
  }
}

.form-icon {
  position: absolute;
  left: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
  top: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
  width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
  height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.form-icons {
  position: absolute;
  // left: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
  // top: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
  left: -20px;
  top: 28px;
  width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
  height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.form-title {
  font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
  color: rgba(var(--title-color), 1);
  font-weight: 600;
  width: 100%;
  text-align: left;
  padding-bottom: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
}

input {
  &:-internal-autofill-selected {
    background-color: rgba(var(--error), 1) !important; // Add your desired background color
    color: rgba(var(--content-color), 1);
  }
}

.input-label {
  width: 100%;
  color: rgba(var(--content-color), 1);
  text-align: left;
  padding-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.required-note {
  color: rgba(var(--error), 1);
  font-size: 12px;
}

.form-control {
  padding: calc(9px + (12 - 9) * ((100vw - 320px) / (1920 - 320))) calc(38px + (44 - 38) * ((100vw - 320px) / (1920 - 320)));
  border: 1px solid rgba(var(--border-color), 1);
  border-radius: 4px;
  font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));

  &::placeholder {
    color: rgba(var(--content-color), 1);
  }

  &:focus {
    box-shadow: none;
    border: 1px solid rgba(var(--border-color), 1);
  }

  &.invalid-field {
    border: 1px solid rgba(var(--error), 1);
    background-color: #ffffff;
    color: rgba(var(--error), 1);
  }
}

.select-form {
  position: relative;
  text-align: start;
  cursor: pointer;

  &.open {
    transition: all 0.5s ease-in-out;

    .form-select {
      img {
        transform: scale(-1);
      }
    }
  }

  .selected {
    color: rgba(var(--title-color), 1);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    line-height: 1.1;
  }

  .form-select {
    color: rgba(var(--content-color), 1);
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      transform: scale(1);
      transition: all 0.5s ease-in-out;
      opacity: 0.5;
    }

    option {
      color: rgba(var(--title-color), 1);
      font-size: 16px;
    }
  }

  .form-option {
    position: absolute;
    top: calc(70px + (80 - 70) * ((100vw - 320px) / (1920 - 320)));
    left: 0;
    z-index: 2;
    max-height: 270px;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    justify-content: flex-start;
    background: #ffffff;
    gap: 5px;
    box-shadow: 0px 0px 20px 0 rgba(var(--content-color), 0.1);
    transition: all 0.5s ease-in-out;
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));

    &.inner-form {
      top: calc(55px + (65 - 55) * ((100vw - 320px) / (1920 - 320)));
    }

    li {
      width: 100%;
      cursor: pointer;

      label {
        cursor: pointer;
      }

      &:hover {
        .inner-list {
          label {
            color: rgba(var(--theme-color), 1);
          }

          .check-box {
            &::before {
              border-color: rgba(var(--theme-color), 1);
            }
          }
        }
      }

      &:first-child {
        .inner-list {
          padding-top: 0;
        }
      }

      &:last-child {
        .inner-list {
          border-bottom: none;
          padding-bottom: 0;
        }
      }

      .inner-list {
        font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: rgba(var(--content-color), 1);
        text-transform: capitalize;
        display: flex;
        align-items: center;
        gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
        border-bottom: 1px solid #eee;
        text-align: start;
        padding-block: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
      }
    }
  }
}

.form-control {
  .select-form {
    .inner-select {
      display: flex;
      justify-content: space-between;

      span {
        line-height: unset;
      }
    }

    .inner-form {
      top: 45px;
    }
  }

  .form-select {
    min-width: none;
    max-width: none;
    padding: 0;
    background-color: transparent;

    span {
      line-height: unset;
    }
  }
}

.form-floating {
  line-height: 1.4;

  label {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.form-control {
  &.error-input {
    color: red;
    border: 1px solid red;
  }
}

.css-13cymwt-control,
.css-t3ipsp-control,
.css-16xfy0z-control {
  cursor: pointer !important;
  border: 1px solid rgba(var(--border-color), 1) !important;
  padding: 14px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  text-align: left !important;

  .css-1fdsijx-ValueContainer {
    padding: 0 !important;
  }
}

.css-t3ipsp-control {
  padding: 14px !important;

  .css-1hb7zxy-IndicatorsContainer {
    .css-1hb7zxy-IndicatorsContainer {
      padding: 0 !important;
    }
  }
}

.css-qbdosj-Input,
.css-1h01tm3-Input {
  margin: 0 !important;
  padding: 0 !important;
}

.css-1hb7zxy-IndicatorsContainer {
  .css-1u9des2-indicatorSeparator {
    width: 0 !important;
    padding: 0 !important;
  }
}

.css-1nmdiq5-menu {
  text-align: left !important;
  border: 1px solid rgba(var(--border-color), 1) !important;
  box-shadow: none !important;

  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: rgba(var(--theme-color), 1);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(var(--white), 1);
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(var(--theme-color), 0.4);
    border-radius: 6px;
  }
}

.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer {
  .css-tj5bde-Svg {
    display: none !important;
  }

  background: url("../../images/svg/arrow-down.svg") center center no-repeat !important;
  object-fit: cover !important;
  opacity: 0.5 !important;
}

.css-166bipr-Input {
  padding: 0 !important;
  margin: 0 !important;
}



//  new select


.select-wrapper {
  position: relative;

  &.selected {
    .select-btn {
      button {
        color: rgba(var(--theme-color), 1);
        border: 1px solid rgba(var(--theme-color), 1);
      }
    }
  }

  .select-btn {
    position: relative;

    button {
      font-size: 16px;
      font-weight: 400;
      text-transform: none;
      border: 1px solid rgba(var(--border-color), 1);
      color: rgba(var(--title-color), 0.8);
      border-radius: 4px;
      padding: 8px 12px !important;

      .taglNews {
        padding: 0px 4px;
        border-radius: 4px 0 4px 0;
        background-color: #ff4f4f;
        color: #fff;
        font-weight: 500;
        font-size: 10px;
        margin: 0 !important;
        transform: translate(3px, -8px);
      }

      &.open {
        color: rgba(var(--theme-color), 1);
        border: 1px solid rgba(var(--theme-color), 1);
      }

      .check-icon {
        font-size: 18px;
      }
    }

    @media (max-width: 510px) {
      display: none;
    }
  }
}

.MuiTextField-root {
  label {
    transform: translate(14px, calc(11px + (13 - 11) * ((100vw - 320px) / (1920 - 320)))) scale(1);
    font-size: 15px;

    &.Mui-focused,
    &[data-shrink="true"] {
      color: rgba(var(--theme-color), 1);
      transform: translate(14px, -7px) scale(0.7);
    }
  }

  .MuiOutlinedInput-root {
    &:hover {
      fieldset {
        border-color: rgba(var(--border-color), 1) !important;
      }
    }

    &.Mui-focused,
    &[data-shrink="true"] {
      &:hover {
        fieldset {
          border: 2px solid rgba(var(--theme-color), 1) !important;
        }
      }
    }
  }

  input {
    padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflowing content */
    text-overflow: ellipsis;
    /* Add ellipsis for truncated text */
    background-color: #fff;

    &::placeholder {
      text-overflow: ellipsis;
      /* Apply ellipsis to placeholder text */
    }
  }


  fieldset {
    border: 1px solid rgba(var(--border-color), 1);

    &:hover {
      border: 1px solid rgba(var(--border-color), 1);
    }
  }
}

.filter-body-wrap {
  .filter-row {
    .filter-box {
      .menu-list-wrap {
        .list-row {
          &.active {
            box-shadow: 0 0 13px rgba(var(--border-color), 1);
            position: relative;
            z-index: 9;
          }

          &.disabled {
            border: none !important;

            .select-child {
              padding: 0px !important;

              .select-inner {
                .inner-icon-line {
                  span {
                    margin: 0px 0px 0px 0px !important;
                  }
                }
              }
            }
          }

          border-radius: 4px;
          border: 1px solid rgba(var(--border-color), 1);
          background-color: rgba(var(--white), 1);
        }
      }
    }
  }
}

.select-child {
  position: absolute;
  top: 60px;
  left: 0;
  background-color: rgba(var(--white), 1);
  min-width: 335px;
  z-index: 1;
  padding: 10px;
  box-shadow: 0 0 13px rgba(var(--border-color), 1);
  border: 1px solid rgba(var(--border-color), 1);
  border-radius: 4px;

  @media (max-width: 575px) {
    left: calc(-86px + (-76 - -86) * ((100vw - 320px) / (576 - 320)));
    min-width: unset;
    width: calc(300px + (430 - 300) * ((100vw - 320px) / (576 - 320)));
  }

  .select-inner {
    gap: 10px;
    align-items: center;
    width: 100%;
    border-radius: 4px;

    .text-input-wrap {
      position: relative;

      .text-input {
        position: relative;
        width: 100%;

        input {
          padding: 9px;
          margin: 0;
        }
      }

      button {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 0;
        max-width: max-content;
        min-width: unset;
        justify-content: end;
        cursor: pointer;
        background-color: rgba(var(--theme-color), 0.07);
      }
    }

    .select-checkbox {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    label {
      transform: translate(14px, 11px) scale(1);
      font-size: 13px;

      &.Mui-focused,
      &[data-shrink="true"] {
        color: rgba(var(--theme-color), 1);
        transform: translate(15px, -6px) scale(0.8);
      }

      &.label-text {
        transform: none;
        color: rgba(var(--title-color), 1);
        line-height: 1.2;
        font-size: 14px;
        width: calc(100% - 30px);
        cursor: pointer;
      }
    }


    .MuiInputBase-root {
      &.Mui-focused {
        fieldset {
          border: 2px solid rgba(var(--theme-color), 1);
        }
      }

      input {
        padding: 0;
        font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    .count-wrapper {
      input {
        padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
      }


    }

    .count-error {
      margin-top: 7px;
      color: rgba(var(--error), 1);
      font-size: 13px;
    }

    .chip-wrapper {
      margin-top: 10px;
      display: flex;
      gap: 7px;
      flex-wrap: wrap;

      &:empty {
        margin-top: 0;
      }
    }

    ul {
      margin-top: 10px;

      li {
        color: rgba(var(--title-color), 1);

        strong {
          text-transform: capitalize;
        }
      }
    }

    @media (max-width: 575px) {
      display: block;
    }

    .css-188mx6n-MuiSlider-root {
      color: rgba(var(--theme-color), 1) !important;
    }

    .css-1228x3t {
      color: rgba(var(--theme-color), 1) !important;
    }

    .css-ttgsjq-MuiSlider-track {
      background-color: rgba(var(--theme-color), 1) !important;
    }

    .css-10opxo5 {
      background-color: rgba(var(--theme-color), 1) !important;
    }

    .css-5s1qf1 {
      &.Mui-active {
        box-shadow: 0px 0px 0px 8px rgba(25, 118, 210, 0.16) !important;
      }
    }

    .css-cp2j25-MuiSlider-thumb {
      width: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))) !important;
      height: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320))) !important;

      &.Mui-active {
        box-shadow: 0px 0px 0px 8px rgba(25, 118, 210, 0.16) !important;
      }
    }

    .select-inner-box {
      border: 1px solid rgba(var(--border-color), 1);
      padding: 10px;
    }
  }

  .select-box {
    display: flex;
    align-items: center;
    gap: 14px;

    .left,
    .right {
      width: 100%;
      border: 1px solid #eee;
      border-radius: 6px;
      padding: 10px;
      text-align: center;
    }

    h5 {
      color: #777;
      font-weight: 400;

      span {
        font-weight: 500;
        color: #222;
      }
    }
  }
}

.topic-input {
  .MuiAutocomplete-input {
    padding: 6px !important;
  }

  .css-igs3ac {
    border-color: rgba(var(--border-color), 1) !important;
  }

  .css-1x6bjyf-MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      padding: 6px !important;
    }

    .MuiAutocomplete-tag {
      margin: 0px 3px !important;
    }
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: rgba(var(--title-color), 0.8) !important;
    line-height: 1 !important;
  }

  .MuiInputBase-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(var(--border-color), 1) !important;
      }
    }

    &:focus,
    &.Mui-focused,
    &:active {
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(var(--theme-color), 1) !important;

        &:hover {
          border-color: rgba(var(--theme-color), 1) !important;
        }
      }
    }

    .MuiAutocomplete-endAdornment {
      .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
        display: none !important;

        svg {
          display: none !important;
        }
      }
    }

    .css-38raov-MuiButtonBase-root-MuiChip-root {
      height: 28px !important;
    }
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: rgba(var(--border-color), 1) !important;

    &:hover {
      border-color: rgba(var(--border-color), 1) !important;
    }
  }
}


.MuiChip-root {
  background-color: rgba(var(--theme-color), 0.07) !important;
  cursor: pointer !important;

  svg {
    color: rgba(var(--theme-color), 0.4) !important;

    &:hover {
      color: rgba(var(--theme-color), 0.7) !important;
    }
  }
}

.MuiTooltip-tooltip {
  margin: 0 !important;
  font-size: 13px !important;
  max-width: 220px !important;
  width: auto;
  min-width: 150px !important;
  background-color: rgba(var(--title-color), 1) !important;
}

// .MuiTooltip-popper[data-popper-placement*="right"] {
//   margin-left: 10px !important;

//   &::before {
//     content: "";
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%) rotate(45deg);
//     left: -7px;
//     width: 13px;
//     height: 13px;
//     background-color: rgba(var(--title-color), 1);
//     transition: all 133ms cubic-bezier(0.4, 0, 0.2, 1);
//   }
// }

.MuiInputBase-root {
  position: relative;

  &:hover {
    fieldset {
      border-color: rgba(var(--border-color), 1) !important;
    }
  }

  fieldset {
    border: 1px solid rgba(var(--border-color), 1) !important;
  }

  .MuiSvgIcon-root {
    font-size: 16px;
    cursor: pointer;
  }

  .MuiSelect-select {
    padding: 9px 32px 7px 11px !important;
    min-width: 16px !important;
    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.5;

    @media (max-width: 991px) {
      line-height: 1.4;
    }

    @media (max-width: 440px) {
      padding: 8px 32px 6px 11px !important;
    }
  }

  &.Mui-focused {
    [aria-expanded="false"] {
      &.MuiSelect-select {
        ~fieldset {
          border-color: rgba(var(--border-color), 1) !important;
          border-width: 1px !important;
        }

      }
    }

    [aria-expanded="true"] {
      &.MuiSelect-select {
        ~fieldset {
          border-color: rgba(var(--theme-color), 1) !important;
          border-width: 2px !important;
        }

      }
    }
  }

}