@media (min-width: 1434px) {
    .container {
        // max-width: 1410px;
        max-width: unset;
        padding: 0 calc(15px + (200 - 15) * ((100vw - 320px) / (1920 - 320)));
    }
}

.logo {
    cursor: pointer;
    height: 63px;
    height: calc(38px + (63 - 38) * ((100vw - 320px) / (1920 - 320)));
}

.custom-container {
    padding-inline: calc(12px + (155 - 12) * ((100vw - 320px) / (1920 - 320)));
}

section,
.xlt-space {
    padding-top: calc(35px + (80 - 35) * ((100vw - 320px) / (1920 - 320)));
}

.xlb-space {
    padding-bottom: calc(35px + (80 - 35) * ((100vw - 320px) / (1920 - 320)));
}

.lt-space {
    padding-top: calc(25px + (60 - 25) * ((100vw - 320px) / (1920 - 320)));
}

.lb-space {
    padding-bottom: calc(25px + (60 - 25) * ((100vw - 320px) / (1920 - 320)));
}

.mt-space {
    padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.mb-space {
    padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.st-space {
    padding-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.sb-space {
    padding-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.custom-form-w {
    max-width: 450px;
    margin-inline: auto;

    .otp-code {
        .otp-field {
            height: 70px;
            width: 70px;
        }
    }
}

.custom-header-bar {
    background-color: rgba(var(--theme-color), 1) !important;
    color: $white !important;
    padding: calc(12px + 2 * (100vw - 320px) / 1600) 0 !important;

    .announcement {
        span {
            font-weight: 900;
        }
    }
}

.fade-in {
    animation: fadeInOut 4s ease-in-out infinite;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.custom-bg-overscroll {
    overflow: hidden;
}

.loading-overlay {
    background: $white !important;
}

.creators-table-wrap {
    position: unset !important;

    .loading-overlay {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100vh;
        width: 100vw;
        background: rgb(255 255 255 / 85%);
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        z-index: 4;
    }
}

.custom-faq-accordion {
    .accordion {
        .accordion-item {
            &.active {
                .accordion-header {
                    border: 1px solid $primary-color !important;

                    .accordion-button {
                        background-color: $primary-color !important;
                    }
                }
            }
        }
    }
}

.custom-feedback-btn {
    .feed-btn {
        position: fixed;
        right: -54px;
        top: 50%;
        z-index: 4;
        rotate: 270deg;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);

        @media (max-width: 991px) {
            right: -48px;
        }

        @media (max-width: 767px) {
            right: -46px;
        }

        @media (max-width: 575px) {
            z-index: 11;
        }
    }
}

.instagram-bg {
    background-image: linear-gradient(43deg, #F09433, #E6683C, #DC2743, #CC2366, #BC1888);
}

.instagram-bg-light {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    background-image: linear-gradient(43deg, rgba(240, 148, 51, 0.30), rgba(230, 104, 60, 0.25), rgba(220, 39, 67, 0.32), rgba(204, 35, 102, 0.37), rgba(188, 24, 136, 0.10));
}

/*======= Background-color css end  ======= */
.scroll-bar {

    /* Firefox */
    & {
        scrollbar-width: auto;
        scrollbar-color: rgba(var(--theme-color), 1);
    }

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(var(--theme-color), 0.1);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(var(--theme-color), 0.5);
        border-radius: 6px;
    }

    &-hide {
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
}

/**====== custom scrollbar css start ======**/
.custom-scrollbar,
.MuiTableContainer-root {
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $light-gray;
    }

    &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba($primary-color, 0.1);
    }
}

.custom-filter-offcanvas {
    position: fixed !important;
    background: $white !important;

    ~.offcanvas-backdrop {
        z-index: 0 !important;
    }
}

.bg-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.5s ease-in-out;

    &.active {
        display: block;
    }
}

body::-webkit-scrollbar {
    display: none;
}

.custom-login-activity {
    thead {
        background-color: var(--theme-color);

        tr {
            th {
                padding: 12px;
            }
        }
    }
}

.ai-search-panel {
    &:empty {
        display: none;
    }

    .MuiFormControl-root {
        .MuiFormGroup-root {
            label {
                .MuiButtonBase-root {
                    &.Mui-checked {
                        span {
                            svg {
                                fill: $primary-color ;
                            }

                        }

                        ~span {
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}

.user-profile {
    .user-img {
        display: flex;
        gap: calc(10px + (22 - 10) * ((100vw - 320px) / (1920 - 320)));
        padding: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
        background-color: $white;
        box-shadow: 0px 0px 20px 0px rgba(47, 47, 47, 0.05);
        border-radius: 6px;
        align-items: center;

        @media (max-width: 480px) {
            align-items: start;
        }

        &:hover {
            .profile-img {
                .edit-icon {
                    bottom: 5px;
                    right: 5px;
                }
            }
        }

        .profile-img {
            width: 100px;

            @media (max-width: 480px) {
                width: 90px;
            }

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        .user-details {
            width: calc(100% - 100px);
            display: flex;
            align-items: center;
            margin-left: auto;

            @media (max-width: 480px) {
                flex-wrap: wrap;
                gap: 12px;
            }

            h4 {
                margin-bottom: 8px;
                font-weight: 600;
                font-size: 14px;

                img {
                    height: 16px;
                    vertical-align: baseline;
                }
            }

            .custom-badge-url {
                background-color: $sidebar-background;
                border-radius: 6px;
                width: 140px;

                @media (max-width: 575px) {
                    width: 100px;
                }

                padding: 8px;
                margin-bottom: 0 !important;
            }

            .custom-analytics-selected {
                margin-top: 15px;
                min-width: 140px;

                label {
                    color: rgba(var(--title-color), 0.5);
                    font-weight: 500;
                }

                .form-control {
                    padding: 4px 5px;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            h6 {
                margin-bottom: 12px;
                font-weight: 400;
                font-style: italic;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 5px;

                img {
                    height: 16px;
                }
            }

            p {
                font-weight: 500;
                color: rgba(var(--title-color), 0.5);
                width: 75%;
            }

            .edit-icon {
                border: 1px solid rgba(var(--black), 0.1);
                padding: 2px 12px;
                border-radius: 4px;
                display: flex;
                min-width: 170px;
                align-items: center;
                justify-content: center;
                gap: 10px;
                cursor: pointer;
                transition: all .4s ease-in-out;

                p {
                    color: rgba(var(--title-color), 1);
                    transition: all .4s ease-in-out;
                }

                >img {
                    height: 12px;
                    width: 12px;
                    border-radius: 0;
                    transition: all .4s ease-in-out;
                }

                &:hover {
                    background-color: rgba($primary-color, 1);

                    >img {
                        filter: invert(1);
                    }

                    p {
                        color: rgba($white, 1);
                    }
                }
            }
        }
    }

    .header-breadcrumbs-box {
        padding: 14px 18px;
        background-color: rgba($primary-color, 0.08);

        .custom-back-btn {
            display: flex;
            align-items: center;
            gap: 6px;
            cursor: pointer;

            span {
                color: $primary-color;
            }

            .back-arrow-custom {
                height: 16px;
                width: 16px;
                filter: invert(28%) sepia(93%) saturate(2729%) hue-rotate(203deg) brightness(102%) contrast(105%);
            }
        }

        .breadcrumbs-list {
            margin-right: -7%;
        }
    }

    .custom-top-card {
        border: none;

        .card-header {
            border: none;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: rgba(var(--theme-color), 0.04);

            span {
                color: rgba(var(--content-color), 1);
                font-size: 14px;
            }
        }

        .custom-trend-count {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .custom-followers-count {
                font-weight: 500;
                color: rgba(var(--content-color), 1);
            }
        }

        .custom-tag-list {
            .tag-list {
                .tag-item {
                    display: flex;
                    justify-content: space-between;
                    padding: 8px 0;
                    font-size: 1em;
                    color: rgba(var(--content-color), 1);

                    .tag-count {
                        color: rgba(var(--content-color), 1);
                        font-weight: 600;
                    }

                    &:hover {
                        h6 {
                            color: $primary-color;
                        }
                    }

                    .tag-name {
                        flex: 1;
                        margin-left: 8px;
                    }
                }
            }
        }

        .trend-up-percentage {
            font-weight: 600;
            color: $success-color;
        }

        .trend-down-percentage {
            color: $danger-color;
            font-weight: 600;
        }

        .card-footer {
            border-top: 1px dashed rgba(var(--theme-color), 0.3);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: unset !important;
            padding: 15px;

            .comparison {
                font-weight: 600;
                color: $success-color;
            }

            .footer-meta {
                font-weight: 500;
                color: rgba(var(--content-color), 1);
            }

            .footer-meta-mention {
                color: $white;
                background-color: rgba($danger-color, 0.75);
                border-radius: 8px;
                padding: 4px 12px;
            }

            .custom-view-btn {
                border: none;
                padding: 2px 8px;
                border-radius: 6px;
                color: rgba(var(--content-color), 1);
                background-color: $sidebar-background;
            }
        }
    }

    .custom-media-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            font-weight: 700;
        }

        &.custom-overview {
            @media (max-width: 1199px) {
                margin-top: 16px;
            }
        }
    }

    .custom-badge-tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 6px;

        span {
            background-color: rgba($primary-color, 0.85);
            color: $white !important;
            border-radius: 8px;
            padding: 2px 12px;
        }
    }

    .custom-media-card {
        background-color: $white;
        border: none;
        padding: 15px;
        box-shadow: 0px 0px 20px 0px rgba(47, 47, 47, 0.05);

        .custom-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba($light-text , 0.1);

            &:last-child {
                border-bottom: none !important;
            }

            a {
                color: var(--title-color);
                font-size: 18px;
            }

            span {
                font-size: 18px;
                color: rgba(var(--title-color), 0.5);
            }
        }

        .custom-engagement {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                color: rgba(var(--title-color), 0.5);
            }

            p {
                color: rgba(var(--title-color), 0.6);
            }
        }

        li {
            padding: 12px 0px;

            &:first-child {
                border-bottom: 1px solid rgba($light-text , 0.1);
                padding-bottom: 8px;
            }

            &:last-child {
                padding-bottom: 0px;
            }
        }

        .custom-subtitle {
            p {
                font-size: 16px;
                color: $light-text;
            }

            h5 {
                font-weight: 600;
            }

        }

        .gender-icon {
            height: calc(16px + 2 * (100vw - 320px) / 1600);
            width: calc(16px + 2 * (100vw - 320px) / 1600);
            stroke: rgba(var(--title-color), 0.6);
        }

        .user-location {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;

            p {
                color: rgba(var(--title-color), 0.6);
            }

            img {
                height: calc(16px + 2 * (100vw - 320px) / 1600);
                width: calc(16px + 2 * (100vw - 320px) / 1600);
            }
        }

        .media-card {
            background-color: rgba(var(--theme-color), 0.04);
            border: none;
            padding: 15px;
            border-radius: 10px;

            ul {
                li {
                    &:first-child {
                        border-bottom: 1px solid $white;
                        padding: 0px 0px 10px 0px;
                    }

                    padding: 10px 0px 0px 0px;
                }
            }
        }

        .share-button {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div {
                color: rgba(var(--title-color), 1);
                border-radius: 4px;
                display: block;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                transition: all .4s ease-in-out;
            }

            span {
                display: block;
                line-height: 1;
                font-weight: 600;
                font-size: 20px;
                color: rgba(var(--title-color), .67);
                transition: all .4s ease-in-out;
            }

            .number-text {
                display: block;
                line-height: 1;
                font-weight: 500;
                font-size: 18px;
                color: rgba(var(--title-color), .9);
                transition: all 0.4s ease-in-out;
            }

            &:hover {
                span {
                    color: rgba($primary-color, 1);
                }
            }
        }
    }

    .customer-badge {
        display: flex;
        align-items: center;
        gap: 14px;
        flex-wrap: wrap;

        .custom-badge-url {
            padding: 8px 15px !important;
            width: auto !important;
        }
    }

    .custom-trend-card {
        background-color: rgba(var(--theme-color), 0.04);
        border: none;

        .card-header {
            border: none;
            background-color: rgba(var(--theme-color), 0.04);

            .card-title {
                margin-bottom: 0px;
            }
        }

        span {
            color: rgba(var(--title-color), 0.6);
        }

        .custom-trend-media {
            color: rgba(var(--title-color), 0.6);
        }
    }

    .user-box {
        padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
        background-color: rgba(var(--theme-color), 0.04);

        .user-card-slider {
            .swiper-button-prev {
                left: 2%;

                @media (max-width: 991px) {
                    left: 5%;
                }

                @media (max-width: 375px) {
                    left: 6%;
                }
            }

            .swiper-button-next {
                right: 2%;

                @media (max-width: 991px) {
                    right: 5%;
                }

                @media (max-width: 375px) {
                    right: 6%;
                }
            }

            .swiper-button-next,
            .swiper-button-prev {
                border: none;
                border-radius: 100%;
                width: calc(20px + 10 * (100vw - 320px) / 1600);
                height: calc(20px + 10 * (100vw - 320px) / 1600);

                @media (max-width: 1199px) {
                    width: 30px;
                    height: 30px;
                }

                @media (max-width: 575px) {
                    width: 25px;
                    height: 25px;
                }

                &::after {
                    font-size: calc(12px + (15 - 12) * ((100vw - 320px) / (1920 - 320)));
                    color: $black;
                    font-weight: 600;
                    background: rgba(var(--theme-color), 0.04);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 1;
                    border-radius: 100%;
                }
            }
        }

        .top-post {
            border: 1px solid rgba(var(--border-color), 1);
            border-radius: 4px;
            padding: 12px;

            .d-flex {
                gap: 10px;

                .user-img-profile {
                    border-radius: 50%;
                    background-color: rgba($primary-color, 0.85);
                    object-fit: cover;

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }

                p {
                    font-weight: 500;

                    span {
                        display: block;
                        font-size: $paragraph-font-size;
                        line-height: 1;
                        color: rgba(var(--content-color), 1);
                        font-weight: 400;
                    }
                }
            }

            .post-description {
                span {
                    padding: 0px 0px 8px;
                    font-size: 16px;
                    color: $light-text;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: normal;
                }
            }

            .custom-post-info {
                margin-top: 18px;
                margin-bottom: 18px;
                display: flex;
                align-items: center;
                gap: 10px;

                @media (max-width: 991px) {
                    margin-bottom: 0px;
                }

                .post-info-left {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                }

                .media-icon {
                    height: 20px;
                    width: 20px;
                    fill: none;
                    stroke: $black;
                }
            }

            .post-img {
                margin-top: 12px;
                margin-bottom: 18px;

                img {
                    height: 300px;
                    width: 100%;
                    object-fit: cover;
                    object-position: top;
                    border-radius: 4px;
                }
            }
        }
    }

    .user-tab {
        padding: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))) 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320))) 0;
        display: flex;
        gap: calc(15px + (50 - 15) * ((100vw - 320px) / (1920 - 320)));
        justify-content: center;

        li {
            font-size: $heading_5;

            .badge {
                padding: 6px 12px;
                border-radius: 25px;
                background-color: rgba($success-color, 0.1);
                color: rgba($success-color, 1);
                margin: 0 4px;
                font-weight: 400;
                font-size: $heading_5;
            }

            &.active {
                color: $primary-color;
                text-decoration: underline;
                text-decoration-style: dashed;
                text-underline-offset: 10px;
                text-decoration-color: rgba(0, 0, 0, 0.09);
            }
        }
    }

    .performance-tab {
        h4 {
            margin-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
        }

        ul {
            display: flex;
            align-items: center;
            gap: calc(20px + (70 - 20) * ((100vw - 320px) / (1920 - 320)));

            li {
                >.d-flex {
                    gap: 6px;
                    align-items: center;
                    margin-bottom: 4px;

                    img {
                        height: 20px;
                    }

                    h3 {
                        font-weight: 600;
                    }
                }

                p {
                    color: rgba(var(--title-color), 0.8);
                    margin-bottom: 6px;
                }

                .badge {
                    padding: 6px 12px;
                    border-radius: 25px;
                    background-color: rgba($success-color, 0.15);
                    color: rgba($success-color, 1);
                    font-weight: 400;
                    font-size: $heading_5;
                }
            }
        }
    }
}

.change-password {
    min-height: 100vh;

    .card {
        overflow: hidden;
        // border: none;
        border-radius: 10px;
        background-color: #f9f9f9cf;
        border: 1px solid #eee;

        .card-header {
            background-color: transparent;
            color: $theme-font-color;
            padding: 1rem;
            font-weight: 700;
            border-bottom: 1px dashed rgba(var(--theme-color), 0.1);
        }

        .card-body {
            box-shadow: unset;
            background-color: transparent;

            .btn-light-primary {
                background-color: rgba(var(--theme-color), 0.07);
                color: $primary-color;
            }

            .form-label {
                font-weight: 500;
                color: rgba(var(--title-color), 1);
                font-size: 16px;
            }

            .form-control {
                border-radius: 5px;
                border: 1px solid $light-widget-border;
                padding: 8px 47px 8px 15px;
                font-size: 16px;

                &:focus {
                    border-color: $primary-color;
                    box-shadow: 0 0 0 0.2rem rgba(0, 117, 255, 0.25);
                }
            }

            .custom-show-hide {
                position: relative;

                .icon-show-show,
                .icon-show-hide {
                    height: 18px;
                    width: 18px;
                    position: absolute;
                    right: 18px;
                    top: 45px;
                    stroke: $theme-body-sub-title-color;
                    cursor: pointer;
                }

                &.show {
                    display: block;
                }

                &.hide {
                    display: none;
                }
            }

            .btn {
                border-radius: 5px;
                font-weight: 500;

                &.btn-outline-secondary {
                    border: 1px solid $secondary-color;
                    color: $secondary-color;

                    &:hover {
                        background-color: $secondary-color;
                        color: $white;
                    }
                }

                &.btn-warning {
                    background-color: $warning-color;
                    border-color: $warning-color;
                    color: $white;

                    &:hover {
                        background-color: darken($warning-color, 10%);
                        border-color: darken($warning-color, 10%);
                    }
                }
            }

            .profile-btn-group {
                border-top: 1px solid #eee;
                padding-top: 20px;
            }
        }
    }
}

.bg-theme-color {
    background-color: rgba(var(--theme-color), 1);
    color: #fff;
}

.mb-0.mb-3 {
    margin-bottom: 0 !important;
}

.taglNews {
    padding: 0px 4px;
    border-radius: 4px 0 4px 0;
    background-color: #ff4f4f;
    color: #fff !important;
    font-weight: 500;
    font-size: 10px;
    margin: 0 !important;
    transform: translate(3px, -8px);
}