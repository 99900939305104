.btn {
  padding: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320))) calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 4px;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 500;
  text-wrap: nowrap;
  border: 1px solid transparent;
  
  &:active {
    border: 1px solid transparent;
    border-color: transparent !important;
  }
  
  &.disabled{
    pointer-events: all;
    cursor: not-allowed;
  }
}

.b_outline {
  border: 1px solid rgba(var(--border-color), 1);
  color: rgba(var(--title-color), 1);

  &:hover {
    border: 1px solid rgba(var(--theme-color), 0.5);
    color: rgba(var(--theme-color), 1);
  }
}

.btn-theme {
  background-color: rgba(var(--theme-color), 1);
  color: rgba(var(--white), 1);

  &:hover {
    background-color: rgba(var(--theme-color), 1);
    color: rgba(var(--white), 1);
  }

  &.disabled {
    background-color: rgba(var(--theme-color), 0.5);
    color: rgba(var(--white), 1);
  }
}

.btn-outline {
  background-color: rgba(var(--white), 1);
  border: 1px solid rgba(var(--border-color), 1);
  color: rgba(var(--theme-color), 1);

  &:hover {
    background-color: rgba(var(--theme-color), 1);
    color: rgba(var(--white), 1);
  }
}

.btn-danger {
  img {
    filter: invert(1) brightness(100);
  }
}

.btn-light {
  color: rgba(var(--title-color), 1);
  font-weight: 400;

  &:hover {
    background-color: rgba(var(--border-color), 1);
    border: 1px solid transparent;
  }
}

.btn-solid {
  background-color: rgba(var(--theme-color), 0.1);
  color: rgba(var(--theme-color), 1);

  &:hover {
    background-color: rgba(var(--theme-color), 1);
    color: rgba(var(--white), 1);
  }
}

.btn-pill-light {
  background-color: rgba(var(--theme-color), 0.1);
  color: rgba(var(--theme-color), 1);

  &:hover,&:active,&:focus-visible {
    background-color: rgba(var(--theme-color), 0.1) !important;
    color: rgba(var(--theme-color), 1) !important;
  }
}

.ai-badge {
  span {
    background-color: rgba(var(--theme-color), 0.2);
    border-radius: 4px;
    color: rgba(var(--theme-color), 1);
    line-height: 2;
    letter-spacing: 0.5px;
    padding: 5px;
    transform: unset;
    position: relative;
    inset: unset;
    width: max-content;
  }
}
