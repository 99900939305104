.portfolio-modal {
    .modal-dialog {
        max-width: calc(300px + (1610 - 300) * ((100vw - 320px) / (1920 - 320)));
        margin: auto;
    }

    .modal-header {
        padding: 0px;

        .btn-close {
            background: url('../../images/svg/close.svg') center center no-repeat;
            opacity: 1;
            border-radius: 100%;
            background-color: rgba(var(--white), 1);
            box-shadow: 0 0 8px 0 rgba(var(--border-color), 1);
            position: absolute;
            top: calc(8px + (30 - 8) * ((100vw - 320px) / (1920 - 320)));
            right: calc(8px + (30 - 8) * ((100vw - 320px) / (1920 - 320)));
            z-index: 1;
        }
    }

    .modal-body {
        height: calc(300px + (600 - 300) * ((100vw - 320px) / (1920 - 320)));
        padding: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320))) calc(16px + (100 - 16) * ((100vw - 320px) / (1920 - 320))) calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));

        .p-img {
            aspect-ratio: 69 / 50;
            object-fit: cover;
            transition: all 0.5s ease-in-out;
            width: 100%;
            border-radius: 10px;
        }
    }
}

.modal {
    .modal-dialog {
        &.confirmation-modal {
            width: 100%;
            max-width: 580px;

            .modal-content {
                padding: 40px;

                .modal-header {
                    border: none;
                    padding: 0px;

                    .btn-close {
                        position: absolute;
                        top: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
                        right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
                        border: 1px solid rgba(var(--black), 0.2);
                        border-radius: 100%;
                        font-size: 10px;
                        font-weight: 700;
                    }
                }

                .modal-body {
                    padding: 0;
                    text-align: center;

                    h3 {
                        font-size: 30px;
                        font-weight: 600;
                        line-height: 1.2;
                        margin-bottom: 6px;
                    }

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 1.2;
                        color: rgba(var(--gray), 1);
                        margin-bottom: 32px;
                    }

                    .footer {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-inline: 20px;
                        gap: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));

                        .btn {
                            width: 100%;
                            padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320)));
                            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                            border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
                            line-height: 1;
                            text-align: center;

                            &.btn-gray {
                                border: 1px solid rgba(var(--border-color), 1);
                                color: rgba(var(--title-color), 1);
                            }
                        }
                    }
                }
            }
        }

        .modal-header {
            padding: 5px;

            .btn-close {
                background-image: url(../../images/svg/close-lg.svg);
                position: absolute;
                right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
                top: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
                opacity: 1;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        &.creator-addlist {
            .modal-content {
                padding: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));

                .modal-body {
                    padding: 0;

                    .list-wrap {
                        display: flex;
                        align-items: flex-start;
                        gap: calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
                        padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));

                        .list-icon {
                            width: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
                            height: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
                            @include display;
                            padding: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
                            background-color: rgba(var(--theme-color), 0.1);
                            border-radius: 4px;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .delete-icon {
                            width: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
                            height: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
                            @include display;
                            padding: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
                            background-color: rgba(var(--error), 0.2);
                            border-radius: 4px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .list-detail {
                            width: calc(100% - 80px);

                            h5 {
                                font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
                                padding-bottom: 3px;
                                color: rgba(var(--title-color), 1);
                            }

                            p {
                                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                                color: rgba(var(--content-color), 1);
                            }
                        }

                        @media (max-width: 425px) {
                            align-items: flex-start;
                        }
                    }

                    .form-floating {
                        label {
                            font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
                            color: rgba(var(--content-color), 1);
                        }
                    }

                    &.addlist-wrap {
                        .create-list-wrapper {
                            max-height: calc(160px + (255 - 160) * ((100vw - 320px) / (1920 - 320)));
                            overflow-y: auto;

                            .user-row {
                                padding-block: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
                            }
                        }
                    }
                }

                .error-note {
                    display: flex;
                    gap: 7px;
                    align-items: flex-start;
                    padding-top: 10px;
                    font-weight: 500;
                    color: rgba(var(--error), 1);

                    img {
                        filter: contrast(4);
                    }
                }
            }
        }

        &.contact-addlist {
            @media (min-width: 630px) {
                max-width: 600px;
            }

            .modal-content {
                padding: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
            }

            .modal-body {
                padding: 0;

                .modal-title {
                    font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
                    color: rgba(var(--title-color), 1);
                }

                .contact-option-wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    height: calc(200px + (460 - 200) * ((100vw - 320px) / (1920 - 320)));
                    overflow-y: auto;

                    .contact-box {
                        display: flex;
                        align-items: center;
                        gap: calc(8px + (16 - 8) * ((100vw - 320px) / (1920 - 320)));
                        padding: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
                        border-bottom: 1px solid rgba(var(--border-color), 1);
                        width: 100%;

                        &.active {
                            background-color: rgba(var(--theme-color), 0.08);
                        }

                        .check-box {
                            &:checked {
                                ~label {
                                    span {
                                        background-color: rgba(var(--white), 1);
                                    }
                                }

                                ~.contact-box {
                                    background-color: red;
                                }
                            }
                        }

                        label {
                            display: flex;
                            align-items: center;
                            gap: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
                            width: calc(100% - 36px);
                            color: rgba(var(--title-color), 1);
                            font-weight: 500;
                            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                            text-transform: capitalize;

                            &.note-label {
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 0;

                                p {
                                    color: rgba(var(--content-color), 1);
                                    font-weight: 400;
                                    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
                                }
                            }

                            span {
                                padding: calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320)));
                                width: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
                                height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
                                border-radius: 100%;
                                @include display;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}