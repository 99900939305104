.breadcrumb-box {
    background: url('../../images/breadcrumb.jpg') center center no-repeat;
    background-size: cover;
    border-radius: calc(6px + (10 - 6) * ((100vw - 320px) / (1920 - 320)));
    padding: calc(21px + (39 - 21) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    display: grid;
    gap: 8px;
    p {
        color: rgba(var(--content-color), 1);
        width: 70%;
        margin-inline: auto;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));

        @media (max-width: 1199.98px){
            width: 90%;
        }

        @media (max-width: 767.98px){
            width: 100%;
        }
    }
    .breadcrumb {
        justify-content: center;
        margin-bottom: 0;
        align-items: center;

        .breadcrumb-item {
            +.breadcrumb-item {
                &::before {
                    color: rgba(var(--content-color), 1);
                }
            }

            a {
                font-size: 16px;
                @include display;
                gap: 5px;
                color: rgba(var(--content-color), 1);
                font-weight: 500;

                img {
                    width: 16px;
                    height: 16px;
                    filter: brightness(0) contrast(0);
                }
            }

            &.active {
                font-weight: 500;
                font-size: 16px;
                color: rgba(var(--theme-color), 1);
            }
        }
    }
}

.breadcrumbs-list {
    // background-color: red;
    margin-left: auto;
    display: flex;
    align-items: center;

    @media (max-width: 575.98px) {
        width: calc(100% - 40px - 10px);
    }

    li {
        position: relative;
        color: rgba(var(--title-color), 1);
        font-weight: 500;
        width: 100%;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        // -webkit-box-orient:vertical;
        +li {
            padding-left: 4px;
            margin-left: 4px;

            &::before {
                margin-right: 6px;
                content: "/";
                font-size: 15px;
                color: #222;
            }
        }

        &:last-child {
            color: rgba(var(--title-color), 0.6);
        }
    }
}