body {
    font-family: $font-roboto;
    background-color: rgba(var(--white), 1);
    font-size: 14px;
    margin: 0;
    transition: all 0.5s ease-in-out;
    
}

p {
    margin-bottom: 0%;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

li {
    display: inline-block;
}

a {
    text-decoration: none;
    cursor: pointer;
}

h1 {
    font-size: calc(36px + (50 - 36) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    margin-bottom: 0;
}

h2 {
    font-size: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    margin-bottom: 0;
}

h3 {
    font-size: calc(20px + (26 - 20) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    margin-bottom: 0;
}

h4 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    margin-bottom: 0;
}

h5 {
    font-weight: 500;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0;
}

h6 {
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0;
    font-weight: 500;
}

p,
a {
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    line-height: 1.5;
}