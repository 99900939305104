
/**=====================
   Sidebar CSS Start
==========================**/
%sidebar-after {
    position: absolute;
    content: "";
    top: 58%;
    width: 8px;
    border-top: 2px solid $white;
  }
  // default dashboard
  .d-flex {
    &.media-wrapper {
      width: 100%;
      display: -webkit-flex;
      .flex-grow-1 {
        flex: unset;
      }
    }
  }
  .heart {
    text-align: end;
  }
  %sidebar-before {
    position: absolute;
    content: '';
    width: 1px;
    height: 0;
    background: rgba($theme-body-sub-title-color, 0.4);
    top: -10px;
    left: 27px;
    transition: height 0.5s;
    [dir="rtl"] & {
      left: unset;
      right: 27px;
    }
  }
  %horizontal-after {
    position: absolute;
    content: "";
    top: 49%;
    width: 5px;
    height: 1px;
    border-radius: 100%;
    background-color: #59667a;
  }
  %for-animated-hover {
    box-shadow: $main-header-shadow;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    left: 0;
  }
  %for-animated-hover-box {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
    border-radius: 5px;
    &:before {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $white;
      content: "";
      top: -7px;
      position: absolute;
      right: 10px;
      z-index: 2;
      [dir="rtl"] & {
        left: 10px;
        right: unset;
      }
    }
  }
  .page-wrapper {
    position: relative;
    .page-header {
      max-width: 100vw;
      position: $main-header-position;
      top: $main-header-top;
      z-index: $main-header-z-index;
      transition: $sidebar-transition; 
      background-color: $sidebar-background; 
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
      .header-wrapper { 
        width: 100%;
        align-items: center;
        padding: $header-wrapper-padding;
        position: relative;
        @media (max-width: 1550px) and (min-width: 1200px){
          padding: 12px 20px;
        }
        .left-header {
          .left-menu-header {
            .app-menu {
              display: none; 
            }
          }
        }
        .nav-right {
          justify-content: flex-end;
          ul {
            li {
              padding: 9px 14px;
              display: inline-block;
              position: relative;
              border-radius: 5px;
              cursor: pointer;
              svg {
                vertical-align: middle;
                width: 24px;
                height: 24px;
                stroke: none;
                fill: $theme-body-sub-title-color;
              }
              &.serchinput {
                display: none !important;
              .serchbox {
                position: relative;
              }
              .search-form {
                top: 52px;
                position: absolute;
                transition: all linear 0.3s;
                right: -50px;
                transform: translateY(-35px) scaleY(0);
                opacity: 0;
                visibility: hidden;
                margin-left: 12px;
                @media (max-width: 480px) {
                  right: -60px;
                  [dir="rtl"] & {
                    right: -74px;
                  }
                }
                &.open {
                  transform: translateY(0px) scaleY(1);
                  opacity: 1;
                  visibility: visible;
                  transition: all linear 0.3s;
                }
                input {
                  padding: 10px 15px;
                  background-color: $light;
                  width: 180px;
                }
              }
            }
              svg {
                vertical-align: $header-wrapper-nav-icon-align;
                width: $header-wrapper-nav-icon-size;
                height: $header-wrapper-nav-icon-size;
                stroke: none;
                fill: $dark-gray;
                path {
                  color: $header-wrapper-nav-icon-color;
                }
              }
              .mode {
                &.active {
                  svg {
                    rotate: 180deg;
                  }
                }
              }
            }
          }
          &.right-header {
            ul {
              li {
                .mode {
                  cursor: pointer;
                  text-align: center;
                  i {
                    font-size: 18px;
                    font-weight: 600;
                    transition: all 0.3s ease;
                    &.fa-lightbulb-o {
                      font-weight: 500;
                      font-size: 22px;
                      transition: all 0.3s ease;
                      animation: zoomIn 300ms ease-in-out;
                    }
                  }
                }
                .header-search {
                  cursor: pointer;
                }
                &.profile-nav {
                  padding-block: 0;
                  .profile-media {
                    .flex-grow-1 {
                      margin-left: 10px;
                      span {
                        font-weight: 500;
                      }
                      p {
                        font-size: 12px;
                        line-height: 1;
                        color: rgba($theme-body-font-color, 0.7);
                      }
                    }
                  }
                }
                &:nth-child(6) {
                  @media (max-width: 630px){
                    padding-right: unset;
                    [dir="rtl"] & {
                      padding-left: unset;
                      padding-right: 6px;
                    }
                  }
                }
              }
            }
          }
          >ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            >li {
              padding: 9px 14px;
              cursor: pointer;
              @media (max-width: 630px){
                padding: 3px 6px;
              }
              &:last-child {
                border: none !important;
                [dir="rtl"] & {
                  margin-right: unset;
                }
                @media (max-width: 630px){
                  display: none;
                }
              }
              .badge {
                position: absolute;
                right: -5px;
                top: -6px;
                padding: 2px 4px;
                font-size: 11px;
                font-weight: 700;
                font-family: work-Sans, sans-serif;
              }
              span {
                ul {
                  left: inherit;
                  right: -10px;
                  width: 130px;
                  &:before,
                  &:after {
                    left: inherit;
                    right: 10px;
                  }
                  li {
                    display: block;
                    a {
                      font-size: 14px;
                      color: $dark-color;
                      i {
                        margin-left: 10px;
                        font-size: 13px;
                        color: $dark-color;
                      }
                    }
                  }
                }
              }
            }
            .flag-icon {
              font-size: 16px;
            }
          }
          .bookmark-flip {
            width: unset !important;
            box-shadow: unset !important;
            background-color: transparent !important;
            overflow: initial;
            &::after,
            &::before {
              display: none;
            }
            .bookmark-dropdown {
              .bookmark-content {
                text-align: center;
                .bookmark-icon {
                  width: 52px;
                  height: 52px;
                  background-color: #f9f9f9;
                  border-radius: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 auto;
                }
                span {
                  font-family: $font-roboto, $font-serif;
                  font-size: 12px;
                  font-weight: 500;
                  letter-spacing: 0.5px;
                }
              }
            }
          }
          .flip-card {
            width: 300px;
            height: 260px;
            background-color: transparent;
            .flip-card-inner {
              position: relative;
              height: 100%;
              text-align: center;
              transition: transform 0.6s;
              transform-style: preserve-3d;
              .front,
              .back {
                position: absolute;
                backface-visibility: hidden;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
                width: 300px;
                button {
                  background-color: $transparent-color;
                  color: var(--theme-color);
                  border: none;
                  &:focus {
                    outline: $transparent-color;
                  }
                }
              }
              .front {
                background-color: $white;
                transition: .3s;
                svg {
                  stroke: $dark-color;
                  fill: none;
                  transition: all 0.3s ease;
                  &:hover {
                    stroke: var(--theme-color);
                    transition: all 0.3s ease;
                  }
                }
              }
              .back {
                background-color: $white;
                transform: rotateY(180deg);
                li {
                  &:first-child {
                    margin-top: 20px;
                    height: 140px !important;
                  }
                }
                button {
                  width: 100%;
                }
                .flip-back-content {
                  input {
                    border-radius: 25px;
                    padding: 6px 12px;
                    width: 100%;
                    border: 1px solid #ddd;
                    &:focus {
                      outline: none;
                    }
                  }
                }
              }
              &.flipped {
                transform: rotateY(180deg);
                .front {
                  opacity: 0;
                }
              }
            }
          }
          .onhover-show-div {
            width: 330px;
            top: 50px;
            right: 0;
            left: unset;
            .dropdown-title {
              padding: 20px;
              text-align: center;
              border-bottom: 1px solid rgba($dropdown-border-color, 0.3);
            }
            ul {
              padding: 15px;
              li {
                .d-flex {
                  position: relative;
                  .message-img {
                    padding: 5px;
                    border-radius: 50%;
                    img {
                      position: relative;
                      border-radius: 50%;
                      width: 40px;
                      height: auto;
                    }
                  }
                  .flex-grow-1 {
                    margin-left: 15px;
                    h5 {
                      a {
                        color: $theme-font-color;
                        font-weight: 500;
                        [class="dark-only"] & {
                          color: $dark-all-font-color;
                        }
                      }
                    }
                    p {
                      margin-bottom: 0;
                      opacity: 0.6;
                      line-height: 1.4;
                    }
                  }
                  .notification-right {
                    svg {
                      vertical-align: middle;
                      width: 18px;
                      margin-top: -10px;
                      stroke: $theme-body-sub-title-color;
                    }
                  }
                }
              }
            }
            &.message-dropdown {
              li {
                &:last-child {
                  padding-bottom: 0;
                  text-align: center;
                }
              }
            }
            li {
              padding: 15px;
              width: 100%;
              + li {
                border-top: 1px solid rgba($dropdown-border-color, 0.3);
                border-radius: 0;
              }
              p {
                font-size: 14px;
                letter-spacing: 0.3px;
                margin-bottom: 0;
              }
              a {
                letter-spacing: 0.3px;
              }
              &:last-child {
                padding-bottom: 0;
              }
              .d-flex {
                position: relative;
                img {
                  width: 40px;
                  position: relative;
                }
                .status-circle {
                  left: 0;
                }
                .flex-grow-1 {
                  >span {
                    letter-spacing: 0.8px;
                    display: inline-block;
                  }
                  p {
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }
          .notification-box {
            position: relative;
          }
          .cart-box {
            position: relative;
          }
          .cart-dropdown {
            width: 270px;
            li {
              width: 240px;
              padding: 10px 0;
              .d-flex {
                align-items: center;
                .flex-grow-1 {
                  margin-right: 20px;
                  span {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    color: $theme-font-color;
                  }
                  h6 {
                    color: $theme-body-sub-title-color;
                    margin-bottom: 5px;
                  }
                }
              }
              &+li {
                margin-top: 0;
                border-top: 1px solid rgba($dropdown-border-color, 0.1);
              }
              &:last-child {
                padding: 10px 0;
                border-top: none;
              }
              .view-cart ,.view-checkout{
                font-size: 16px;
                font-weight: 500;
                color: $theme-font-color;
                transition: all 0.4s;
                &:hover {
                  color: var(--theme-color);
                  transition: all 0.4s;
                }
              }
            }
            p {
              line-height: 1.3;
              margin-top: 3px;
              opacity: 0.6;
            }
            .close-circle {
              position: absolute;
              right: 0;
              top: 0;
              a {
                i {
                  color: $theme-body-sub-title-color;
                }
              }
              svg {
                width: 14px;
                stroke: $white;
              }
            }
            .total {
              border-bottom: 1px solid rgba($dropdown-border-color, 0.1);
              h5 {
                font-weight: 500;
                color: $theme-body-sub-title-color;
                span {
                  font-size: 14px;
                }
              }
            }
            .view-checkout {
              text-decoration: unset;
            }
          }
          .notification-box {
            svg {
              animation: swing 1.5s ease infinite;
            }
          }
          .notification-dropdown {
            top: 52px;
            width: 300px;
            right: -20px !important;
            left: unset;
            @media (max-width: 575.98px) {
              width: calc(100vw - 30px);
            }
            li {
              background-color: #f9f9f9;
              border-radius: 0px;
              border-top: none !important;
              width: 100%;
              &:last-child {
                text-align: center;
                background-color: transparent;
              }
              &.b-l-primary {
                [dir="rtl"] & {
                  border-left: unset !important;
                  border-right: 4px solid var(--theme-color) !important;
                }
              }
              &.b-l-secondary {
                [dir="rtl"] & {
                  border-left: unset !important;
                  border-right: 4px solid var(--theme-secondary) !important;
                }
              }
              &.b-l-success {
                [dir="rtl"] & {
                  border-left: unset !important;
                  border-right: 4px solid $success-color !important;
                }
              }
              &.b-l-info {
                [dir="rtl"] & {
                  border-left: unset !important;
                  border-right: 4px solid $info-color !important;
                }
              }
              p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                  font-size: 11px;
                  vertical-align: middle;
                }
              }
            }
          }
          .profile-dropdown {
            width: 160px;
            top: 57px;
            left: -12px;
            padding: 0 10px;
            li {
              padding: 10px !important;
              a {
                text-decoration: unset;
              }
              &:hover {
                a {
                  span {
                    color: var(--theme-color);
                    transition: color 0.3s ease;
                  }
                  svg {
                    stroke: var(--theme-color);
                    transition: stroke 0.3s ease;
                    path {
                      stroke: var(--theme-color);
                      transition: stroke 0.3s ease;
                    }
                  }
                }
              }
              +li {
                margin-top: 0;
              }
              &:last-child {
                padding-top: 10px !important;
              }
              span {
                color: $dark-color;
              }
              svg {
                width: 16px;
                vertical-align: bottom;
                margin-right: 10px;
                stroke: $dark-color;
                fill: none;
              }
            }
          }
        }
      }
    }
    .page-body-wrapper {
      background-color: $main-body-color;
      .sidebar-wrapper {
        .logo-wrapper {
            .logo {
                height: calc(26px + 14 * (100vw - 320px) / 1600); 
            }
        }
      }
      .page-body {
        min-height: calc(100vh - #{$header-size});
        margin-top: $header-size;
        padding: $page-body-padding;
        position: relative;
        transition: 0.5s;
        &:before {
          animation: fadeIn 0.5s ease-in-out;
        }
      }
      .page-title {
        padding: $page-title-padding;
        margin: 0 -27px 0;
        >.row {
          margin: 0;
          .col-6 {
            padding: 0;
          }
        }
        .row {
          align-items: center;
          h3 {
            text-transform: $page-title-text-tranform;
            color: $theme-font-color;
            font-weight: 600;
            small {
              display: $page-small-title-display;
              font-size: $page-small-title-font-size;
              margin-bottom: $page-small-title-margin-bottom;
              text-transform: $page-small-title-text-transform;
              color: $theme-body-sub-title-color;
            }
          }
        }
        .breadcrumb {
          background-color: $transparent-color;
          padding: 0;
          margin-bottom: 0;
          align-items: center;
          justify-content: flex-end;
          li {
            &:first-child {
              position: relative;
              display: flex;
              height: 30px;
              width: 30px;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              background-color: rgba($primary-color, 0.1);
            }
          }
          .breadcrumb-item {
            color: var(--theme-color);
            font-weight: 500;
            &+.breadcrumb-item {
              &:before {
                content: $breadcrumb-content;
                color: var(--theme-color);
                font-weight: 500;
              }
            }
            a {
              svg {
                width: 16px;
                height: 16px;
                stroke: var(--theme-color);
                vertical-align: middle;
              }
            }
            &.active {
              font-weight: 300;
            }
          }
        }
      }
      div.sidebar-wrapper {
        .sidebar-main {
          .sidebar-links {
            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    >li {
                      a {
                        svg {
                          &.stroke-icon {
                            stroke: $white;
                          }
                          &.fill-icon {
                            fill: $white;
                          }
                        }
                        &.active {
                          svg {
                            transition: all 0.3s ease;
                            position: relative;
                            content: '';
                            width: 35px;
                            height: 35px;
                            background-color: var(--theme-color);
                            padding: 8px;
                            top: 0;
                            -webkit-transition: 0.8s;
                            transition: 0.8s;
                            border-radius: 5px;
                            box-shadow: 1.5px 0.33px 16px 0px rgba($primary-color,0.6);
                          }
                          span {
                            color: var(--theme-color);
                          }
                        }
                      }
                    }
                    li {
                      a {
                        text-transform: capitalize;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .sidebar-wrapper {
      &[data-layout="stroke-svg"] {
        .stroke-icon {
          display: inline-block;
        }
        .fill-icon {
          display: none;
        }
      }
      &[data-layout="fill-svg"] {
        .stroke-icon {
          display: none;
        }
        .fill-icon {
          display: inline-block;
        }
      }
    }
    &.horizontal-wrapper {
      [data-simplebar] {
        position: relative;
      }
      .footer {
        margin-left: 0;
      }
      .header-logo-wrapper {
        width: 150px;
        .toggle-sidebar {
          display: none;
        }
      }
      .sidebar-list {
        i.fa-thumb-tack {
          visibility: hidden;
          position: absolute;
          top: 19px;
          right: 0px;
          color: var(--theme-color);
          [dir="rtl"] & {
            left: 0;
            right: unset;
          }
        }
        &:hover {
          i.fa-thumb-tack {
            visibility: hidden;
            cursor: pointer;
          }
        }
      }
      .page-body-wrapper {
        .sidebar-wrapper {
          height: 54px;
          line-height: inherit;
          top: 74px;
          background: $white;
          box-shadow: 0px 9px 24px rgba($semi-dark, 0.05);
          overflow-x: hidden;
          z-index: 1;
          overflow-y: visible;
          position: fixed;
          width: 100vw;
          border-top: 1px solid $light-semi-gray;
          z-index: 3;
          .simplebar-content {
            display: flex;
          }
          li {
            display: inline-block;
          }
          .badge {
            position: absolute;
            top: 2px;
            right: 0px;
            z-index: 1;
          }
          .logo-wrapper,
          .logo-icon-wrapper {
            display: none;
          }
          .sidebar-main {
            text-align: left;
            position: relative;
            .left-arrow,
            .right-arrow {
              position: absolute;
              top: 0px;
              padding: 15px;
              background-color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
              cursor: pointer;
              &.disabled {
                display: none;
              }
            }
            .left-arrow {
              left: 0;
            }
            .right-arrow {
              right: 0;
            }
            .sidebar-links {
              list-style: none;
              margin: 0;
              padding: 0;
              line-height: normal;
              direction: ltr;
              text-align: left;
              display: -webkit-box !important;
              padding: 0 5px;
              transition: all .5s ease;
              white-space: nowrap;
              li {
                &.sidebar-list {
                  a {
                    &.active {
                      .according-menu {
                        i {
                          &:before {
                            content: '-';
                          }
                        }
                      }
                    }
                    .according-menu {
                      display: none;
                    }
                  }
                  ul.sidebar-submenu {
                    li {
                      a {
                        &.active {
                          color: var(--theme-color);
                          &:after {
                            background-color: var(--theme-color);
                          }
                        }
                      }
                    }
                  }
                }
              }
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        position: relative;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        &.sidebar-main-title {
                          &.pin-title {
                            display: none !important;
                            &.show {
                              display: none !important;
                            }
                          }
                        }
                        a {
                          svg {
                            &.stroke-icon {
                              stroke: $theme-body-sub-title-color;
                            }
                            &.fill-icon {
                              fill: $theme-body-sub-title-color;
                            }
                          }
                        }
                        &:first-child {
                          display: none !important;
                        }
                        >a {
                          display: block;
                          color: $theme-body-font-color;
                          line-height: 23px;
                          text-decoration: none;
                          text-transform: capitalize;
                          padding: 5px 15px;
                          margin: 0 3px;
                          letter-spacing: 0.04em;
                          position: relative;
                          border-radius: 5px;
                          font-weight: 500;
                          transition: all 0.3s ease;
                          &:hover {
                            background-color: rgba($primary-color, 0.2);
                            transition: all 0.3s ease;
                          }
                          &.active {
                            background-color: lighten($primary-color, 22%);
                            transition: all 0.3s ease;
                            svg,
                            span {
                              color: var(--theme-color);
                              transition: all 0.3s ease;
                            }
                          }
                          .badge {
                            position: absolute;
                            top: -5px;
                            margin-left: 1px;
                            right: 10px;
                            padding: 0.20em 0.7em;
                          }
                          svg {
                            width: 18px;
                            height: 18px;
                            vertical-align: sub;
                            margin-right: 10px;
                          }
                        }
                        .sidebar-submenu {
                          position: fixed;
                          z-index: 4;
                          box-shadow: 0 0 1px 0 $dark-gray;
                          padding: 15px 0px 15px 0px;
                          background: white;
                          li {
                            border: 0;
                            float: none;
                            position: relative;
                            display: block;
                            a {
                              background: transparent;
                              padding: 4px 35px;
                              display: block;
                              transition: all 0.5s ease;
                              line-height: 1.9;
                              color: $theme-body-font-color;
                              letter-spacing: 0.06em;
                              font-weight: 500;
                              transition: all 0.3s ease;
                              &:after {
                                left: 20px;
                                @extend %horizontal-after;
                              }
                              &:hover {
                                color: var(--theme-color);
                                letter-spacing: 1.5px;
                                transition: all 0.3s ease;
                                &:after {
                                  background-color: var(--theme-color);
                                }
                              }
                            }
                          }
                        }
                        &:hover {
                          >a {
                            &:after {
                              left: 0;
                              right: 0;
                              opacity: 1;
                            }
                          }
                        }
                        &.mega-menu {
                          position: unset;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &.overlay-white {
            background-color: rgba(255, 255, 255, 0.08);
          }
        }
        .toggle-nav {
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
          i {
            font-size: 24px;
          }
        }
        .page-body {
          margin-top: 135px;
        }
        .sidebar-main-title {
          display: none !important;
        }
        &.scorlled {
          .sidebar-wrapper {
            display: none;
            transition: all 0.3s ease;
          }
        }
      }
      .page-header {
        width: 100%;
        box-shadow: none;
        .header-wrapper {
          .form-control-plaintext {
            width: auto !important;
          }
          .left-header {
            width: 30%;
            @media (max-width: 1428px) and (min-width: 1324px) {
              width: 32%;
            }
            @media (max-width: 1323px) and (min-width: 1200px) {
              width: 37%;
            }
            @media (max-width: 1199px) and (min-width: 992px) {
              width: 32%;
            }
          }
          .nav-right {
            @media (max-width: 1428px) and (min-width: 1324px) {
              width: 55%;
            }
            @media (max-width: 1323px) and (min-width: 1200px) {
              width: 40%;
            }
            @media (max-width: 1199px) and (min-width: 992px) {
              width: 50%;
            }
          }
        }
      }
      &.enterprice-type {
        footer {
          margin-left: 0;
        }
      }
    }
    &.material-icon {
      .card {
        border-radius: 30px;
        box-shadow: 0 0 20px rgba($primary-color, 0.10);
      }
    }
    .sidebar-main-title {
      h6 {
        font-size: 14px;
        font-weight: 600;
        color: var(--theme-color);
        text-transform: uppercase;
        margin-bottom: 0;
        letter-spacing: 0.40px;
      }
    }
    // left sidebar css
    &.compact-wrapper {
      .sidebar-list {
        i.fa-thumb-tack {
          visibility: hidden;
          position: absolute;
          top: 11px;
          right: -9px;
          color: var(--theme-color);
          z-index: 9999;
          [dir="rtl"] & {
            left: -9px;
            right: unset;
          }
        }
        &:hover {
          i.fa-thumb-tack {
            visibility: visible;
            cursor: pointer;
          }
        }
        &.pined {
          order: -1;
        }
      }
      .pin-title {
        order: -1;
      }
      .page-header {
        margin-left: $sidebar-compact-width;
        width: calc(100% - $sidebar-compact-width);
        @media (max-width: 991.98px) { 
          margin-left: 0 !important;
          width: calc(100% - 0px) !important; 
        }
        &.close_icon {
          margin-left: 71px;
          width: calc(100% - 71px);
        }
        .header-wrapper {
          .logo-wrapper {
            display: none;
          }
          .toggle-sidebar {
            display: none;
            @media (max-width: 991.98px) { 
              display: block !important;
              width: 40px;
              height: 40px;
              background-color: $primary-color;
              position: unset;
              border-radius: 10px;
              margin-right: 10px;
              &::before {
                display: none;
              }
              svg {
                width: 20px;
                height: 20px;
                transform: translate(50%, -50%);
                position: absolute;
                top: 50%;
                fill: none;
                stroke-width: 2px;
                stroke: #fff;
              }
            }
          }
        }
      }
      .page-body-wrapper {
        div {
          &.sidebar-wrapper {
            position: fixed;
            top: 0;
            z-index: 9;
            height: auto;
            line-height: inherit;
            background: $sidebar-background;
            width: $sidebar-compact-width;
            text-align: left;
            transition: 0.3s;
            box-shadow: 0 0 21px 0 rgba($semi-dark, 0.1);
            .logo-wrapper,
            .logo-icon-wrapper {
              padding: 14px 18px;
              background-color: $header-light-color;
            }
            svg {
              stroke: $theme-font-color; 
            } 
            .logo-icon-wrapper {
              display: none;
            }
            &.close_icon {
              width: 71px;
              transition: all 0.3s ease;
              @media (max-width: 991.98px) { 
                transform: translate(-285px);
              }
              .badge {
                opacity: 0;
                width: 0;
                padding: 0;
              }
              .sidebar-main {
                .sidebar-links {
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          >li {
                            max-width: 86px;
                            margin: 0;
                            transition: all 0.3s ease;
                            .sidebar-submenu {
                              a {
                                display: none;
                              }
                            }
                            a {
                              text-align: center;
                              padding: 12px 14px;
                              transition: all 0.3s ease;
                            }
                          }
                        }
                      }
                    }
                  }
                  .sidebar-list{
                    .sidebar-link {
                      span {
                        display: none !important;
                      }
                    }
                  }
                }
              }
              .sidebar-main-title {
                display: none !important;
                opacity: 0;
                transition: all 0.3s ease;
              }
              .logo-wrapper {
                display: none;
              }
              .logo-icon-wrapper {
                text-align: right;
                display: block;
                padding: 15px 18px; 
              }
              &:hover {
                width: $sidebar-compact-width;
                transition: all 0.3s ease;
                
                .badge {
                  opacity: 1;
                  width: auto;
                  transition: all 0.3s ease;
                }
                .sidebar-main {
                  .sidebar-links {
                    .sidebar-list {
                      .sidebar-link {
                        span {
                          display: block !important;
                        }
                      }
                    }
                    .simplebar-wrapper {
                      .simplebar-mask {
                        .simplebar-content-wrapper {
                          .simplebar-content {
                            >li {
                              max-width: unset;
                              margin: initial;
                              transition: all 0.3s ease;
                              a {
                                text-align: left;
                                [dir="rtl"] & {
                                  text-align: right;
                                }
                              }
                              .sidebar-submenu {
                                a {
                                  display: block;
                                }
                              }
                              >a {
                                padding: 12px 15px;
                                transition: all 0.3s ease;
                                span {
                                  display: inline-block;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .sidebar-main-title {
                  opacity: 1;
                  display: block !important;
                  transition: all 0.3s ease;
                }
                .logo-wrapper {
                  display: block;
                }
                .logo-icon-wrapper {
                  display: none;
                }
              }
              .mobile-sidebar {
                transform: translate(183px);
              }
              ~.page-body,
              ~footer {
                margin-left: 71px;
                transition: 0.5s;
                @media (max-width: 991.98px) {
                  margin-left: 0;
                  transition: 0.5s;
                }
              }
              ~.footer-fix {
                width: calc(100% - 90px);
              }
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 100px);
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          >li {
                            .sidebar-link {
                              &::before {
                                right: 20px;
                                left: unset;
                              }
                            }
                            a {
                              span {
                                display: none; 
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .sidebar-list {
                    .sidebar-title {
                      &.active {
                        ~.sidebar-submenu {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
              .according-menu {
                display: none;
              }
            }
            .sidebar-main {
              .left-arrow,
              .right-arrow {
                display: none;
              }
              .sidebar-list {
                i.fa-thumb-tack {
                  visibility: hidden;
                  position: absolute;
                  top: 24px;
                  right: 47px;
                  color: var(--theme-color);
                  [dir="rtl"] & {
                    left: 47px;
                    right: unset;
                  }
                }
                &:hover {
                  i.fa-thumb-tack {
                    visibility: visible;
                    cursor: pointer;
                  }
                }
                &.pined,
                &.pin-title {
                  order: -1;
                }
              }
              .pin-title {
                order: -1;
              }
              .sidebar-links {
                height: $sidebar-height;
                left: -300px;
                z-index: 99;
                transition: color 1s ease;
                overflow: auto; 
                color: rgba(0, 0, 0, 0);
                margin-bottom: 50px;
                li {
                  display: block;
                  &.active {
                    svg {
                      transition: all 0.3s ease;
                      position: relative;
                      content: '';
                      width: 35px;
                      height: 35px;
                      background-color: $primary-color;
                      padding: 8px;
                      stroke: $white;
                      top: 0; 
                      -webkit-transition: 0.8s;
                      transition: 0.8s;
                      border-radius: 5px;
                      box-shadow: 1.5px 0.33px 16px 0px rgba($primary-color,0.6);
                    }
                    span {
                      color: $primary-color;
                    }
                  }
                  a {
                    font-weight: 500;
                    padding: 12px 15px 5px 15px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $theme-font-color;
                    svg {
                      width: 35px;
                      height: 35px;
                      padding: 8px;
                      stroke: $theme-font-color; 
                      vertical-align: -2px;
                      float: none;
                      transition: all 0.3s ease;
                    }
                    span {
                      letter-spacing: 0.5px;
                      text-transform: capitalize;
                      font-weight: 500;
                      color: $theme-font-color;
                      width: 168px;
                      height: 35px;
                      padding: 8px;
                    }
                    &.link-nav {
                      .according-menu {
                        display: none;
                      }
                    }
                  }
                  &:last-child {
                    border-bottom: none;
                  }
                }
                &.opennav {
                  left: 0;
                }
                .back-btn {
                  display: block;
                  width: 100%;
                  padding: 0;
                  .mobile-back {
                    padding: 20px;
                    color: $theme-font-color;
                    font-weight: 700;
                    text-transform: uppercase;
                    border-bottom: 1px solid #efefef;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                  }
                }
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        >li {
                          display: block;
                          width: 100%;
                          position: relative;
                          &.sidebar-main-title {
                            padding: 25px 0 10px 20px;
                            &.pin-title {
                              display: none !important;
                              &.show {
                                display: block !important;
                              }
                            }
                            [dir="rtl"] & {
                              padding: 25px 20px 10px 0;
                            }
                            &::before {
                              position: absolute;
                              content: '';
                              bottom: 0;
                              left: 50%;
                              background: $header-light-color;
                              width: calc(100% - 40px);
                              height: 1px;
                              transform: translateX(-50%);
                            }
                            &:nth-child(2) {
                              margin-top: 0;
                              &::before {
                                display: none;
                              }
                            }
                          }
                          &.sidebar-list {
                            .badge {
                              position: absolute;
                              right: 50px;
                              top: 14.5px;
                              padding: 0.45em 0.8em 0.33em;
                              z-index: 1;
                              font-size: 10px;
                              letter-spacing: 0.7px;
                              font-weight: 600;
                              font-family: $font-roboto, $font-serif;
                              border-radius: 10px;
                            }
                          }
                          a {
                            padding: 12px 15px 5px 15px !important;
                            position: relative;
                            color: #222222;
                            transition: all 0.3s ease;
                            .sub-arrow {
                              right: 20px;
                              position: absolute;
                              top: 10px;
                              i {
                                display: none;
                              }
                              &:before {
                                display: none;
                              }
                            }
                          }
                          &:first-child {
                            display: none !important;
                          }
                          .sidebar-submenu {
                            width: 100%;
                            padding: 0;
                            position: relative !important;
                            &::before {
                              @extend %sidebar-before;
                            }
                            >li {
                              a {
                                padding-left: 65px !important;
                                font-weight: 400;
                              }
                            }
                            .submenu-title {
                              .according-menu {
                                top: 7px;
                              }
                            }
                            &.opensubmenu {
                              display: block !important;
                            }
                            li {
                              &:hover {
                                >a {
                                  svg {
                                    transition: all 0.3s ease;
                                    position: relative;
                                    content: '';
                                    width: 35px;
                                    height: 35px;
                                    background-color: $header-light-color;
                                    padding: 8px;
                                    top: 0;
                                    -webkit-transition: 0.8s;
                                    transition: 0.8s;
                                    border-radius: 50px;
                                    box-shadow: 1.5px 0.33px 16px 0px rgba($header-light-color, 0.6);
                                  }
                                }
                              }
                              .opensubmegamenu {
                                display: none;
                                position: relative !important;
                                right: 0;
                                width: 100%;
                                padding: 0;
                                &.opensubchild {
                                  display: block;
                                }
                                li {
                                  a {
                                    padding: 6px 45px;
                                    &:after {
                                      display: none;
                                    }
                                  }
                                }
                              }
                              a {
                                padding: 7px 25px;
                                font-size: 14px;
                                color: $white;;
                                display: block;
                                position: relative;
                                letter-spacing: 0.06em;
                                font-weight: 400;
                                transition: all 0.3s ease;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                &.active {
                                  color: var(--theme-color);
                                  &::after {
                                    border-top: 2px solid var(--theme-color);
                                  }
                                }
                                &:after { 
                                  left: 35px;
                                  @extend %sidebar-after;
                                }
                                &:hover {
                                  margin-left: 0;
                                }
                              }
                            }
                          }
                          .sidebar-link {
                            transition: all 0.5s ease;
                            display: block;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &.active {
                              transition: all 0.5s ease;
                              position: relative;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .simplebar-content {
              display: flex;
              flex-direction: column;
            }
          }
        }
        .according-menu {
          position: absolute;
          right: 30px;
          top: 14px;
          display: block;
          font-size: 18px;
          i {
            vertical-align: middle;
            color: $white;
            font-weight: 500;
          }
        }
        .main-menu {
          &.border-section {
            border: none;
          }
          .menu-left {
            .main-menu-right {
              .toggle-nav {
                position: absolute;
                z-index: 2;
                right: 145px;
                top: 6px;
                .sidebar-name {
                  font-size: 18px;
                  padding-left: 5px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .page-body {
          min-height: calc(100vh - 80px);
          margin-top: 24px;
          margin-left: $sidebar-compact-width;
          @media (max-width:991px) {
            margin-left: 0px !important;
          }
        }
      }
      &.dark-sidebar,
      &.color-sidebar {
        .sidebar-main-title {
          h6 {
            color: $dark-all-font-color;
          }
        }
        .toggle-sidebar {
          svg {
            stroke: var(--theme-color);
          }
        }
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        >li {
                          a {
                            svg {
                              &.stroke-icon {
                                stroke: $dark-all-font-color;
                              }
                              &.fill-icon {
                                fill: $dark-all-font-color;
                              }
                            }
                            &.active {
                              svg {
                                &.stroke-icon {
                                  stroke: var(--theme-color);
                                }
                                &.fill-icon {
                                  fill: var(--theme-color);
                                }
                              }
                            }
                          }
                          &.sidebar-main-title {
                            &::before {
                              background: $dark-card-border;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        div.sidebar-wrapper {
          background-color: $dark-card-background;
          .sidebar-main {
            .sidebar-links {
              li {
                a {
                  span {
                    color: $dark-all-font-color;
                  }
                  svg {
                    &.stroke-icon {
                      stroke: $dark-all-font-color;
                    }
                    &.fill-icon {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .sidebar-link {
                          &.active {
                            background-color: rgba($primary-color, 0.2);
                            svg {
                              color: var(--theme-color);
                              stroke: var(--theme-color);
                            }
                          }
                        }
                        .sidebar-submenu {
                          li {
                            display: list-item ;
                            a {
                              color: $dark-all-font-color;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .sidebar-list {
                ul.sidebar-submenu {
                  li {
                    a {
                      span {
                        color: $dark-all-font-color;
                      }
                      &.active {
                        color: var(--theme-color);
                      }
                    }
                  }
                }
              }
            }
          }
          .logo-wrapper {
            .for-light {
              display: none;
            }
            .for-dark {
              display: block;
            }
          }
        }
        .according-menu {
          i {
            color: $dark-all-font-color;
          }
        }
      }
      &.color-sidebar {
        div.sidebar-wrapper {
          background-color: #2f3c4e;
        }
      }
    }
    // compact icon css
    &.compact-sidebar {
      .simplebar-wrapper {
        .simplebar-mask {
          .simplebar-content-wrapper {
            .simplebar-content {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
      .sidebar-list {
        i.fa-thumb-tack {
          visibility: hidden;
          position: absolute;
          top: 8px;
          right: 54px;
          color: var(--theme-color);
          width: 20px;
          height: 20px;
          background-color: $light-primary;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          [dir="rtl"] & {
            left: 54px;
            right: unset;
          }
        }
        &:hover {
          i.fa-thumb-tack {
            visibility: visible;
            cursor: pointer;
            z-index: 1;
          }
        }
        &.pined {
          order: -1;
        }
      }
      .pin-title {
        order: -1;
      }
      ~.bg-overlay1 {
        transition: 0.8s;
        &.active {
          z-index: 8;
          height: 100vh;
          width: 100vw;
          background-color: rgba($black, 0.2);
          position: fixed;
          top: 0;
        }
      }
      .toggle-sidebar {
        position: relative;
        right: unset;
        top: unset;
        margin-right: 15px;
      }
      .sidebar-main-title {
        display: none;
      }
      .logo-wrapper {
        display: none;
      }
      .logo-icon-wrapper {
        display: block;
        text-align: center;
        padding: 24px 30px;
        box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
      }
      .page-header {
        margin-left: 150px;
        width: calc(100% - 150px);
        .header-wrapper {
          .logo-wrapper {
            display: none;
          }
          .left-header {
            width: 30%;
            @media (max-width: 1578px) and (min-width:1274px) {
              width: 38%;
            }
            @media (max-width: 1273px) and (min-width:1200px) {
              width: 45%;
            }
            @media (max-width:991px) and (min-width: 768px) {
              width: 36%;
            }
            @media (max-width:767px) and (min-width:756px) {
              width: 28%;
            }
            h6 {
              @media (max-width: 1199px) {
                width: 132px;
              }
              @media (max-width:767px) {
                width: 83px;
              }
            }
          }
        }
        &.close_icon {
          margin-left: 0;
          width: calc(100% - 0px);
        }
      }
      .page-body-wrapper {
        .page-body {
          margin-top: 80px;
          margin-left: 150px;
        }
        .footer {
          margin-left: 150px;
        }
        div.sidebar-wrapper {
          position: fixed;
          top: 0;
          z-index: 9;
          >div {
            height: auto;
            line-height: inherit;
            background: $white;
            width: 150px;
            text-align: left;
            transition: 0.3s;
            box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
          }
          .sidebar-main {
            .left-arrow,
            .right-arrow {
              display: none;
            }
            .sidebar-links {
              margin: 30px 0;
              height: calc(100vh - 113px);
              overflow: auto;
              li {
                a {
                  color: $dark-color;
                  span {
                    text-transform: capitalize;
                    color: $dark-color;
                    font-weight: 500;
                    letter-spacing: 1px;
                    font-size: 14px;
                  }
                  &.active {
                    background: rgba($primary-color, 0.1);
                    color: var(--theme-color);
                    border-radius: 20px;
                    margin: 0 10px;
                    span {
                      color: var(--theme-color);
                    }
                    ~.sidebar-submenu {
                      animation: fadeInLeft 300ms ease-in-out;
                    }
                  }
                }
                .sidebar-title {
                  display: block;
                  padding: 20px 0;
                  border-bottom: 1px solid #f3f3f3;
                }
                .sidebar-submenu {
                  position: fixed;
                  top: 0px;
                  height: calc(100vh - 0px);
                  overflow: auto;
                  left: 150px;
                  width: 230px;
                  background-color: $white;
                  box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
                  transition: all 0.5s ease-in-out;
                  display: none;
                  padding-top: 20px;
                  z-index: -1;
                  li {
                    a {
                      padding: 15px 15px 15px 40px;
                      display: block;
                      position: relative;
                      border-bottom: 1px solid #f1f1f1;
                      .sub-arrow {
                        display: none;
                      }
                      .according-menu {
                        position: absolute;
                        right: 20px;
                        top: 16px;
                      }
                      &.active {
                        background-color: rgba($primary-color, 0.1);
                        color: var(--theme-color);
                        transition: all 0.3s ease;
                      }
                    }
                    .submenu-content {
                      li {
                        a {
                          &.active {
                            background-color: $transparent-color;
                          }
                        }
                      }
                    }
                  }
                  >li {
                    >a {
                      &::after {
                        position: absolute;
                        content: "";
                        left: 20px;
                        top: 48%;
                        width: 8px;
                        border-top: 2px solid rgba($theme-body-sub-title-color, 0.4);
                      }
                    }
                  }
                }
              }
              .sidebar-list {
                position: relative;
                .badge {
                  position: absolute;
                  left: 53%;
                  top: 7px;
                  font-weight: 500;
                  font-size: 9px;
                }
                .sidebar-link {
                  text-align: center;
                  svg {
                    margin: 0 auto 6px;
                    width: 24px;
                    height: 24px;
                  }
                  >span {
                    display: block;
                    font-weight: 500;
                    letter-spacing: 1px;
                    font-size: 14px;
                    word-break: break-word;
                  }
                  .according-menu {
                    display: none;
                  }
                }
              }
              .mega-menu {
                text-align: center;
                padding: 0;
                svg {
                  width: 24px;
                  height: 24px;
                }
                .according-menu {
                  position: absolute;
                  right: 20px;
                  top: 13px;
                }
              }
            }
          }
          &[data-layout="stroke-svg"] {
            .sidebar-links {
              .mega-menu {
                svg {
                  &.stroke-icon {
                    display: block;
                    margin: 0 auto 6px;
                  }
                  &.fill-icon {
                    display: none;
                  }
                }
              }
            }
          }
          &[data-layout="fill-svg"] {
            .sidebar-links {
              .mega-menu {
                svg {
                  &.stroke-icon {
                    display: none;
                  }
                  &.fill-icon {
                    display: block;
                    margin: 0 auto 6px;
                  }
                }
              }
            }
          }
          &.close_icon {
            transform: translateX(-150px);
            ~.page-body,
            ~.footer {
              margin-left: 0px;
            }
          }
        }
      }
    }
    // compact small css
    &.compact-small {
      .toggle-sidebar {
        position: relative;
        right: unset;
        top: unset;
        margin-right: 15px;
      }
      .sidebar-list {
        i.fa-thumb-tack {
          visibility: hidden;
          position: absolute;
          top: 8px;
          right: 22px;
          color: var(--theme-color);
          width: 20px;
          height: 20px;
          background-color: $light-primary;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          [dir="rtl"] & {
            left: 22px;
            right: unset;
          }
        }
        &:hover {
          i.fa-thumb-tack {
            visibility: visible;
          }
        }
        &.pined,
        &.pin-title {
          order: -1;
        }
      }
      .sidebar-main-title,
      .logo-wrapper {
        display: none;
      }
      .logo-icon-wrapper {
        display: block;
        text-align: center;
        padding: 27px 30px;
        box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
      }
      .page-header {
        margin-left: 90px;
        width: calc(100% - 90px);
        .header-wrapper {
          .logo-wrapper {
            display: none;
          }
        }
        &.close_icon {
          margin-left: 0;
          width: calc(100% - 0px);
          
        }
      }
      .page-body-wrapper {
        .page-body {
          margin-left: 90px;
        }
        .footer {
          margin-left: 90px;
        }
        div.sidebar-wrapper {
          >div {
            width: 90px;
          }
          .sidebar-main {
            .sidebar-links {
              @media (max-width:420px) {
                height: calc(100vh - 105px);
              }
              li {
                .sidebar-title {
                  display: block;
                  padding: 15px 0;
                }
                .sidebar-submenu {
                  left: 90px;
                  li {
                    a {
                      padding: 10px 15px 10px 40px;
                      .sub-arrow {
                        display: none;
                      }
                      .according-menu {
                        top: 11px;
                      }
                    }
                  }
                }
              }
              .sidebar-list {
                .badge {
                  display: none;
                }
                .sidebar-link {
                  svg {
                    margin: 0 auto;
                    width: 20px;
                    height: 20px;
                  }
                  >span {
                    display: none;
                  }
                }
              }
              .mega-menu {
                svg {
                  margin: 0 auto;
                }
                a {
                  >span {
                    display: none;
                  }
                }
              }
            }
          }
          &.close_icon {
            transform: translateX(-150px);
     
            ~.page-body,
            ~.footer {
              margin-left: 0px;
            }
          }
        }
      }
    }
    // only body css
    &.only-body {
      background-color: #f2f0fa;
      .page-body-wrapper {
        background-color: #f2f0fa;
      }
      .page-header {
        position: relative;
        .header-logo-wrapper {
          display: none;
        }
        .header-wrapper {
          padding: 0 !important;
          .left-header {
            display: none;
          }
          .nav-right {
            position: absolute;
            right: 60px;
            top: 58px;
          }
          .search-full {
            top: 37px;
            width: calc(100% - 50px);
            left: 25px;
            [dir="rtl"] & {
              left: unset;
              right: 25px;
            }
            .form-group {
              .Typeahead {
                .u-posRelative {
                  background-color: $white;
                  border-radius: 15px;
                }
              }
            }
            .Typeahead-menu {
              top: 78px;
              width: calc(100% - 30px);
              margin: 0 auto;
              right: 0;
            }
          }
        }
      }
      .page-body-wrapper {
        .page-title {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
      .sidebar-wrapper,
      footer {
        display: none;
      }
      .page-body-wrapper {
        .page-body {
          margin-top: 0px;
          min-height: calc(100vh - 0px);
        }
        .page-title {
          padding-left: 30px;
          padding-right: 30px;
          margin: 30px 0;
          border-radius: 15px;
          @media (max-width: 991.98px) {
            padding-left: 20px;
            padding-right: 20px;
            margin: 20px 0;
          }
          >.row {
            .col-6 {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
          .breadcrumb {
            margin-top: 5px;
            justify-content: flex-start;
          }
        }
      }
    }
    // modern css
    &.modern-type {
      .progress-chart-wrap {
        margin-left: -22px;
        [dir="rtl"] & {
          margin-left: unset;
          margin-right: -22px;
        }
      }
      .ecommerce-widget {
        border: 1px solid lighten($primary-color, 22%) !important;
      }
      .file-sidebar .pricing-plan {
        border: 1px solid lighten($primary-color, 22%) !important;
      }
      .alert-primary {
        color: var(--theme-color);
      }
      .project-box {
        background-color: lighten($primary-color, 22%);
      }
      .nav-tabs .nav-link.active {
        background-color: transparent;
      }
      .simplebar-mask {
        top: 10px;
      }
      .page-header {
        margin-left: 0px;
        width: calc(100% - 0px);
        &.close_icon {
          margin-left: 0px;
          width: calc(100% - 0px);
        }
        .header-wrapper {
          .left-header {
            width: 30%;
            @media (max-width:1428px) and (min-width:1200px) {
              width: 37%;
            }
            @media (max-width:818px) {
              width: 36%;
            }
          }
          .nav-right {
            @media (max-width:1669px) {
              width: 50%;
            }
            @media (max-width:1428px) and (min-width:1200px) {
              width: 40%;
            }
          }
          .header-logo-wrapper {
            display: flex;
            align-items: center;
          }
          .logo-wrapper {
            display: block;
            margin-right: 30px;
          }
          .toggle-sidebar {
            display: block;
            position: relative;
            top: unset;
            right: unset;
            margin-right: 15px;
          }
        }
      }
      .page-body-wrapper {
        .page-title {
          background-color: transparent;
          padding-bottom: 0;
        }
        div.sidebar-wrapper {
          margin: 30px !important;
          top: 80px;
          bottom: 0;
          border-radius: 15px;
          .logo-wrapper,
          .logo-icon-wrapper {
            display: none;
          }
          .sidebar-main {
            .sidebar-links {
              height: calc(100vh - 160px);
            }
          }
          &.close_icon {
            .logo-icon-wrapper {
              display: none;
            }
            ~.page-body {
              margin-left: 0px;
            }
            ~footer {
              margin-left: 0px;
              margin-right: 0px;
            }
            &:hover {
              .logo-wrapper {
                display: none;
              }
            }
          }
        }
        .page-body {
          margin-left: 296px;
          .container-fluid {
            max-width: 1500px;
          }
        }
        .footer {
          margin-left: 360px;
          margin-right: 70px;
          border-radius: 10px;
          @media (max-width: 991px) {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }
    // material css
    &.material-type {
      position: relative;
      &::before {
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        height: 300px;
        background-image: $gradient-bg-primary;
      }
      .card {
        box-shadow: 0 0 20px rgba($black, 0.05);
      }
      .page-header {
        position: relative;
        max-width: 1560px;
        margin: 50px auto 0;
        border-radius: 15px 15px 0 0;
        .header-wrapper {
          .left-header {
            width: 31%;
            @media (max-width:1600px) {
              width: 33%;
            }
            @media (max-width:1366px) {
              width: 38%;
            }
            @media (max-width:1199px) {
              width: 30%;
            }
          }
          .nav-right {
            @media (max-width:1600px) {
              width: 40%;
            }
            @media (max-width:1199px) {
              width: 45%;
            }
          }
        }
      }
      .page-body-wrapper {
        // background-image: url(../images/other-images/boxbg.jpg);
        background-blend-mode: overlay;
        background-color: rgba($white, 0.5);
        .sidebar-wrapper {
          position: relative;
          max-width: 1560px;
          margin: 0 auto;
          top: unset;
          height: 56px;
        }
        .page-body {
          margin: 0 auto;
          max-width: 1560px;
          background-color: $body-color;
          box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
          border-radius: 0 0 15px 15px;
        }
      }
      .footer {
        margin-top: 40px;
      }
      &.compact-wrapper {
        .page-header {
          .header-wrapper {
            .left-header {
              width: 38%;
            }
            .nav-right {
              @media (max-width:991px) {
                width: 50%;
              }
            }
          }
        }
      }
    }
    &.advance-layout {
      background-blend-mode: overlay;
      background-color: $main-body-color;
      .card {
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid #ecf3fa;
      }
      .page-header {
        position: relative;
        background-color: $white;
        margin: 0 auto;
      }
      .page-body-wrapper {
        background: transparent;
        .sidebar-wrapper {
          position: relative !important;
          top: unset !important;
          height: unset;
          box-shadow: none;
          border-top: 1px solid #efefef;
          border-bottom: 1px solid #efefef;
          >div {
            overflow-x: hidden;
          }
        }
        .page-body {
          margin-top: 0 !important;
        }
      }
    }
  }
  @keyframes pagebody {
    from {
      opacity: 0;
      transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
    }
    50% {
      opacity: 1;
    }
  }
  // sidebar filter
  .md-sidebar {
    position: relative;
    .md-sidebar-toggle {
      display: none;
      width: fit-content;
      text-transform: capitalize;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 1460px) {
    .sidebar-wrapper {
      .sidebar-main {
        .sidebar-links {
          .simplebar-wrapper {
            .simplebar-mask {
              .simplebar-content-wrapper {
                .simplebar-content {
                  >li {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .profile-dropdown {
      &.onhover-show-div {
        right: 0;
        left: unset !important;
        &:before {
          right: 10px !important;
          left: unset !important;
        }
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .mobile-title {
      &.d-none {
        display: flex !important;
        justify-content: space-between;
        padding: 20px 30px 17px;
        border-bottom: 1px solid #ddd;
        margin: 0 -15px;
        h5 {
          color: var(--theme-color);
        }
        svg {
          stroke: var(--theme-color) !important;
        }
      }
    }
    .page-wrapper {
      .page-header{
        .header-wrapper{
          .left-header{
            .left-menu-header{
              .app-list{
                ul{
                  padding: 20px 0;
                  width: 180px;
                  li{
                    a{
                      color: $theme-body-font-color;
                      font-weight: 600;
                    }
                  }
                }
              }
              .app-menu{
                display: block !important;
                width: 40px;
                height: 40px;
                background-color: rgba($primary-color, 0.2);
                position: relative;
                border-radius: 10px;
                @media (max-width: 630px){
                  width: 35px;
                  height: 35px;
                }
                svg{
                  width: 20px;
                  height: 20px;
                  transform: translate(50%, -50%);
                  font-size: 18px;
                  position: absolute;
                  top: 50%;
                  [dir="rtl"] & {
                    left: 0;
                  }
                  @media (max-width: 630px){
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 992px) {
    .page-header {
      .header-wrapper {
        .nav-right {
          .notification-dropdown {
            top: 46px;
          }
        }
      }
    }
    .responsive-btn {
      display: none;
    }
    .page-wrapper {
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-mask {
                top: 0;
              }
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .sidebar-submenu {
                          width: 200px;
                          opacity: 0;
                          visibility: hidden;
                          border-radius: 5px;
                          transition: all 0.5s ease;
                          a {
                            .sub-arrow {
                              position: absolute;
                              right: 20px;
                              top: 5px;
                              color: $light-semi-font;
                              font-weight: 100;
                              transition: all 0.5s ease;
                              font-size: 15px;
                              top: 8px;
                            }
                          }
                          li {
                            .opensubmegamenu {
                              display: none;
                              border-radius: 4px;
                            }
                            &:hover {
                              .opensubmegamenu {
                                display: block;
                                position: absolute;
                                left: 200px;
                                background-color: $white;
                                top: -10px;
                                padding: 15px 0px 15px 0px;
                                box-shadow: 0 0 1px 0 #898989;
                              }
                            }
                            a {
                              &:hover {
                                .sub-arrow {
                                  right: 16px;
                                  transition: all 0.5s ease;
                                }
                              }
                            }
                          }
                        }
                        &.hoverd {
                          .sidebar-submenu {
                            opacity: 1;
                            visibility: visible;
                            margin-top: 8px;
                            transition: all 0.5s ease;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .toggle-nav {
            display: none;
          }
          .mobile-back {
            display: none !important;
          }
        }
      }
    }
  }
  @media screen and (max-width: 899px) {
    .header-left{
      display: none !important;
    }
  }
  @media only screen and (min-width: 768px) {
    // material icon css
    .page-wrapper {
      &.material-icon {
        position: relative;
        &::before {
          position: absolute;
          content: '';
          left: 0;
          width: 100%;
          height: 200px;
          background: $gradient-bg-primary;
        }
        .toggle-sidebar {
          color: rgba($white, 0.8);
          &:hover {
            >svg {
              color: $white !important;
              stroke: $white !important;
            }
          }
        }
        .page-header {
          position: relative;
          background: transparent;
          padding-left: 90px;
          max-width: 1660px;
          margin: 0 auto;
          width: calc(100% - 0px);
          box-shadow: none;
          .f-light {
            color: $white;
          }
          .header-wrapper {
            .nav-right {
              ul {
                li {
                  svg {
                    stroke: $white;
                  }
                  .profile-dropdown {
                    li {
                      svg {
                        stroke: $theme-body-font-color;
                      }
                    }
                  }
                }
              }
              >ul {
                >li {
                  color: $white;
                  .notification-box,
                  .cart-box {
                    svg {
                      stroke: $white;
                    }
                  }
                  >a {
                    >svg {
                      stroke: $white;
                    }
                  }
                  >svg {
                    stroke: $white;
                  }
                }
              }
              .language-nav {
                .more_lang {
                  color: $theme-body-font-color;
                }
              }
              .onhover-show-div {
                color: $theme-body-font-color;
              }
              &.right-header {
                ul {
                  li {
                    .profile-media {
                      .flex-grow-1 {
                        p {
                          color: rgba($white, 0.7);
                        }
                      }
                    }
                  }
                }
              }
            }
            .left-header{
              .left-menu-header{
                .app-menu{
                  width: 35px;
                  height: 35px;
                  svg{
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }
          &.close_icon {
            padding-left: 0;
            margin: 0 auto !important;
          }
        }
        .page-body-wrapper {
          .page-body {
            margin-top: 0 !important;
            padding-left: 90px;
            max-width: 1660px;
            margin: 0 auto;
          }
          div.sidebar-wrapper {
            &.close_icon {
              ~.page-body {
                padding-left: 15px;
                margin: 0 auto !important;
              }
            }
          }
          .page-title {
            color: $white;
            background-color: transparent;
            border-bottom: none;
            box-shadow: none;
            .breadcrumb {
              .breadcrumb-item {
                a {
                  svg {
                    stroke: $white;
                  }
                }
                &.active {
                  color: rgba($white, 0.6);
                }
                +.breadcrumb-item {
                  &::before {
                    color: rgba($white, 0.8);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 992px) {
    // material icon css
    .page-wrapper {
      &.material-icon {
        &::before {
          height: 240px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .sidebar-wrapper {
      .menu {
        .brand-logo {
          img {
            width: 120px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 576px) {
    .toggle-nav {
      i {
        font-size: 20px;
      }
    }
  }
  .page-wrapper.compact-wrapper {
        .page-body-wrapper {
            div.sidebar-wrapper {
                .sidebar-main {
                    .sidebar-links {
                        >li {
                            display: block;
                            width: 100%;
                            position: relative;
                            &:first-child {
                                display: none;
                            }
                            .sidebar-submenu{
                                &:before {
                                    position: absolute;
                                    content: "";
                                    width: 1px;
                                    height: 0;
                                    background: rgba(155, 155, 155, 0.4);
                                    top: -10px;
                                    left: 27px;
                                    transition: height 0.5s;
                                }
                                li {
                                    a {
                                        padding: 7px 25px;
                                        font-size: 14px;
                                        color: #fff;
                                        display: block;
                                        position: relative;
                                        letter-spacing: 0.06em;
                                        font-weight: 400;
                                        -webkit-transition: all 0.3s ease;
                                        transition: all 0.3s ease;
                                        white-space: nowrap;                                
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        padding-left: 65px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
  /**=====================
     Sidebar CSS Ends
  ==========================**/